.showNewsContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.showNewsContainer .goBackbtn{
    background-color: var(--lightBlueBg);
    margin-bottom: 64px;
    width: inherit;
    padding: 14px 0 14px 16%;
    display: flex;
}

.showNewsContainer .goBackbtn img{
    display: block;
}

.showNewsContainer .showNewsTitle{
    font-size: 2.25em;
    font-weight: 700;
    line-height: 130%;
    width: 45%;
    margin: 0 auto;
    text-align: left;
}

.showNewsContainer .showNewsMain img{
    margin: 4em auto;
    border-radius: 1.25em;
    width: 68%;
}

.showNewsContainer .showNewsContent{
    text-align: left;
    width: 45%;
    margin: 0 auto;
}

.showNewsContainer .showNewsContent p{
    font-size: 1em;
    line-height: 160%;
    font-weight: 500;
    margin: 32px 0;
}

.showNewsContainer .showNewsContent h2{
    font-size: 1.5em;
    line-height: 130%;
    font-weight: 700;
    margin: 40px 0;
}

.showNewsContainer .showNewsContent img{ 
    margin: 64px auto;
    width: fit-content;
    /* max-width: 100%; */
    width: 100%;
    height: auto;
    display: block;
}

.showNewsContainer .showNewsContent iframe{
    /* border: none;
    margin: 0 auto; */
    text-align: center;
    width: inherit;
}

.showNewsContainer .showNewsContent blockquote{
    background-color: var(--lightBlueBg);
    padding: 5px 10px;
    border-left: 8px solid var(--gray3);
}

@media (hover:hover){
    .showNewsContainer .goBackbtn img:hover{
        cursor: pointer;
    }
}


@media screen and (max-width: 900px){
    .showNewsContainer{
        margin: 0;
        margin-top: 5em;
    }

    .showNewsContainer .goBackbtn{
        padding-left: 85px;
        margin-bottom: 0;
    }

    .showNewsContainer .showNewsMain{
        padding: 48px 84px 0;
    }

    .showNewsContainer .showNewsMain img{
        margin: 48px 0;
        width: 100%;
    }

    .showNewsContainer .showNewsTitle,
    .showNewsContainer .showNewsContent{
        width: 100%;
    }

    .showNewsContainer .showNewsContent img{
        margin: 64px 0;
    }
}

@media screen and (max-width: 500px){

    .showNewsContainer{
        margin: 0;
        margin-top: 5em;
    }

    .showNewsContainer .goBackbtn{
        padding: 16px 0 16px 40px;
    }

    .showNewsContainer .goBackbtn img{
        height: 24px;
        width: 24px;
    }

    .showNewsContainer .showNewsMain{
        padding: 28px 40px 0;
    }

    .showNewsContainer .showNewsTitle{
        font-size: 24px;
        line-height: 130%;
    }

    .showNewsContainer .showNewsMain img{
        margin: 24px 0;
        border-radius: 8px;
    }

    .showNewsContainer .showNewsContent p{
        margin: 16px 0;
    }

    .showNewsContainer .showNewsContent h2{
        margin: 36px 0 24px;
    }

    .showNewsContainer .showNewsContent img{
        margin: 36px 0;
        border-radius: 8px;
    }

}