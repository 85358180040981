.service-about-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
    margin: 50px 0;
}

.service-about-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 20px;
}

.service-about-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}
.service-about-container .service-details{
    margin: 50px 0 0;
}
.service-about-container .service-details .main-header{
display: inline-flex;
}
.about-service-tags-area{
    display: flex;
    align-items: center;
    gap: 20px;
}
.about-service-tags-area .about-service-tag{
    border-radius: 8px;
    background-color: var(--lightBlueBg);
    color: var(--bluePrimaryLight);
    padding: 12px 20px;

}
.mt-5{
    margin-top: 1.5rem;
}
.p-0{
    padding: 0 !important;
}

.quot-btn a{
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quot-btn a div{
    height: 24px;
    padding: 0 10px;

}
.px-2{
    padding: 0 1rem;
}
.about-service-tag.active {
    background-color: var(--bluePrimaryLight);

    color: #fff;
}