.service-list-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
}

.service-list-header {
    background-color: var(--lightBlueBg);
    padding: 45px;
    text-align: left;
}
.service-list-header input{
    padding: 8px !important;
    border: 1px solid var(--bluePrimaryLight) !important;
    border-radius: 8px !important;
    width: 300px !important;
}
.service-list-header input:focus{
    outline: none;

}
.service-listing-area {
    margin: 3em 0;

}

.service-listing-header {
    font-size: 30px;
    font-weight: 600;
    padding: 20px 0;
    color: var(--bluePrimaryLight);
}

.service-listing-detail-section {
    padding: 20px;
}
.service-listing-detail-section .MuiGrid-container{
    justify-content: center;
}
.service-listing-detail-section .service-list-detail-card {
    padding: 20px;
    padding-left: 40px;
    text-align: left;
    background-color: var(--lightBlueBg);
    border-radius: 15px;
    position: relative;
    padding-top: 50px;
    transition: 0.5s;
    margin: 10px;
    margin-top: 50px;

}
.service-listing-detail-section .service-list-detail-card:hover {
    background-color: var(--lightBlue);
}

.service-listing-detail-section .service-list-detail-card .service-list-detail-card-title {
    font-size: 22px;
    font-weight: 600;
    color: var(--gray6);
}

.service-listing-detail-section .service-list-detail-card .service-list-detail-card-title .title {
    padding-bottom: 15px;
    position: relative;
    display: inline-block;


}
.service-listing-detail-section .service-list-detail-card:hover .title:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -18px;
    height: 45px;
    width: 4px;
    background-color: var(--bluePrimaryLight);
    border-radius: 25px;
}
.service-listing-detail-section .service-list-detail-card:hover .title:after {
    content: '';
    position: absolute;
    top: 7px;
    right: -35px;
    height: 20px;
    width: 20px;
    background-color: var(--bluePrimaryLight);
    clip-path: polygon(45% 50%, 0 100%, 0 3%);
}
.service-listing-detail-section .service-list-detail-card .service-list-detail-card-title .service-card-img {
    position: absolute;
    background-color: var(--bluePrimaryLight);
    width: 80px;
    height: 80px;
    position: absolute;
    left: 40px;
    top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.service-listing-detail-section .service-list-detail-card .service-list-detail-card-title .service-card-img img {
    width: 40px;
    height: 40px;
    object-fit: contain;

}

/* .service-listing-detail-section .service-list-detail-card .service-list-detail-card-body {} */


.services .rightOps{
    display: none;
}