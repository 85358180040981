.socialMediaContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    width: fit-content;
    margin: 64px auto 0;
}

.socialMediaContainer .shareHeading{
    font-size: 16px;
    line-height: 130%;
    font-weight: 700;
    margin-bottom: 12px;
}

.socialMediaContainer .socialMediaIcons{
    display: flex;
    width: fit-content;
    margin: 0 auto;
}

.socialMediaContainer .socialMediaIcons .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 4px;
    background-color: var(--whiteBlueTint);
    border-radius: 4px;
}

.socialMediaContainer .socialMediaIcons .icon img{
    display: block;
}




@media screen and (max-width: 900px){
    .socialMediaContainer{
        margin-top: 48px;
    }
}


@media screen and (max-width: 400px){
    .socialMediaContainer{
        margin-top: 40px;
    }
    
    .socialMediaContainer .socialMediaIcons .icon{
        border-radius: 4px;
    }
}