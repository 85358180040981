.newsLetterContainer{
    font-family: 'Poppins', sans-serif;
    background-color: var(--bluePrimaryLight);
    background-image: url("../../images//svgs/getStartedBg.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin: 6em 0;
    padding: 4.375em 1em 45px;
}

.newsLetterContainer .newsLetterTitle{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--whiteBlueTint);
    text-transform: capitalize;
}

.newsLetterContainer .newsLetterSubTitle{
    font-weight: 500;
    font-size: 1.125;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--lightBlueBg);
}

.newsLetterContainer .newsLetterForm{
    display: flex;
    width: fit-content;
    margin: 2.5em auto 0;
}

.newsLetterContainer .newsLetterForm input{
    font-size: 0.75em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    margin-right: 1.3em;
    padding: 1em 1.3em;
    border-radius: 1em;
    font-weight: 500;
    border: none;
    color: var(--blackCharcol);
    background-color: var(--whiteBlueTint);
    width: 19em;
    font-family: 'Poppins', sans-serif;
}

.newsLetterContainer .newsLetterForm input::placeholder{
    color: var(--gray4);
}

.newsLetterContainer .newsLetterForm input:focus{
    outline: none;
}

.newsLetterContainer .newsLetterForm .newsLetterBtn button{
    border: none;
    color: var(--blackCharcol);
    font-size: 0.875em;
    line-height: 1.5em;
    font-weight: 500;
    background-color: var(--whiteBlueTint);
    border-radius: 0.75em;
    width: fit-content;
    padding: 11px 19px;
    font-family: 'Poppins', sans-serif;
    transition: all 0.2s;
}




@media (hover:hover){
    .newsLetterContainer .newsLetterForm .newsLetterBtn button:hover{
        background-color: white;
        cursor: pointer;
    }
}





@media screen and (max-width: 900px){
    .newsLetterContainer{
        margin: 48px 0;
        padding: 48px 28px 23px;
    }

    .newsLetterContainer .newsLetterForm input{
        padding: 12px;
        font-size: 10px;
        line-height: 15px;
        border-radius: 8px;
        margin-right: 11px;
    }

    .newsLetterContainer .newsLetterForm .newsLetterBtn button{
        padding: 10px 14px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
    }
}

@media screen and (max-width: 400px){
    .newsLetterContainer {
        margin: 40px 0;
        padding: 40px 28px 15px;
    }

    .newsLetterContainer .newsLetterTitle{
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
    }

    .newsLetterContainer .newsLetterSubTitle{
        font-size: 18px;
        line-height: 27px;
    }

    .newsLetterContainer .newsLetterForm input{
        flex: 0;
        width: auto;
    }
}


