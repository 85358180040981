.portsPoint:hover{
    background-color: var(--whiteBlueTint);
    cursor: pointer;
}

.popUpMain{
    font-family: 'Poppins', sans-serif;
    color: var(--whiteBlueTint);
    opacity: 0;
    /* transform: translateY(-20px); */
    transition: all 0.5s;
}

.portsPoint:hover .popUpMain{
    opacity: 1;
    /* transform: translateY(0px); */
}

.popUpName{
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: var(--blackCharcol);
}

.popUpMain .popUpDesc{
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    white-space: pre-wrap;
}

.popUpMain .popUpLM{
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: -0.02em;
}

.portsPoint .portName{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
}

.portsPoint .rotate {
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(45deg);
}
