.getStartedContainer{
    font-family: 'Poppins', sans-serif;
    background-color: var(--bluePrimaryLight);
    background-image: url("../../images//svgs/getStartedBg.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin: 6em 0;
    padding: 4em 1em;
}

.getStartedContainer .getStartedTitle{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--whiteBlueTint);
}

.getStartedContainer .getStartedSubTitle{
    font-weight: 500;
    font-size: 1.125em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--lightBlueBg);
}

.getStartedContainer .getStartedBtn{
    color: var(--blackCharcol);
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 600;
    background-color: var(--whiteBlueTint);
    border-radius: 1em;
    width: fit-content;
    padding: 1em 2em;
    margin: 2.5em auto 0;
    transition: all 0.2s;
}

.getStartedContainer a{
    color: var(--blackCharcol);
    text-decoration: none;
}



@media (hover:hover){
    .getStartedContainer .getStartedBtn:hover{
        background-color: white;
        cursor: pointer;
    }
}




@media screen and (max-width: 900px){
    .getStartedContainer{
        padding: 48px 84px;
        margin: 0 0 48px;
    }

    .getStartedContainer .getStartedSubTitle{
        font-size: 16px;
        margin: 2px 0 36px;
    }

    .getStartedContainer .getStartedBtn{
        padding: 8px 14px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
        white-space: nowrap;
    }


    .faqGetStartedHelper{
        margin-bottom: -48px;
    }
}


@media screen and (max-width: 400px){
    .getStartedContainer{
        padding: 40px 28px;
    }

    .getStartedContainer .getStartedTitle{
        font-size: 24px;
        line-height: 36px;
    }

    .getStartedContainer .getStartedSubTitle{
        font-size: 14px;
        line-height: 21px;
        margin: 12px 0 24px;
    }

}