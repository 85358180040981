.latestBlogContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin: 4.5em 0 6em;
}

.latestBlogContainer .latestBlogHeadline{
    font-size: 2.25em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.latestBlogContainer .latestBlogs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2em auto 0;
    width: fit-content;
    grid-column-gap: 1em;
}

.noLatestBlogFound img{
    width: 30vw;
    margin: 48px auto 40px;
}

.noLatestBlogFound .noLatestBlogFoundMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}



@media screen and (max-width: 1000px){
    .latestBlogContainer .latestBlogs{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }

    .latestBlogContainer .latestBlogHeadline{
        font-size: 24px;
        line-height: 140%;
    }
}


@media screen and (max-width: 650px){
    .latestBlogContainer{
        padding: 0 28px;
        margin: 40px 0;
    }

    .latestBlogContainer .latestBlogs{
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
    }

    .noLatestBlogFound img{
        width: 50vw;
        margin: 40px auto 24px;
    }

    .noLatestBlogFound .noLatestBlogFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
        padding: 0 10px;
    }

}