.pearksContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 50px 0;

}

.pearksContainer .pearksHeadline{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: capitalize;

}

.pearksContainer .pearksSubHeadline{
    font-weight: 500;
    font-size: 1.5em;
    line-height: 150%;
    letter-spacing: -0.02em;
    width: 100%;
    margin: 1em auto 0;
}

.pearksContainer .pearksMain{
    
    margin: 0 auto;
    margin-top: 4em;
    text-align: left;
}
.pearksContainer .pearksMain .perks-icon{
    width: 90px;
    height: 90px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 20px;
}
.pearksContainer .pearksMain .perks-icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pearksContainer .pearksMain .pearksItem{
    background-color: var(--lightBlueBg);
    padding: 25px;
    border-radius: 12px;
    min-height: 300px;
}


.pearksContainer .pearksMain .pearksItem .pearksTitle{
    font-weight: 600;
    font-size: 1.125em;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.pearksContainer .pearksMain .pearksItem .pearksContent{
    font-weight: 500;
    font-size: 1em;
    line-height: 150%;
    text-transform: capitalize;
    margin-top: 1em;
}

.feature-section-head {
    font-weight: 600;
    font-size: 35px;
    color: #343434;
    padding-bottom: 15px;

}

.feature-section-head span {
    color: #415AA9;
}

.feature-section-head p {
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

@media screen and (max-width: 1000px){

    .pearksContainer{
        margin: 48px 84px;
        padding: 0;
    }

    .pearksContainer .pearksSubHeadline{
        width: 100%;
    }

    .pearksContainer .pearksMain{
        grid-template-columns: 1fr 1fr;
        margin-top: 36px;
    }

    .pearksContainer .pearksMain .pearksItem{
        padding: 16px;
        border-radius: 12px;
    }

    .pearksContainer .pearksMain .pearksItem img{
        margin-bottom: 0;
    }

    .pearksContainer .pearksMain .pearksItem .pearksTitle{
        margin: 16px 0 12px;
        font-size: 14px;
        line-height: 150%;
    }

    .pearksContainer .pearksMain .pearksItem .pearksContent{
        font-size: 12px;
        line-height: 150%;
    }
}

@media screen and (max-width: 600px){
    .pearksContainer{
        margin: 40px 28px;
    }
}

@media screen and (max-width: 500px){
    .pearksContainer .pearksHeadline{
        font-size: 24px;
        line-height: 150%;
    }

    .pearksContainer .pearksSubHeadline{
        font-size: 16px;
        line-height: 150%;
        margin: 8px 0 40px;
    }

    .pearksContainer .pearksMain{
        margin-top: 0;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
    }
}

