.LandingHeroCompContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 5em;
    padding-top: 4em;
    background-color: var(--lightBlueBg);
}

.LandingHeroCompContainer .LandingHeroCompHead {
    font-weight: 700;
    font-size: 48px;
    line-height: 120.5%;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    width: 80%;
    margin: 0 auto;
}

.LandingHeroCompContainer .LandingHeroCompSubHead {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    width: 80%;
    margin: 24px auto 64px;
}

.LandingHeroCompContainer img {
    width: 100%;
}

.LandingHeroCompContainer .LandingHeroCompBtns {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
}

.ladingpage-img img{
    width: 100%;
    object-fit: contain;
}
.LandingHeroCompContainer .LandingHeroCompBtns a {
    text-decoration: none;
}

.LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompGetStarted {
    margin-right: 1.5em;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 1em 2em;
    border-radius: 1em;
    background-color: var(--bluePrimaryLight);
    transition: all 0.2s;
    color: var(--whiteBlueTint);
}

.LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompLearnMore {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 14px 30px;
    border-radius: 1em;
    background-color: var(--lightBlueBg);
    transition: all 0.2s;
    color: var(--bluePrimaryLight);
    transition: all 0.2s;
}




@media (hover:hover) {
    .LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompGetStarted:hover {
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }

    .LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompLearnMore:hover {
        background-color: var(--bluePrimaryLight);
        color: white;
        cursor: pointer;
    }
}





@media screen and (max-width: 900px) {

    .LandingHeroCompContainer {
        margin-top: var(--mbNavbar);
        padding-top: 48px;
        padding-bottom: 40px;
    }

    .LandingHeroCompContainer .LandingHeroCompHead {
        width: 60%;
        font-size: 36px;
        line-height: 120.5%;
        letter-spacing: -0.01em;
    }

    .LandingHeroCompContainer .LandingHeroCompSubHead {
        width: 60%;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 48px;
    }

    .LandingHeroCompContainer .LandingHeroCompBtns {
        padding-bottom: 0;
        margin-bottom: 40px;
    }

    .LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompGetStarted {
        margin: 0 12px 0 0;
        padding: 8px 14px;
        border-radius: 8px;
        width: fit-content;
        font-size: 12px;
        line-height: 18px;
    }

    .LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompLearnMore {
        padding: 6px 14px;
        border-radius: 8px;
        width: fit-content;
        font-size: 12px;
        line-height: 18px;
    }


    .LandingHeroCompContainer img {
        width: 100%;
    }

}

@media screen and (max-width: 400px) {
    .LandingHeroCompContainer {
        margin-top: var(--mbNavbar);
        padding-top: 40px;
        padding-bottom: 1em;
    }

    .LandingHeroCompContainer .LandingHeroCompHead {
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: -0.01em;
        text-transform: capitalize;
        width: inherit;
        margin: 0 28px;
    }

    .LandingHeroCompContainer .LandingHeroCompSubHead {
        width: inherit;
        margin: 16px 28px 40px;
        font-size: 14px;
        line-height: 150%;
    }
}

.LandingHeroCompContainer {
    position: relative;
    height: 80vh;
}

.LandingHeroCompContainer::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00000099;
    z-index: 1;
}

.head-line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: #fff;
    width: 80%;
}

.header-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.inquire-now{
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 9999;
    border-radius: 4px;
    transform: translate(255px,-25%);
    transition: 0.5s;
    
}
.inquire-active{
    transform: translate(0,-25%);
   
 }
.inquire-btn{
    color: #fff;
    transform: rotate(-90deg);
    background-color: var(--bluePrimaryLight);
    padding: 10px 20px;
    height: 30px;
    margin: 42px -42px 0 0;
    cursor: pointer;

}
.form-item{
    margin-bottom: 20px;
}

.inquire-form{
    background-color: #fff;
}

.advertise-box{
    position: relative;
}

.advertise-img{
    width: 600px;
}

.advertise-img img{
    width: 100%;
}

.icon-close{
    position: absolute;
    top: 0;
    right: 0;
    background: #FFFFFF;
    width: 30px;
    border: none;
    border-radius: 4px;
    text-align: center;
    padding: 5px;
}

@media screen and (max-width: 400px) {
    .advertise-img{
        width: 100%;
    }
}