.timberIndexContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 4em 0 0;
    margin-top: 50px;
}

.timberIndexContainer .timberIndexHeading {
    font-weight: 600;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
}

.timberIndexContainer .timberIndexTable {
    width: 68%;
    margin: 3.5em auto 5.5em;
}

.new-26{
    width: 100%;
    height: 25%;
    margin-top: -60px;
    margin-bottom: 30px;
}

.new-26 img{
    width: 68%;
    border-radius: 10px;
}





.timberIndexContainer .timberIndexTable .timberIndexTableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timberIndexContainer .timberIndexTable .timberIndexTableHeader img.tabletSearchIcon {
    display: none;
}

.timberIndexContainer .timberIndexTable .timberIndexTableHeader .searchBar {
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 25%;
}

.timberIndexContainer .timberIndexTable .timberIndexTableHeader .searchBar input {
    border: none;
    outline: none;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    margin-left: 1em;
    width: 90%;
}

.timberIndexContainer .timberIndexTable .searchBar input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer {
    display: flex;
    margin: 0 -6px;
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer img {
    background-color: var(--bluePrimaryLight);
    margin: 0 6px;
    border-radius: 8px;
    padding: 6px;
    cursor: pointer;
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer .paginationPgNo {
    font-weight: 500;
    font-size: 1em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    padding: 4px 15px;
    margin: 0 6px;
    cursor: pointer;
    display: none;
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer .paginationPgNo.thisPage {
    background-color: var(--bluePrimaryLight);
    color: white;
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer .paginationPgNo.showPageNo {
    display: inherit;
}

.timberIndexContainer .timberIndexTable input.tabletSearchBar {
    display: none;
}









.timberIndexContainer .timberIndexTable table {
    background-color: var(--lightBlueBg);
    position: relative;
    text-align: left;
    border-collapse: collapse;
    border-radius: 1.25em;
    width: 100%;
    margin: 1.25em auto;
    padding: 1.375em 0 0 1.5em;
}

.timberIndexContainer .timberIndexTable table::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    /* background: url("../../../images/pngs/logo\ icon.png"); */
    background: url("../../../images/svgs/coalIndexWaterMark.svg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
}

.timberIndexContainer .timberIndexTable table thead {
    color: var(--bluePrimaryLight);
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.75em;
}

.timberIndexContainer .timberIndexTable table thead tr {
    height: 5em;
}

.timberIndexContainer .timberIndexTable table thead tr th:first-of-type {
    padding-left: 1.5em;
}

.timberIndexContainer .timberIndexTable table tbody {
    font-size: 1em;
    line-height: 1.75em;
    font-weight: 500;
}

.timberIndexContainer .timberIndexTable table tbody tr {
    border-top: 1px solid var(--gray5);
    height: 6em;
}

.timberIndexContainer .timberIndexTable table tbody td {
    vertical-align: top;
    padding: 1.5em 0 0 0;
}

.timberIndexContainer .timberIndexTable table tr td:first-of-type {
    padding-left: 1.5em;
    padding-right: 7vw;
    width: 12.7vw;
}

.timberIndexContainer .timberIndexTable table tbody td .timberIndexDataTime {
    color: var(--gray3);
    font-size: 0.75em;
    line-height: 175%;
}

.timberIndexContainer .timberIndexTable table tbody td .priceChangeBlock {
    display: flex;
    align-items: center;
}

.timberIndexContainer .timberIndexTable table tbody td .priceChangeBlock img {
    margin-left: 5px;
}

.timberIndexContainer .timberIndexTable table tbody td .priceChangePer {
    font-size: 0.75em;
    line-height: 175%;
}


.timberIndexContainer .timberIndexTable table tr td.specialData {
    padding: 1em 0;
    text-align: center;
    margin: 1em 0;
    font-weight: 400;
    color: var(--gray3);
    border-top: 1px solid var(--gray5);

}


.timberIndexContainer .timberIndexTable .timberIndexTableFooter {
    display: flex;
    justify-content: right;
}


.subscribe-box {
    box-sizing: border-box;
    border: 1px solid #415AA9;
    border-radius: 20px;
    margin: 50px auto;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 68%;
}

.subscribe-info {
    margin: 0 auto;
    position: relative;
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(20deg); }
   95% { transform: rotate(-20deg); }
  100% { transform: rotate(0deg); }
}

.bell-icon{
    position: absolute;
    right: 50px;
}

.bell-icon img{
    width: 100px;
    animation: wiggle 1.5s infinite;
}

.title-line-1 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    /* identical to box height */

    text-align: center;

    color: #415AA9;

}

.title-line-2 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;
    padding-top: 6px;
    color: #415AA9;

    opacity: 0.8;
}

.title-line-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    cursor: pointer;
    /* transition: all 2s ease; */
}

.title-line-3:before {
    content: "";
    position: absolute;
    /* top: 0; */
    left: 40px; 
    z-index: -1;
    display: block;
    border-radius: 28px;
    background: transparent;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.title-line-3:hover:before {
    width: 68%;
    background: #415AA9;
}

/* .title-line-3:hover{
    background: #415AA9;
    border-radius: 10px;
    padding: 12px;
 
    width: 200px;
} */

.title-line-3:hover span{
    color: #FFFFFF;
}

.title-line-3 span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */
    /* padding-top: 30px; */
    padding-right: 20px;

    color: #415AA9;
}

.subscribe-banner {
    padding: 18px 30px;
    z-index: 1;
}

.custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subscribe-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    /* text-align: justify; */
    margin: 0px;
    color: #282828;
}

.subscribe-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: justify;

    color: #7A7878;
    margin: 0px;
    padding-bottom: 35px;

}

.close-icon {
    align-self: start;
    cursor: pointer;
}

.background-box {
    position: relative;
}

.background-box svg {
    position: absolute;
    left: 0px;
    width: 612px;
    height: 590px;
}

.form-area {
    margin-top: 30px;
}

.field-box {

    margin-bottom: 20px;
}

.field-box input,textarea {
    width: 90%;
    background: #FDFDFF;
    border: 1px solid rgba(23, 104, 172, 0.23);
    border-radius: 6px;
    padding: 17px 23px;
}

.field-box input::placeholder,textarea::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    /* margin-left: 25px; */
}

.submit-btn:hover > p,svg{
    color: #3c8cd0;
}

.submit-btn p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    color: #1768AC;
    padding-right: 10px;
    margin: 0px;
}

.custom-footer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: justify;

    color: #161616;

}

.image-box svg {
    width: 100%;
}

.custom-body{
    display: flex;
    /* justify-content: space-between; */
}

.form-area{
    margin-left: 25px;
}

.custom-subscribe div.MuiDialog-paper{
    width: 70%;
    height: 590px;
    max-width: unset;
    overflow: hidden;
}

.custom-body-img{
    width: 50%;
}

.custom-body-form{
    width: 50%;
}

@media  (max-width:991px){
    .new-26{
        margin-top: 30px;
    }
    .new-26 img{
        width: 100%;
    }
    .background-box {
        display: none;
    }
    .custom-body-img{
        width: 100%;
    }
    
    .custom-body-form{
        width: 100%;
    }
    .custom-body{
        display: block;
        /* justify-content: space-between; */
    }
    .custom-subscribe div.MuiDialog-paper{
        width: 100%;
        height: auto;
        overflow: auto;
    }
    .form-area{
        width: 100%;
        margin-left: 0px;
    }
    .field-box input,textarea {
        width: 82%;
    }
    .subscribe-banner {
        padding: 18px 15px;
        z-index: 1;
    }
    /* .subscribe-title {
        font-size: 28px;
        line-height: 40px;
        margin-right: 10px;
        margin-bottom: 15px;
    } */
}

@media screen and (max-width: 1050px) {
    .timberIndexContainer {
        padding: 48px 50px 0;
        margin-bottom: 88px;
    }

    .timberIndexContainer .timberIndexTable {
        width: inherit;
        margin: 0;
    }

    .timberIndexContainer .timberIndexTable .view {
        width: inherit;
        margin: 20px 0 40px;
    }

    .timberIndexContainer .timberIndexTable .view .wrapper {
        position: relative;
        overflow: auto;
        white-space: nowrap;
        border-radius: 1.25em;
    }

    .timberIndexContainer .timberIndexTable table {
        margin: 0;
    }

    .timberIndexContainer .timberIndexTable table tbody tr td,
    .timberIndexContainer .timberIndexTable table thead tr th {
        padding-left: 16px;
    }

    .timberIndexContainer .timberIndexTable .view .wrapper table tbody tr td.stickyCol {
        padding-bottom: 1em;
    }

    .timberIndexContainer .timberIndexTable .view .wrapper table tbody tr td.stickyCol,
    .timberIndexContainer .timberIndexTable .view .wrapper table thead tr th.stickyCol {
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--lightBlueBg);
        left: -1px;
        white-space: pre-wrap;
        padding-right: 16px;
        height: fit-content;
        /* white-space: nowrap; */
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }

}

@media screen and (max-width: 900px) {
    .timberIndexContainer {
        margin-bottom: 48px;
    }

    .timberIndexContainer .timberIndexTable .timberIndexTableHeader .searchBar {
        display: none;
    }

    .timberIndexContainer .timberIndexTable .timberIndexTableHeader img.tabletSearchIcon {
        display: block;
        margin-left: 8px;
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar {
        border: none;
        outline: none;
        display: block;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
        width: calc(100% - 20px);
        border-radius: 8px;
        padding: 0 10px;
        max-height: 0;
        transition: all 0.2s;
        margin: 0;
        margin-top: 20px;
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar::placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar.showTabletSearchbar {
        max-height: 5em;
        padding: 6px 10px;
    }

    .timberIndexContainer .timberIndexTable .timberIndexTableHeader {
        margin-top: 40px;
    }

    .timberIndexContainer .timberIndexTable .timberPaginationContainer {
        margin: 0 -3px;
    }

    .timberIndexContainer .timberIndexTable .timberPaginationContainer img {
        height: 13px;
        width: 13px;
        margin: 0 3px;
        border-radius: 4px;
        padding: 6px;
    }

    .timberIndexContainer .timberIndexTable .timberPaginationContainer .paginationPgNo {
        font-size: 12px;
        line-height: 175%;
        padding: 0;
        height: 23px;
        width: 23px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    .timberIndexContainer .timberIndexTable .timberIndexTableFooter {
        justify-content: center;
    }

    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 600px) {
    .timberIndexContainer {
        padding: 40px 28px 0;
        margin-bottom: 40px;
    }

    .timberIndexContainer .timberIndexHeading {
        font-size: 24px;
        line-height: 36px;
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar {
        margin-top: 16px;
    }

    .timberIndexContainer .timberIndexTable .view {
        margin: 16px 0 24px;
    }

    .timberIndexContainer .timberIndexTable table {
        border-radius: 8px;
    }

    .timberIndexContainer .timberIndexTable table thead tr th {
        font-size: 12px;
        line-height: 175%;
    }

    .timberIndexContainer .timberIndexTable table tbody tr td {
        font-size: 14px;
        line-height: 175%;
        padding-top: 25px;
    }

    .timberIndexContainer .timberIndexTable .view .wrapper table tbody tr td.stickyCol {
        display: block;
        white-space: normal;
        margin-right: 0;
        width: 127px;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 300px) {

    .timberIndexContainer .timberIndexTable .view .wrapper table tbody tr td.stickyCol,
    .timberIndexContainer .timberIndexTable .view .wrapper table thead tr th.stickyCol {
        position: initial;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}