.recognitionContainer{
    font-family: 'Poppins', sans-serif;
    margin: 1.125em 0 61px;
}

.recognitionContainer .recognitionHeadline{
    font-weight: 600;
    line-height: 140%;
    color: var(--gray4);
    margin-bottom: 0.75em;
}

.recognitionContainer .recognitionCompanies{
    display: flex;
    justify-content: center;
}

.recognitionContainer .recognitionCompanies img{
    margin: 0 5px;
}


.recognitionContainer .recognitionCompanies .recognitionGridContainer{
    width: fit-content;
}


@media screen and (max-width: 900px){

    .recognitionContainer{
        padding: 0 0;
        margin: 48px 0;
    }

    .recognitionContainer .recognitionHeadline{
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 16px;
    }

    .recognitionContainer .recognitionCompanies .recognitionGridContainer{
        width: inherit;
    }

    .recognitionContainer .recognitionCompanies .recognitionGridContainer img{
        width: 144px;
        margin: 0 6px;
    }
}


@media screen and (max-width: 400px) {
    .recognitionContainer{
        margin: 24px 0 36px;
        padding: 0;
    }

    .recognitionContainer .recognitionCompanies{
        display: initial;
    }

    .recognitionContainer .recognitionHeadline{
        font-size: 12px;
        line-height: 140%;
        margin-bottom: 22px;
    }
    
    .recognitionContainer .recognitionCompanies .recognitionGridContainer{
        width: 90%;
        margin: 0 auto;
        align-items: center;
    }

    /* .recognitionContainer .recognitionCompanies .recognitionGridContainer img{
        width: 90%;
    } */
}