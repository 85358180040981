.lightBlueBgBannerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    background-color: var(--lightBlueBg);
    padding: 4em 0;
    margin-top: 7em;
}

.lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
    font-size: 3em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    font-weight: 700;
}


@media screen and (max-width: 900px){
    .lightBlueBgBannerContainer{
        margin-top: 5em;
        padding: 3em 0;
    }
}

@media screen and (max-width:  400px){
    .lightBlueBgBannerContainer{
        padding: 40px 0;
    }
    .lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
        font-size: 36px;
        line-height: 54px;
    }
}

