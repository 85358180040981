.product-list-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;

}

.product-list-header {
    background-color: var(--lightBlueBg);
    padding: 20px;
    text-align: left;

}

.product-listing-area {
    margin: 4em 0;

}

.product-listing-header {
    height: 450px;
    background-color: var(--lightBlueBg);
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: left;
}

.product-listing-header .list-title {
    font-size: 30px;
    font-weight: 600;
    padding: 20px 0;
    color: var(--bluePrimaryLight);
}

.product-listing-header .list-header-detail {
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
}

.product-listing-detail-section {
    padding: 20px;
}

.product-listing-detail-section .product-list-detail-card {
    padding: 20px;
    padding-left: 30px;
    text-align: left;
    
}

.product-listing-detail-section .product-list-detail-card .product-list-detail-card-title {
    font-size: 22px;
    font-weight: 600;
    color: var(--gray6);
}

.product-listing-detail-section .product-list-detail-card .title {
    position: relative;
    display: inline-block;
}

.product-listing-detail-section .product-list-detail-card:hover .title:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -13px;
    height: 50px;
    width: 4px;
    background-color: var(--bluePrimaryLight);
    border-radius: 25px;
}
.product-listing-detail-section .product-list-detail-card:hover .title:after {
    content: '';
    position: absolute;
    top: 7px;
    right: -35px;
    height: 20px;
    width: 20px;
    background-color: var(--bluePrimaryLight);
    clip-path: polygon(45% 50%, 0 100%, 0 3%);

}

.product-listing-detail-section .product-list-detail-card .product-list-detail-card-title .product-card-img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
}

.product-listing-detail-section .product-list-detail-card .product-list-detail-card-title img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* .product-listing-detail-section .product-list-detail-card .product-list-detail-card-body {} */

.products .rightOps {
    display: none;
}

a{
    text-decoration: none;
    color: #000000;
}