.portDescContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.portDescContainer .portDescHeader{
    background-color: var(--bluePrimaryLight);
    padding: 57px 0;
    color: var(--whiteBlueTint);
}

.portDescContainer .portDescHeader .portDescSubHeader{
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}


.portDescContainer .portDescHeader .portDescHeaderDetails{
    width: fit-content;
    position: relative;
    margin: auto;
    left: -20px;
}

.portDescContainer .portDescHeader .portDescSubHeader img {
    display: block;
}

.portDescContainer .portDescHeader .portDescHeaderDetails .portDescHeaderTitle{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    white-space: nowrap;
}

/* .portDescContainer .goBackbtn{
    background-color: var(--lightBlueBg);
    margin-bottom: 64px;
    width: inherit;
    padding: 14px 0 14px 16%;
    display: flex;
}

.portDescContainer .goBackbtn img{
    display: block;
} */

.portDescContainer .portDescMain{
    margin-bottom: 64px;
}

.portDescContainer .portDescContent{
    text-align: left;
    width: 45%;
    margin: 0 auto;
}

.portDescContainer .portDescContent p{
    font-size: 1em;
    line-height: 160%;
    font-weight: 500;
    margin: 32px 0;
}

.portDescContainer .portDescContent h1{
    font-size: 1.5em;
    line-height: 130%;
    font-weight: 700;
    margin: 40px 0;
}

.portDescContainer .portDescContent img{ 
    margin: 0 auto;
    display: block;
    height: auto;
    width: 100%;
    border-radius: 1.25em;
}

.portDescContainer .portDescContent ul{
    list-style-position: inside;
}

.portDescContainer .portDescContent iframe{
    /* border: none;
    margin: 0 auto; */
    text-align: center;
    width: inherit;
}

.portDescContainer .portDescContent blockquote{
    background-color: var(--lightBlueBg);
    padding: 5px 10px;
    border-left: 8px solid var(--gray3);
}

.portDescContainer .portDescContent table{
    background-color: var(--lightBlueBg);
    text-align: left;
    border-collapse: collapse;
    border: 0px solid black;
    border-radius: 1.25em;
    table-layout: fixed;
    max-width: 100%;
    overflow-x: auto;
    display: block;
    width: 100%;
    margin: 1.25em auto;
    white-space: nowrap;
    font-size: 1em;
    line-height: 1.75em;
    font-weight: 500;
    width: fit-content !important;
}

.portDescContainer .portDescContent table tr:first-of-type{
    color: var(--bluePrimaryLight);
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.75em;
    height: 5em;
    border-top: 0px solid var(--gray5);
}

.portDescContainer .portDescContent table tr{
    border-top: 1px solid var(--gray5);
    height: 6em;
}

.portDescContainer .portDescContent table td{
    vertical-align: top;
    padding: 1.5em 0 0 0;
    border: 0px;
    padding-right: 2vw;
}

.portDescContainer .portDescContent table tr td:first-of-type{
    padding-left: 1.5em;
}


@media (hover:hover){
    .portDescContainer .portDescHeader .portDescSubHeader img{
        cursor: pointer;
    }
}

@media screen and (max-width: 1050px){
    /* .portDescContainer .portDescContent table{
        margin: 1.0;
    } */

    .portDescContainer .portDescContent table tr td{
        padding-left: 16px;
    }
    
    .portDescContainer .portDescContent table tr td:first-of-type{
        padding-bottom: 1em;
    }

    .portDescContainer .portDescContent table tr td:first-of-type{
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--lightBlueBg);
        left: -1px;
        white-space: pre-wrap;
        padding-right: 16px;
        height: fit-content;
        /* white-space: nowrap; */
    }
}

@media screen and (max-width: 900px){
    .portDescContainer{
        margin: 0;
        margin-top: 5em;
    }

    .portDescContainer .portDescHeader{
        padding: 57px 84px;
    }

    .portDescContainer .portDescHeader .portDescSubHeader{
        width: inherit;
    }

    .portDescContainer .portDescHeader .portDescHeaderDetails{
        width: inherit;
    }

    /* .portDescContainer .goBackbtn{
        padding-left: 85px;
        margin-bottom: 0;
    } */

    .portDescContainer .portDescMain{
        padding: 48px 84px;
        margin-bottom: 0;
    }

    .portDescContainer .showBlogTitle,
    .portDescContainer .portDescContent{
        width: 100%;
    }

    .portDescContainer .portDescContent img{
        margin: 64px 0;
    }
}

@media screen and (max-width: 800px){
    .portDescContainer .portDescContent table tr td{
        font-size: 14px;
        line-height: 175%;
        padding-top: 25px;
    }

    .portDescContainer .portDescContent table tr:first-of-type td{
        font-size: 12px;
        line-height: 175%;
    }
}

@media screen and (max-width: 500px){

    .portDescContainer{
        margin: 0;
        margin-top: 5em;
    }

    .portDescContainer .portDescHeader{
        padding: 40px 28px 76px;
    }
    
    .portDescContainer .portDescHeader .portDescSubHeader{
        flex-direction: column;
    }

    .portDescContainer .portDescHeader .portDescSubHeader img{
        display: block;
        height: 24px;
        width: 24px;
        margin-bottom: 12px;
        justify-self: left;
        align-self: stretch;
    }

    .portDescContainer .portDescHeader .portDescHeaderDetails{
        position: initial;
    }

    .portDescContainer .portDescHeader .portDescHeaderDetails .portDescHeaderTitle{
        font-size: 16px;
        line-height: 24px;
    } 

    /* .portDescContainer .goBackbtn{
        padding: 16px 0 16px 40px;
    }

    .portDescContainer .goBackbtn img{
        height: 24px;
        width: 24px;
    } */

    .portDescContainer .portDescMain{
        padding: 28px 40px;
    }

    .portDescContainer .portDescContent p{
        margin: 16px 0;
    }

    .portDescContainer .portDescContent h1{
        margin: 36px 0 24px;
    }

    .portDescContainer .portDescContent img{
        margin: 36px 0;
        border-radius: 8px;
    }

}