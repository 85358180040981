.KeyPointsContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 50px 0;
}

.KeyPointsMain {
    height: 1300px;
    background: url(../../images/svgs/key-point-background.svg);
    background-size: contain;
    position: relative;
    margin-top: 50px;
}

.keypoint-box {
    position: absolute;
}

.keypointsTags1 {
    top: 0;
    right: 30%;
}

.keypointsTags2 {
    top: 200px;
    left: 10%;

}

.keypointsTags3 {
    top: 500px;
    left: 30%;
}

.keypointsTags4 {
    top: 350px;
    right: 10%;
}

.keypointsTags5 {
    bottom: 15%;
    left: 10%;
}

.keypointsTags6 {
    bottom: 0px;
    right: 10%;
}

.keypoint-title {
    font-weight: 700;
    font-size: 24px;
}