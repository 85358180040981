.service-more-container{
    font-family: 'Poppins';
    margin: 50px 0;
}

.service-more-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 25px;
}


.service-more-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}
.more-service-card{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
   transform: 0.5s;

}
.service-img{
    width: 100%;
    height: 100%;
}
.service-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.service-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    position: absolute;
    bottom: -20px;
    width: 100%;
    padding: 15px;
    opacity: 0;
    transition: 0.5s;
    z-index: 2;
}
.more-service-card:hover .service-title{

    bottom: 0;
    opacity: 1;
}

.more-service-card::after{
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background-color: #00000088;
   z-index: 1;
   opacity: 0;
   transform: 0.5s;
}
.more-service-card:hover.more-service-card::after{
    opacity: 1;
}