.legendsMain{
    margin: 16px 0 36px;
}

.legendsMain .portName{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
}

@media screen and (max-width: 500px){
    .legendsMain{
        width: 80%;
        margin: 12px 0 24px;
    }
}