.featuresContainer {
    font-family: 'Poppins', sans-serif;
    /* background-color: var(--lightBlueBg); */
    padding-top: 112px;
}

.featuremainimg {
    width: 100%;
    height: 65vh;
}

.featuremainimg img,
.featuremainimg svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.feature-main-tag {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feature-main-tag div {
    font-weight: 400;
    font-size: 18px;
    line-height: 42px;
    color: #202124;
    text-align:left;

}
.feature-main-tag h4 {
    text-align:left;
}
.feature-section-head {
    font-weight: 600;
    font-size: 35px;
    color: #343434;
    padding-bottom: 15px;

}

.feature-section-head span {
    color: #415AA9;
}

.feature-section-head p {
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

.feature-main-tag p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #202124;
    text-align:left;
    margin-top:15px;
    padding-right: 25px;
}

.featuresContainer .featuresHeader {
    /* background-image: url("../../images/svgs/pliShipBg.svg"); */
    /* background-size:contain; */

    background-repeat: no-repeat;
    background-position: center;
    color: var(--blackCharcol);
    margin-bottom: 3.25em;
    position: relative;
    height: 80vh;
}

.feature-head-line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: var(--bluePrimaryLight);
}

.header-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featuresContainer .featuresHeader .feauresHeadline {
    font-weight: 700;
    font-size: 3em;
    line-height: 140%;
    letter-spacing: -0.02em;
    /* width: 45%; */
    margin: 0 auto;
}

.featuresContainer .featuresHeader .featuresContent {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02em;
    /* width: 36%; */
    margin: 0 auto;
    margin-top: 1em;
    flex: 0 1;
}

.featuresContainer .featuresMain {
    /* padding: 0 14.2%; */
    /* display: flex;
    justify-content: center; */
    padding: 50px 0;
}


.featuresContainer .featuresMain .featuresOptions {
    
    width:100%;
    
    margin: 25px 0;
}
.featuresContainer .featuresMain .featuresOptions .swiper-container{
    display: flex;
}



.featuresContainer .featuresMain .featuresOptions label input {
    position: absolute;
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}



.featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel {
    color: var(--gray3);
    padding: 1em;
    text-align: left;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5em;
    display: flex;
    border-radius: 0.75em;
    transition: all 0.1s;
}

.featuresContainer .featuresMain .featuresOptions label .featureSelected {
    background-color: var(--bluePrimaryLight);
    color: white;
}

.featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel img {
    margin-left: 9px;
    transition: all 0.5s;
}

.featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone {
    display: none;
}



.featuresContainer .featuresMain .featuresContent .featuresContentItem,
.featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone {
    display: none;
}

.featuresContainer .featuresMain .featuresContent .featuresContentItem img,
.featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone img {
    border-radius: 1.25em;
    margin-bottom: 24px;
    width: 35em;
}

.featuresContainer .featuresMain .featuresContent .featuresContentItem .featuresContentText,
.featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone .featuresContentTextPhone {
    font-weight: 500;
    color: var(--blackCharcol);
    font-size: 1.125em;
    line-height: 150%;
    text-align: left;
    width: 100%;
}

.featuresContainer .featuresMain .featuresContent .featuresContentItem.showFeaturesItem {
    display: inline;
}




@media (hover:hover) {
    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel:hover {
        cursor: pointer;
        background-color: white;
    }

    .featuresContainer .featuresMain .featuresOptions label .featureSelected:hover {
        background-color: var(--bluePrimaryLight);
    }
}





@media screen and (max-width: 1000px) {
    .featuresContainer {
        padding: 128px 84px 48px;
    }

    .featuresContainer .featuresHeader {
        padding: 50px 0 41.50px;
        margin-bottom: 24.5px;
    }

    .featuresContainer .featuresHeader .feauresHeadline {
        width: inherit;
    }

    .featuresContainer .featuresHeader .featuresContent {
        width: inherit;
    }

    .featuresContainer .featuresMain {
        padding: 0;
    }

    .featuresContainer .featuresMain .featuresContent {
        display: none;
    }

    .featuresContainer .featuresMain .featuresOptions {
        width: 100%;
        margin-right: 0;
    }

    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel {
        width: inherit;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 18px;
        padding: 16px 24px;
        border-radius: 8px;
    }

    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel img {
        height: 16px;
        width: 16px;
        transform: rotate(90deg);
    }

    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel img.featureOpen {
        transform: rotate(-90deg);
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone {
        display: inherit;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s;
        padding: 24px 0;
        padding: 0;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone img {
        width: 100%;
        padding: 0;
        border-radius: 12px;
        margin-bottom: 16px;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone .featuresContentTextPhone {
        width: inherit;
        margin: 0 auto;
        font-size: 16px;
        line-height: 150%;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone.showFeaturesItemPhone {
        max-height: 50em;
        margin: 2em auto;
        width: fit-content;
    }
}

@media screen and (max-width: 500px) {
    .featuresContainer {
        margin-top: 80px;
        padding: 40px 28px;
    }

    .featuresContainer .featuresHeader {
        padding: 29px 0 17.5px;
        margin-bottom: 44.5px;
    }

    .featuresContainer .featuresHeader .feauresHeadline {
        font-size: 24px;
        line-height: 140%;
    }

    .featuresContainer .featuresHeader .featuresContent {
        font-size: 16px;
        line-height: 150%;
        margin-top: 16px;
    }

    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel {
        padding: 16px;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone.showFeaturesItemPhone {
        margin: 24px 0 40px;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone img {
        border-radius: 8px;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone .featuresContentTextPhone {
        font-size: 14px;
        line-height: 150%;
    }
}