@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --bluePrimaryLight: #415AA9;
  --blueSecondaryDark: #324976;
  --blackCharcol: #282828;
  --lightBlueBg: #F5F7FB;
  --lightBlue: #E1E9F9;
  --whiteBlueTint: #F1F2F7;
  --gray2: #4F4F4F;
  --gray3:  #828282;
  --gray4: #BDBDBD;
  --gray5: #E0E0E0;
  --gray6: #545454;
  --mbBreak: 400px;
  --mbNavbar: 5em;
}

*{
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.main-header {
  margin-bottom: 40px !important;
}

.removeSelection{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
            user-select: none; 
}
.benefitsContainer{
    background-color: #F5F7FB;
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 96px 0;
    margin: 4.375em 0 96px;
}

.benefitsContainer .benefitItem{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    margin: 0 auto;
    flex-grow: 1;
}



.benefitsContainer .rowBenefitItem{
    flex-direction: row;
}

.benefitsContainer .rowReverseBenefitItem{
    flex-direction: row-reverse;
}


.benefitsContainer .benefitItem .benefitDetail{
    text-align: left;
    width: 100%;
    margin: 0 2em;
}

.benefitsContainer .benefitItem .benefitSvg{
    width: 100%;
    margin: 0 2em;
}

.benefitsContainer .benefitItem .benefitSvg img{
    width: 100%;
    /* max-height: 30vw; */
}

.benefitsContainer .benefitItem:nth-of-type(2) .benefitSvg img{
    max-height: 475px;
    min-height: 350px;
    height: 40vw;
}





.benefitsContainer .benefitItem .benefitHead{
    font-weight: 600;
    font-size: 2.25em;
    line-height: 140%;
    margin-bottom: 24px;
}

.benefitsContainer .benefitItem .benefitContent{
    font-weight: 500;
    font-size: 1em;
    line-height: 175%;
}

.benefitsContainer .benefitItem .benefitContent a{
    text-decoration: none;
    color: var(--bluePrimaryLight);
}




@media screen and (max-width: 1200px){

    .benefitsContainer .benefitItem .benefitSvg{
        flex: 0 1 auto;
    }


    .benefitsContainer .rowReverseBenefitItem .benefitDetail{
        margin: 3em 0;
    }
}


@media screen and (max-width: 900px){
    .benefitsContainer{
        margin-bottom: 0;
    }
}


@media screen and (max-width: 950px){
    .benefitsContainer{
        padding: 48px 11% 20px 10%;
    }

    .benefitsContainer .benefitItem{
        margin: 0 -8px;
        width: inherit;
    }

    .benefitsContainer .benefitItem .benefitDetail{
        flex: 1 1;
        width: 100%;
        margin-left: 0;
        margin: 0 8px;
    }

    .benefitsContainer .benefitItem .benefitHead{
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 12px;
    }

    .benefitsContainer .benefitItem .benefitContent{
        font-size: 12px;
        line-height: 175%;
    }

    .benefitsContainer .benefitItem .benefitSvg{
        flex: 1 1;
        margin: 0 8px;
    }

    .benefitsContainer .benefitItem .benefitSvg img{
        width: 100%;
    }
}


@media screen and (max-width: 500px){
    .benefitsContainer{
        padding: 0 28px 40px;
        margin-bottom: 0;
    }

    .benefitsContainer .benefitItem{
        flex-direction: column-reverse;
    }

    .benefitsContainer .benefitItem .benefitSvg img{
        width: 100%;
        margin-top: 40px;
    }

    .benefitsContainer .benefitItem:nth-of-type(2) .benefitSvg img{
        max-height: 400px;
        margin-bottom: 1.5rem;
    }    

    .benefitsContainer .benefitItem .benefitDetail{
        margin-left: 0;
        width: inherit;
    }
}
.faqContainer{
    font-family: 'Poppins', sans-serif;
}

.faqContainer .faqHeading{
    font-size: 2.25em;
    line-height: 140%;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: var(--blackCharcol);
    text-transform: capitalize;
    margin-bottom: 0.9em;
}

.faqContainer .faqQueAnsContainer{
    display: flex;
    flex-direction: column;
    margin: -0.75em 0;
}

.faqContainer .faqQueAnsContainer .faqItem{
    display: flex;
    background-color: var(--lightBlueBg);
    width: 70%;
    padding: 2em;
    text-align: left;
    color: var(--blackCharcol);
    justify-content: space-between;
    border-radius: 12px;
    margin: 0.75em auto;
}

.faqContainer .faqQueAnsContainer .faqItem .faqContent{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
}

.faqContainer .faqQueAnsContainer .faqItem .radioLabel{
    height: 32px;
}

.faqContainer .faqQueAnsContainer .faqItem .faqQue{
    font-weight: 600;
    line-height: 140%;
    font-size: 1.125;
    width: 80%;
}

.faqContainer .faqQueAnsContainer .faqItem .faqAns{
    font-size: 18px;
    line-height: 140%;
    width: 80%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
}

.faqContainer .faqQueAnsContainer .faqItem .faqAns a{
    text-decoration: underline;
    color: var(--bluePrimaryLight);
}

.faqContainer .faqQueAnsContainer .faqItem .radioButton{
    position: absolute;
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}

.faqContainer .faqQueAnsContainer .faqItem .radioLabel img{
    transition: all 0.5s;
}

.faqContainer .faqQueAnsContainer .faqItem .radioLabel img.rotate{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.faqContainer .faqQueAnsContainer .faqItem .showFaq{
    margin-top: 1.334em;
    max-height: 10em;
}


.faqPricingHelper{
    margin-bottom: 96px;
}




@media (hover:hover) {
    .faqContainer .faqQueAnsContainer .faqItem{
        cursor: pointer;
    }
}



@media screen and (max-width: 900px){
    .faqContainer{
        padding: 48px 84px;
    }

    .faqContainer .faqQueAnsContainer{
        width: inherit;
        margin: -12px 0;
    }

    .faqContainer .faqQueAnsContainer .faqItem{
        width: inherit;
        padding: 24px;
        margin: 15px 0;
        border-radius: 8px;
    }

    .faqContainer .faqQueAnsContainer .faqItem .radioLabel{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .faqContainer .faqQueAnsContainer .faqItem .radioLabel img{
        height: 24px;
        width: 24px;
        display: block;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqContent{
        flex: 1 1;
        margin-right: 24px;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqQue{
        margin: 0 12px 0 0;
        width: inherit;
        font-size: 14px;
        line-height: 140%;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqAns{
        font-size: 12px;
        line-height: 140%;
        margin: 0;
        width: inherit;
    }

    .faqContainer .faqQueAnsContainer .faqItem .showFaq{
        margin-top: 12px;
    }

    .faqPricingHelper{
        margin-bottom: 0;
    }
}

@media screen and (max-width: 500px){
    .faqContainer{
        padding: 40px 28px;
    }

    .faqContainer .faqHeading{
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 40px;
    }

    .faqContainer .faqQueAnsContainer{
        width: inherit;
        margin: -12px 0;
    }

    .faqContainer .faqQueAnsContainer .faqItem{
        width: inherit;
        padding: 16px;
        margin: 16px 0;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqContent{
        margin-right: 0;    
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqQue{
        margin: 0 12px 0 0;
        width: 90%;
        font-size: 14px;
        line-height: 140%;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqAns{
        font-size: 12px;
        line-height: 140%;
        margin-right: -24px;
        margin: 0;
        width: 105%;
    }

    .faqContainer .faqQueAnsContainer .faqItem .showFaq{
        margin-top: 12px;
    }
}

.recognitionContainer{
    font-family: 'Poppins', sans-serif;
    margin: 1.125em 0 61px;
}

.recognitionContainer .recognitionHeadline{
    font-weight: 600;
    line-height: 140%;
    color: var(--gray4);
    margin-bottom: 0.75em;
}

.recognitionContainer .recognitionCompanies{
    display: flex;
    justify-content: center;
}

.recognitionContainer .recognitionCompanies img{
    margin: 0 5px;
}


.recognitionContainer .recognitionCompanies .recognitionGridContainer{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}


@media screen and (max-width: 900px){

    .recognitionContainer{
        padding: 0 0;
        margin: 48px 0;
    }

    .recognitionContainer .recognitionHeadline{
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 16px;
    }

    .recognitionContainer .recognitionCompanies .recognitionGridContainer{
        width: inherit;
    }

    .recognitionContainer .recognitionCompanies .recognitionGridContainer img{
        width: 144px;
        margin: 0 6px;
    }
}


@media screen and (max-width: 400px) {
    .recognitionContainer{
        margin: 24px 0 36px;
        padding: 0;
    }

    .recognitionContainer .recognitionCompanies{
        display: initial;
    }

    .recognitionContainer .recognitionHeadline{
        font-size: 12px;
        line-height: 140%;
        margin-bottom: 22px;
    }
    
    .recognitionContainer .recognitionCompanies .recognitionGridContainer{
        width: 90%;
        margin: 0 auto;
        align-items: center;
    }

    /* .recognitionContainer .recognitionCompanies .recognitionGridContainer img{
        width: 90%;
    } */
}
.statsContainer {
    font-family: 'Poppins', sans-serif;
}

.statsContainer .statsHeadline{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: var(--blackCharcol);
}

.statsContainer .statsDetails{
    display: flex;
    padding: 2em 0;
    width: 70%;
    margin: 2.5em auto 0;
    background-color: var(--lightBlueBg);
    border-radius: 1em;
    text-align: left;
}

.statsContainer .statsDetails .statsItem{
    padding-left: 2.5em;
    height: 5.25em;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.statsContainer .statsDetails .statsItem:nth-child(2),
.statsContainer .statsDetails .statsItem:nth-child(3),
.statsContainer .statsDetails .statsItem:nth-child(4){
    border-left: 1px solid rgba(130, 130, 130, 0.4);
}

.statsContainer .statsDetails .statsItem .statsData{
    font-weight: 600;
    color: var(--bluePrimaryLight);
    font-size: 2.25em;
    line-height: 130%;
}

.statsContainer .statsDetails .statsItem .statsTag{
    font-weight: 500;
    color: var(--gray3);
    line-height: 140%;
}






@media screen and (max-width: 800px){
    .statsContainer .statsDetails{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: inherit;
        margin: 46px 84px 0;
        padding: 24px 0;
        grid-row-gap: 24px;
    }

    .statsContainer .statsDetails .statsItem:nth-child(3){
        border-left: none;
    }
    
    .statsContainer .statsDetails .statsItem{
        padding-left: 31%;
    }

    /* To add Active users Again do:border: 
        1. Comment next 2 design blocks.
        2. Uncomment 3rd block. 
    */

    .statsContainer .statsDetails .statsItem:nth-child(1){
        padding-left: 41%;
    }

    .statsContainer .statsDetails .statsItem:nth-child(3){
        grid-column: 1/3;
        padding-left: 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
    }

    /* .statsContainer .statsDetails .statsItem:nth-child(1),
    .statsContainer .statsDetails .statsItem:nth-child(3){
        padding-left: 41%;
    } */
}


@media screen and (max-width: 500px){
    .statsContainer .statsHeadline{
        font-size: 24px;
        line-height: 140%;
    }

    .statsContainer .statsDetails{
        margin: 24px 28px 0;
        padding: 24px 0;
        grid-row-gap: 24px;
    }

    .statsContainer .statsDetails .statsItem{
        padding-left: 16px;
    }

    .statsContainer .statsDetails .statsItem:nth-child(1),
    .statsContainer .statsDetails .statsItem:nth-child(3){
        padding-left: 24px;
    }

    .statsContainer .statsDetails .statsItem .statsData{
        font-size: 24px;
        line-height: 130%;
    }
    
    .statsContainer .statsDetails .statsItem .statsTag{
        font-size: 16px;
        line-height: 140%;
    }
}
.blogOverContainer{
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    border-radius: 1.25em;
    width: 19em;
}

.blogOverContainer a{
    text-decoration: none;
    color: var(--blackCharcol);
}

.blogOverContainer img{
    display: block;
    width: 100%;
    height: 16em;
}

.blogOverContainer .blogDetails{
    background-color: var(--lightBlueBg);
    padding: 1.5em;
    text-align: left;
    border-radius: 0 0 1.25em 1.25em;
}

.blogOverContainer .blogDetails .blogCommodity{
    font-size: 0.75em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--bluePrimaryLight);
}

.blogOverContainer .blogDetails .blogTitle{
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.5em;
    margin: 8px 0;
}

.blogOverContainer .blogDetails .blogDate{
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

@media screen and (max-width: 350px){
    .blogOverContainer{
        width: auto;
        margin: 0 3.4vw;
    }

    .blogOverContainer .blogDetails{
        padding: 6.8vw;
    }

    .blogOverContainer .blogDetails .blogTitle{
        font-size: 5vw;
    }

    .blogOverContainer .blogDetails .blogDate,
    .blogOverContainer .blogDetails .blogCommodity{
        font-size: 4vw;
    }

    .blogOverContainer img{
        height: auto;
    }
}
.latestBlogContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin: 4.5em 0 6em;
}

.latestBlogContainer .latestBlogHeadline{
    font-size: 2.25em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.latestBlogContainer .latestBlogs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2em auto 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-column-gap: 1em;
}

.noLatestBlogFound img{
    width: 30vw;
    margin: 48px auto 40px;
}

.noLatestBlogFound .noLatestBlogFoundMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}



@media screen and (max-width: 1000px){
    .latestBlogContainer .latestBlogs{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }

    .latestBlogContainer .latestBlogHeadline{
        font-size: 24px;
        line-height: 140%;
    }
}


@media screen and (max-width: 650px){
    .latestBlogContainer{
        padding: 0 28px;
        margin: 40px 0;
    }

    .latestBlogContainer .latestBlogs{
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
    }

    .noLatestBlogFound img{
        width: 50vw;
        margin: 40px auto 24px;
    }

    .noLatestBlogFound .noLatestBlogFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
        padding: 0 10px;
    }

}
.getStartedContainer{
    font-family: 'Poppins', sans-serif;
    background-color: var(--bluePrimaryLight);
    background-image: url(/static/media/getStartedBg.aadd7243.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin: 6em 0;
    padding: 4em 1em;
}

.getStartedContainer .getStartedTitle{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--whiteBlueTint);
}

.getStartedContainer .getStartedSubTitle{
    font-weight: 500;
    font-size: 1.125em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--lightBlueBg);
}

.getStartedContainer .getStartedBtn{
    color: var(--blackCharcol);
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 600;
    background-color: var(--whiteBlueTint);
    border-radius: 1em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1em 2em;
    margin: 2.5em auto 0;
    transition: all 0.2s;
}

.getStartedContainer a{
    color: var(--blackCharcol);
    text-decoration: none;
}



@media (hover:hover){
    .getStartedContainer .getStartedBtn:hover{
        background-color: white;
        cursor: pointer;
    }
}




@media screen and (max-width: 900px){
    .getStartedContainer{
        padding: 48px 84px;
        margin: 0 0 48px;
    }

    .getStartedContainer .getStartedSubTitle{
        font-size: 16px;
        margin: 2px 0 36px;
    }

    .getStartedContainer .getStartedBtn{
        padding: 8px 14px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
        white-space: nowrap;
    }


    .faqGetStartedHelper{
        margin-bottom: -48px;
    }
}


@media screen and (max-width: 400px){
    .getStartedContainer{
        padding: 40px 28px;
    }

    .getStartedContainer .getStartedTitle{
        font-size: 24px;
        line-height: 36px;
    }

    .getStartedContainer .getStartedSubTitle{
        font-size: 14px;
        line-height: 21px;
        margin: 12px 0 24px;
    }

}
.LandingHeroCompContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 5em;
    padding-top: 4em;
    background-color: var(--lightBlueBg);
}

.LandingHeroCompContainer .LandingHeroCompHead {
    font-weight: 700;
    font-size: 48px;
    line-height: 120.5%;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    width: 80%;
    margin: 0 auto;
}

.LandingHeroCompContainer .LandingHeroCompSubHead {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    width: 80%;
    margin: 24px auto 64px;
}

.LandingHeroCompContainer img {
    width: 100%;
}

.LandingHeroCompContainer .LandingHeroCompBtns {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
}

.ladingpage-img img{
    width: 100%;
    object-fit: contain;
}
.LandingHeroCompContainer .LandingHeroCompBtns a {
    text-decoration: none;
}

.LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompGetStarted {
    margin-right: 1.5em;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 1em 2em;
    border-radius: 1em;
    background-color: var(--bluePrimaryLight);
    transition: all 0.2s;
    color: var(--whiteBlueTint);
}

.LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompLearnMore {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 14px 30px;
    border-radius: 1em;
    background-color: var(--lightBlueBg);
    transition: all 0.2s;
    color: var(--bluePrimaryLight);
    transition: all 0.2s;
}




@media (hover:hover) {
    .LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompGetStarted:hover {
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }

    .LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompLearnMore:hover {
        background-color: var(--bluePrimaryLight);
        color: white;
        cursor: pointer;
    }
}





@media screen and (max-width: 900px) {

    .LandingHeroCompContainer {
        margin-top: var(--mbNavbar);
        padding-top: 48px;
        padding-bottom: 40px;
    }

    .LandingHeroCompContainer .LandingHeroCompHead {
        width: 60%;
        font-size: 36px;
        line-height: 120.5%;
        letter-spacing: -0.01em;
    }

    .LandingHeroCompContainer .LandingHeroCompSubHead {
        width: 60%;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 48px;
    }

    .LandingHeroCompContainer .LandingHeroCompBtns {
        padding-bottom: 0;
        margin-bottom: 40px;
    }

    .LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompGetStarted {
        margin: 0 12px 0 0;
        padding: 8px 14px;
        border-radius: 8px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 12px;
        line-height: 18px;
    }

    .LandingHeroCompContainer .LandingHeroCompBtns .LandingHeroCompLearnMore {
        padding: 6px 14px;
        border-radius: 8px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 12px;
        line-height: 18px;
    }


    .LandingHeroCompContainer img {
        width: 100%;
    }

}

@media screen and (max-width: 400px) {
    .LandingHeroCompContainer {
        margin-top: var(--mbNavbar);
        padding-top: 40px;
        padding-bottom: 1em;
    }

    .LandingHeroCompContainer .LandingHeroCompHead {
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: -0.01em;
        text-transform: capitalize;
        width: inherit;
        margin: 0 28px;
    }

    .LandingHeroCompContainer .LandingHeroCompSubHead {
        width: inherit;
        margin: 16px 28px 40px;
        font-size: 14px;
        line-height: 150%;
    }
}

.LandingHeroCompContainer {
    position: relative;
    height: 80vh;
}

.LandingHeroCompContainer::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00000099;
    z-index: 1;
}

.head-line {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    color: #fff;
    width: 80%;
}

.header-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.inquire-now{
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 9999;
    border-radius: 4px;
    -webkit-transform: translate(255px,-25%);
            transform: translate(255px,-25%);
    transition: 0.5s;
    
}
.inquire-active{
    -webkit-transform: translate(0,-25%);
            transform: translate(0,-25%);
   
 }
.inquire-btn{
    color: #fff;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    background-color: var(--bluePrimaryLight);
    padding: 10px 20px;
    height: 30px;
    margin: 42px -42px 0 0;
    cursor: pointer;

}
.form-item{
    margin-bottom: 20px;
}

.inquire-form{
    background-color: #fff;
}

.advertise-box{
    position: relative;
}

.advertise-img{
    width: 600px;
}

.advertise-img img{
    width: 100%;
}

.icon-close{
    position: absolute;
    top: 0;
    right: 0;
    background: #FFFFFF;
    width: 30px;
    border: none;
    border-radius: 4px;
    text-align: center;
    padding: 5px;
}

@media screen and (max-width: 400px) {
    .advertise-img{
        width: 100%;
    }
}
.legendsMain{
    margin: 16px 0 36px;
}

.legendsMain .portName{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
}

@media screen and (max-width: 500px){
    .legendsMain{
        width: 80%;
        margin: 12px 0 24px;
    }
}
.portsPoint:hover{
    background-color: var(--whiteBlueTint);
    cursor: pointer;
}

.popUpMain{
    font-family: 'Poppins', sans-serif;
    color: var(--whiteBlueTint);
    opacity: 0;
    /* transform: translateY(-20px); */
    transition: all 0.5s;
}

.portsPoint:hover .popUpMain{
    opacity: 1;
    /* transform: translateY(0px); */
}

.popUpName{
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: var(--blackCharcol);
}

.popUpMain .popUpDesc{
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    white-space: pre-wrap;
}

.popUpMain .popUpLM{
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: -0.02em;
}

.portsPoint .portName{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
}

.portsPoint .rotate {
    transform-box: fill-box;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.portsMapContainer {
    font-family: 'Poppins', sans-serif;
}

.portsMapContainer .portsMapHead {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: var(--gray3);
}

.portsMapContainer .portsMapMain {
    width: 60%;
    margin: 0 auto;
}
.d-flex{
    display: flex;
}
.map-box .map-img{
    width: 72px;
    height: 72px;
}
.map-box .map-img img{
    width: 72px;
    height: 72px;
    object-fit: cover;
}   
@media screen and (max-width: 900px) {
    .portsMapContainer {
        padding-top: 48px;
    }

    .portsMapContainer .portsMapHead {
        font-size: 12px;
    }

    .portsMapContainer .portsMapMain {
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .portsMapContainer .portsMapHead {
        font-size: 8px;
    }

    .portsMapContainer .portsMapMain {
        width: 100%;
    }
}

.portsMapMain a g {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: opacity 0.5s;
}

.portsMapMain a:hover g {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}


.portsMapMain {
    width: 100%;
}

.portsMapMain svg {
    object-fit: contain;
    width: 100%;
}

.section-head {
    font-weight: 600;
    font-size: 35px;
    color: #343434;
    padding-bottom: 35px;

}

.section-head span {
    color: #415AA9;
}

.global-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.filter-btn {
    cursor: pointer;
    border: 1px solid #B0B3C8;
    color: var(--bluePrimaryLight);
    border-radius: 8px;
    padding: 4px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;

}

.global-map-section {
    display: flex;

}

.map-area {
    width: 100%;

    height: 500px;
}

.map-area .map {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 20px;
}

.map-details {
    height: 500px;
    overflow: auto;
    padding: 0 0 0 15px;
}

.map-details .map-card-area {
    background-color: var(--lightBlueBg);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
}
.map-details .map-card {
    display: flex;
    align-items: center;
   grid-gap: 15px;
   gap: 15px;
}

.map-details .map-img {
    flex-shrink: 0;

    border-radius: 50%;
    overflow: hidden;
}

.map-details .map-img {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.map-details .map-card-content h3 {
    color: var(--bluePrimaryLight);
    margin-bottom: 7px;
    text-align: left;
}

.map-details .map-card-content .port-type {
    text-align: left;
    display: flex;
    align-items: center;
}
.map-details .map-card-content .port-type div {
    margin-right: 10px !important;

}
.map-details .port-location {
    text-align: right;
    color: var(--bluePrimaryLight);
    font-size: 12px;
    line-height: 0.5;
}
.text-orange{
color: #EF9626;
}
.text-purple{
color: #6F41A9;
}
.text-blue{
color: #415AA9;
}
.map-card-area.active{
    background-color: var(--bluePrimaryLight);
}
.map-card-area.active h3{
    color: #fff;
}
.map-card-area.active .port-location{
    color: #fff;
}
.markers{
    display: flex;
    font-weight: 700;
    font-size: 18px;
    padding-right: 20px;
}
.markers img{
    width: 25px;
    object-fit: contain;
    margin-right: 10px;
}
.port-type img{
    width: 20px;
    object-fit: contain;
    margin-right: 10px;
}
.markers span{
    vertical-align: middle;
}
.port-text{
    text-transform: capitalize;
}
/* 
.map-box{
    padding: 15px;
} */
.map-box .map-img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
}
.map-box .map-img{
    width: 45px;
    height: 45px;
 object-fit: cover;
}
.map-box h3{
    color: var(--bluePrimaryLight);
    text-align: left;
    margin: 15px 0;
    font-size: 18px;
}
.map-box p{
    font-size: 12px;
line-height: 15px;
text-align: left;
color: #7A7878;
}
.gm-style-iw{
    max-width: 250px !important;
}

.map-marker img{
    width: 15px;
    object-fit: contain;
}
.clientContainer img{
    width: 12vw;
}

.clientContainer .recognitionGridContainer{
    margin-top: 5px;
}
.featuresContainer {
    font-family: 'Poppins', sans-serif;
    /* background-color: var(--lightBlueBg); */
    padding-top: 112px;
}

.featuremainimg {
    width: 100%;
    height: 65vh;
}

.featuremainimg img,
.featuremainimg svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.feature-main-tag {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feature-main-tag div {
    font-weight: 400;
    font-size: 18px;
    line-height: 42px;
    color: #202124;
    text-align:left;

}
.feature-main-tag h4 {
    text-align:left;
}
.feature-section-head {
    font-weight: 600;
    font-size: 35px;
    color: #343434;
    padding-bottom: 15px;

}

.feature-section-head span {
    color: #415AA9;
}

.feature-section-head p {
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

.feature-main-tag p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #202124;
    text-align:left;
    margin-top:15px;
    padding-right: 25px;
}

.featuresContainer .featuresHeader {
    /* background-image: url("../../images/svgs/pliShipBg.svg"); */
    /* background-size:contain; */

    background-repeat: no-repeat;
    background-position: center;
    color: var(--blackCharcol);
    margin-bottom: 3.25em;
    position: relative;
    height: 80vh;
}

.feature-head-line {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    color: var(--bluePrimaryLight);
}

.header-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featuresContainer .featuresHeader .feauresHeadline {
    font-weight: 700;
    font-size: 3em;
    line-height: 140%;
    letter-spacing: -0.02em;
    /* width: 45%; */
    margin: 0 auto;
}

.featuresContainer .featuresHeader .featuresContent {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02em;
    /* width: 36%; */
    margin: 0 auto;
    margin-top: 1em;
    flex: 0 1;
}

.featuresContainer .featuresMain {
    /* padding: 0 14.2%; */
    /* display: flex;
    justify-content: center; */
    padding: 50px 0;
}


.featuresContainer .featuresMain .featuresOptions {
    
    width:100%;
    
    margin: 25px 0;
}
.featuresContainer .featuresMain .featuresOptions .swiper-container{
    display: flex;
}



.featuresContainer .featuresMain .featuresOptions label input {
    position: absolute;
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}



.featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel {
    color: var(--gray3);
    padding: 1em;
    text-align: left;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5em;
    display: flex;
    border-radius: 0.75em;
    transition: all 0.1s;
}

.featuresContainer .featuresMain .featuresOptions label .featureSelected {
    background-color: var(--bluePrimaryLight);
    color: white;
}

.featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel img {
    margin-left: 9px;
    transition: all 0.5s;
}

.featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone {
    display: none;
}



.featuresContainer .featuresMain .featuresContent .featuresContentItem,
.featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone {
    display: none;
}

.featuresContainer .featuresMain .featuresContent .featuresContentItem img,
.featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone img {
    border-radius: 1.25em;
    margin-bottom: 24px;
    width: 35em;
}

.featuresContainer .featuresMain .featuresContent .featuresContentItem .featuresContentText,
.featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone .featuresContentTextPhone {
    font-weight: 500;
    color: var(--blackCharcol);
    font-size: 1.125em;
    line-height: 150%;
    text-align: left;
    width: 100%;
}

.featuresContainer .featuresMain .featuresContent .featuresContentItem.showFeaturesItem {
    display: inline;
}




@media (hover:hover) {
    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel:hover {
        cursor: pointer;
        background-color: white;
    }

    .featuresContainer .featuresMain .featuresOptions label .featureSelected:hover {
        background-color: var(--bluePrimaryLight);
    }
}





@media screen and (max-width: 1000px) {
    .featuresContainer {
        padding: 128px 84px 48px;
    }

    .featuresContainer .featuresHeader {
        padding: 50px 0 41.50px;
        margin-bottom: 24.5px;
    }

    .featuresContainer .featuresHeader .feauresHeadline {
        width: inherit;
    }

    .featuresContainer .featuresHeader .featuresContent {
        width: inherit;
    }

    .featuresContainer .featuresMain {
        padding: 0;
    }

    .featuresContainer .featuresMain .featuresContent {
        display: none;
    }

    .featuresContainer .featuresMain .featuresOptions {
        width: 100%;
        margin-right: 0;
    }

    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel {
        width: inherit;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 18px;
        padding: 16px 24px;
        border-radius: 8px;
    }

    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel img {
        height: 16px;
        width: 16px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel img.featureOpen {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone {
        display: inherit;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s;
        padding: 24px 0;
        padding: 0;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone img {
        width: 100%;
        padding: 0;
        border-radius: 12px;
        margin-bottom: 16px;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone .featuresContentTextPhone {
        width: inherit;
        margin: 0 auto;
        font-size: 16px;
        line-height: 150%;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone.showFeaturesItemPhone {
        max-height: 50em;
        margin: 2em auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

@media screen and (max-width: 500px) {
    .featuresContainer {
        margin-top: 80px;
        padding: 40px 28px;
    }

    .featuresContainer .featuresHeader {
        padding: 29px 0 17.5px;
        margin-bottom: 44.5px;
    }

    .featuresContainer .featuresHeader .feauresHeadline {
        font-size: 24px;
        line-height: 140%;
    }

    .featuresContainer .featuresHeader .featuresContent {
        font-size: 16px;
        line-height: 150%;
        margin-top: 16px;
    }

    .featuresContainer .featuresMain .featuresOptions label .featuresRadioLabel {
        padding: 16px;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone.showFeaturesItemPhone {
        margin: 24px 0 40px;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone img {
        border-radius: 8px;
    }

    .featuresContainer .featuresMain .featuresOptions .featuresContentItemPhone .featuresContentTextPhone {
        font-size: 14px;
        line-height: 150%;
    }
}
.pearksContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 50px 0;

}

.pearksContainer .pearksHeadline{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: capitalize;

}

.pearksContainer .pearksSubHeadline{
    font-weight: 500;
    font-size: 1.5em;
    line-height: 150%;
    letter-spacing: -0.02em;
    width: 100%;
    margin: 1em auto 0;
}

.pearksContainer .pearksMain{
    
    margin: 0 auto;
    margin-top: 4em;
    text-align: left;
}
.pearksContainer .pearksMain .perks-icon{
    width: 90px;
    height: 90px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 20px;
}
.pearksContainer .pearksMain .perks-icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pearksContainer .pearksMain .pearksItem{
    background-color: var(--lightBlueBg);
    padding: 25px;
    border-radius: 12px;
    min-height: 300px;
}


.pearksContainer .pearksMain .pearksItem .pearksTitle{
    font-weight: 600;
    font-size: 1.125em;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.pearksContainer .pearksMain .pearksItem .pearksContent{
    font-weight: 500;
    font-size: 1em;
    line-height: 150%;
    text-transform: capitalize;
    margin-top: 1em;
}

.feature-section-head {
    font-weight: 600;
    font-size: 35px;
    color: #343434;
    padding-bottom: 15px;

}

.feature-section-head span {
    color: #415AA9;
}

.feature-section-head p {
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

@media screen and (max-width: 1000px){

    .pearksContainer{
        margin: 48px 84px;
        padding: 0;
    }

    .pearksContainer .pearksSubHeadline{
        width: 100%;
    }

    .pearksContainer .pearksMain{
        grid-template-columns: 1fr 1fr;
        margin-top: 36px;
    }

    .pearksContainer .pearksMain .pearksItem{
        padding: 16px;
        border-radius: 12px;
    }

    .pearksContainer .pearksMain .pearksItem img{
        margin-bottom: 0;
    }

    .pearksContainer .pearksMain .pearksItem .pearksTitle{
        margin: 16px 0 12px;
        font-size: 14px;
        line-height: 150%;
    }

    .pearksContainer .pearksMain .pearksItem .pearksContent{
        font-size: 12px;
        line-height: 150%;
    }
}

@media screen and (max-width: 600px){
    .pearksContainer{
        margin: 40px 28px;
    }
}

@media screen and (max-width: 500px){
    .pearksContainer .pearksHeadline{
        font-size: 24px;
        line-height: 150%;
    }

    .pearksContainer .pearksSubHeadline{
        font-size: 16px;
        line-height: 150%;
        margin: 8px 0 40px;
    }

    .pearksContainer .pearksMain{
        margin-top: 0;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
    }
}


.usersContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 50px 0;
    background-color: var(--lightBlueBg);

}

.usersContainer .usersHeadline{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 140%;
    letter-spacing: -0.02em;
}

.usersContainer .usersMain{
    display: grid;
    grid-template-columns: repeat(3,303px);
    grid-column-gap: 1.125em;
    grid-row-gap: 24px;
    justify-content: center;
    margin-top: 2em;
}

.usersContainer .usersMain .usersItem{
    padding: 1.9em 3.375em 2.2em;
    border-radius: 0.75em;
}

.usersContainer .usersMain .usersItem:last-of-type{
    grid-column: 2/3;
}

.usersContainer .usersMain .usersItem .usersTitle{
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-top: 12px;
}
.user-img{
    width: 100px;
    margin: auto;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1000px){
    .usersContainer{
        padding: 0 84px;
    }

    .usersContainer .usersMain{
        grid-template-columns: repeat(2,50%);
        margin: 26px 0 48px;
        grid-column-gap: 16px;
    }

    .usersContainer .usersMain .usersItem{
        padding: 16px 0 ;
    }

    .usersContainer .usersMain .usersItem:last-of-type{
        grid-column: 1/2;
    }

    .usersContainer .usersMain .usersItem img{
        height: 45px;
        width: 45px;
    }
}

@media screen and (max-width: 600px){   

    .usersContainer{
        padding: 0 28px 40px;
    }

    .usersContainer .usersHeadline{
        font-size: 24px;
        line-height: 140%;
    }

    .usersContainer .usersMain{
        grid-template-columns: repeat(2,1fr);
        grid-row-gap: 24px;
        margin: 12px 0 0;
    }

}
.KeyPointsContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 50px 0;
}

.KeyPointsMain {
    height: 1300px;
    background: url(/static/media/key-point-background.01d00c33.svg);
    background-size: contain;
    position: relative;
    margin-top: 50px;
}

.keypoint-box {
    position: absolute;
}

.keypointsTags1 {
    top: 0;
    right: 30%;
}

.keypointsTags2 {
    top: 200px;
    left: 10%;

}

.keypointsTags3 {
    top: 500px;
    left: 30%;
}

.keypointsTags4 {
    top: 350px;
    right: 10%;
}

.keypointsTags5 {
    bottom: 15%;
    left: 10%;
}

.keypointsTags6 {
    bottom: 0px;
    right: 10%;
}

.keypoint-title {
    font-weight: 700;
    font-size: 24px;
}
.lightBlueBgBannerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    background-color: var(--lightBlueBg);
    padding: 4em 0;
    margin-top: 7em;
}

.lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
    font-size: 3em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    font-weight: 700;
}


@media screen and (max-width: 900px){
    .lightBlueBgBannerContainer{
        margin-top: 5em;
        padding: 3em 0;
    }
}

@media screen and (max-width:  400px){
    .lightBlueBgBannerContainer{
        padding: 40px 0;
    }
    .lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
        font-size: 36px;
        line-height: 54px;
    }
}


.pricingContainer{
    font-family: 'Poppins', sans-serif;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4em auto 0;
    color: var(--blackCharcol);
    display: flex;
}

.pricingContainer .entityTypeOptionContainer{
    background-color: var(--lightBlueBg);
    width: 19em;
    border-radius: 1.25em;
    padding: 1.5em 1.125em;
    text-align: left;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptionHead{
    font-size: 1.125em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-bottom: 1em;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label input{
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem{
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1.125em;
    border-radius: 0.75em;
    transition: all 0.1s;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem .entityTypeOptionItemUser{
    font-size: 1em;
    font-weight: 500;
    line-height: 175%;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem .entityTypeOptionItemPrice{
    font-size: 18px;
    line-height: 175%;
    opacity: 0;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem.entityTypeOptionSelected{
    background-color: var(--bluePrimaryLight);
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem.entityTypeOptionSelected .entityTypeOptionItemUser{
    color: var(--lightBlueBg);
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem.entityTypeOptionSelected .entityTypeOptionItemPrice{
    color: white;
    opacity: 1;
}

.pricingContainer .entityTypeOptionContainer .userDropDown{
    display: none;
}

.pricingContainer .entityTypeOptionContainer .userSelectDropDown .entityTypeOptionsItem{
    display: flex;
    width: 75%;
    justify-content: space-between;
}




.pricingContainer + a .getStartedButton{
    font-size: 1em;
    padding: 1em 2em;
    font-weight: 600;
    line-height: 1.5em;
    background: var(--bluePrimaryLight);
    font-family: 'Poppins', sans-serif;
    border-radius: 1em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 1.5em auto 0;
    transition: all 0.2s;
}

.pricingContainer + a{
    text-decoration: none;
    color: var(--whiteBlueTint);
}


.pricingContainer .includedModules{
    padding: 1.5em;
    border: 1px solid #C4C4C4;
    border-radius: 1.25em;
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    width: 685px;
}

.pricingContainer .includedModules .includedModulesHead{
    font-size: 1.125em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 2.4em;
}

.pricingContainer .includedModules .includedModulesContainer{
    display: flex;
    text-align: left;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto 0;
}

.pricingContainer .includedModules .includedModulesContainer .left,
.pricingContainer .includedModules .includedModulesContainer .right{
    margin: -1.5em 0;
    flex: 1 1;
}

.pricingContainer .includedModules .includedModulesContainer .left{
    margin-right: 4.75em;
}


.pricingContainer .includedModules .includedModulesContainer .includedModulesItem{
    display: flex;
    align-items: center;
    margin: 1.5em 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    white-space: nowrap;
}

.pricingContainer .includedModules .includedModulesContainer .includedModulesItem img{
    margin-right: 1em;
}


.pricingContainer .bottomPrices{
    display: none;
}




@media (hover:hover){

    .pricingContainer + a:hover .getStartedButton{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }

    .pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem:hover{
        background-color: white;
        cursor: pointer;
    }

    .pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem.entityTypeOptionSelected:hover{
        background-color: var(--bluePrimaryLight);
    }

}


@media screen and (max-width: 1100px){
    .pricingContainer{
        flex-direction: column;
        margin: 48px 84px 0;
        width: inherit;
    }

    .pricingContainer .entityTypeOptionContainer{
        width: inherit;
        padding: 24px;
        margin-bottom: 24px;
        border-radius: 12px;
    }

    .pricingContainer .entityTypeOptionContainer .entityTypeOptionHead{
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 16px;
    }

    .pricingContainer .entityTypeOptionContainer .entityTypeOptions{
        display: none;
    }
    

    .pricingContainer .entityTypeOptionContainer .userDropDown{
        position: relative;
        display: block;
    }


    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownBtn{
        font-weight: 500;
        font-size: 12px;
        line-height: 175%;
        color: var(--lightBlueBg);
        background-color: var(--bluePrimaryLight);
        padding: 12px 18px;
        border-radius: 8px;
        width: inherit;
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
    
    .pricingContainer .entityTypeOptionContainer .userDropDown:hover .dropDownBtn img{
        transition: all 0.1s;
    }
    
    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownOptions{
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 175%;
        margin-top: 8px;
        max-height: 0;
        overflow: hidden;
        text-align: left;
        width: calc(100% - 16px);
        background-color: white;
        transition: all 0.1s;
        border-radius: 8px;
        padding: 0 8px;
        box-shadow: 0 0 2px 0 rgb(197, 197, 197);
    }

    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownOptions .dropDownOptionItem{
        padding: 5px 10px;
        border-radius: 8px;
    }
    
    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownOptions.openDropDown{
        padding: 8px 8px;
        max-height: 20em;
    }
    
    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownOptions .selectedOption{
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
    }

    
    .pricingContainer .includedModules {
        margin-left: 0;
        width: inherit;
    }

    .pricingContainer .includedModules .includedModulesContainer .includedModulesItem{
        white-space: initial;
    }

    .pricingContainer + a{
        margin: 0 auto 48px;
        display: inherit;
    }

    .pricingContainer + a .getStartedButton{
        margin: 0 auto;
        padding: 8px 13.5px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
    }

    .pricingContainer .bottomPrices{
        display: initial;
        color: var(--bluePrimaryLight);
        font-size: 18px;
        line-height: 175%;
        margin: 48px 0 8px;
    }
}

@media screen and (max-width: 600px){
    .pricingContainer{
        margin: 40px 28px 0;
    }

    .pricingContainer .entityTypeOptionContainer{
        padding: 16px;
        margin-bottom: 40px;
    }

    .pricingContainer .includedModules{
        border-radius: 12px;
        padding: 16px;
    }

    .pricingContainer .includedModules .includedModulesContainer{
        flex-direction: column;
    }

    .pricingContainer .includedModules .includedModulesContainer .left{
        margin: 0;
        margin-bottom: 8px;
    }

    .pricingContainer .includedModules .includedModulesContainer .right{
        margin-bottom: -14px;
    }

    .pricingContainer .bottomPrices{
        margin: 40px 0 8px;
    }

    .pricingContainer + a{
        margin: 0 auto 40px;
    }
}


@media screen and (max-width: 400px){
    .pricingContainer .includedModules .includedModulesHead{
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 25px;
    }

    .pricingContainer .includedModules .includedModulesContainer .includedModulesItem{
        font-size: 12px;
        line-height: 18px;
        margin: 14px 0;
    }

    .pricingContainer .includedModules .includedModulesContainer .includedModulesItem img{
        height: 16px;
        width: 16px;
    }

}
.SaltCalcContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
}

.SaltCalcContainer .SaltCalcHead{
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.02em;
}

.SaltCalcContainer .SaltCalcMain{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 46px auto 0;
    border: 1.5px solid var(--bluePrimaryLight);
    border-radius: 20px;
    width: 68%;
    position: relative;
}

.SaltCalcContainer .SaltCalcMain .disableInput{
    background-color: var(--lightBlueBg);   
}

.SaltCalcContainer .SaltCalcMain > img{
    padding: 0px;
    border-radius: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1.5px solid var(--bluePrimaryLight);
    position: absolute;
    left: calc(50% - 19.5px);
    top: calc(50% - 19.5px);
    background-color: white;
}

.SaltCalcContainer .SaltCalcMain img.changeDir{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.SaltCalcContainer .SaltCalcMain .calcLeft,
.SaltCalcContainer .SaltCalcMain .calcRight{
    padding: 24px 0 38px 36px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items:baseline ;
    margin: 0 0;
}

.SaltCalcContainer .SaltCalcMain .calcSeperator{
    height: calc(100% + 3px);
    width: 0.5px;
    background: var(--bluePrimaryLight);
    top: -1.5px;
    bottom: 0;
    position: absolute;
    left: 50%;
}

.SaltCalcContainer .SaltCalcMain .calcLeft{
    border-radius: 20px 0 0 20px;
}

.SaltCalcContainer .SaltCalcMain .calcRight{
    border-radius: 0 20px 20px 0;
}

.SaltCalcContainer .SaltCalcMain .calcSide .calcDirection{
    font-weight: 600;
    font-size: 24px;
    line-height: 175%;
    color: var(--gray4);
}

.SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownBtn{
    font-weight: 600;
    font-size: 32px;
    line-height: 175%;
    color: var(--gray2);
    margin: 11px 0 0;
    width: 107px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown:hover .dropDownBtn img{
    transition: all 0.1s;
}

.SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownOptions{
    position: absolute;
    font-weight: 400;
    font-size: 24px;
    line-height: 175%;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    background-color: white;
    transition: all 0.1s;
    border-radius: 12px;
    padding: 0 24px;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownOptions.openDropDown{
    padding: 16px 24px;
    font-weight: 400;
    max-height: 15em;
}

.SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownOptions .selectedOption{
    color: var(--bluePrimaryLight);
}


@media (hover:hover){
    .SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownBtn:hover{
        cursor: pointer;
    }

    .SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownOptions .dropDownOptionItem:hover{
        color: var(--bluePrimaryLight);
        cursor: pointer;
    }
}


.SaltCalcContainer .SaltCalcMain .calcSide input::-webkit-outer-spin-button,
.SaltCalcContainer .SaltCalcMain .calcSide input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.SaltCalcContainer .SaltCalcMain .calcSide input[type=number] {
  -moz-appearance: textfield;
}

.SaltCalcContainer .SaltCalcMain .calcSide input{
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    color: var(--bluePrimaryLight);
    width: 50%;
    border: none;
    outline: none;
    margin-top: 36px;
}

.SaltCalcContainer .SaltCalcMain .calcSide.disableInput input{
    background-color: var(--lightBlueBg);
}




@media screen and (max-width: 900px){
    .SaltCalcContainer{
        margin: 0 84px 48px;
    }

    .SaltCalcContainer .SaltCalcMain{
        width: inherit;
        border-radius: 12px;
    }

    .SaltCalcContainer .SaltCalcMain .calcLeft,
    .SaltCalcContainer .SaltCalcMain .calcRight{
        padding-bottom: 24px;
    }

    .SaltCalcContainer .SaltCalcMain .calcLeft{
        border-radius: 12px 0 0 12px;
    }
    
    .SaltCalcContainer .SaltCalcMain .calcRight{
        border-radius: 0 12px 12px 0;
    }

    .SaltCalcContainer .SaltCalcMain .calcSide .calcDirection{
        font-size: 16px;
        line-height: 175%;
    }

    .SaltCalcContainer .SaltCalcMain > img{
        top: calc(50% - 13.5px);
        left: calc(50% - 13.5px);
        height: 24px;
        width: 24px;
    }

    .SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownBtn{
        font-size: 24px;
        line-height: 175%;
        margin-top: 8px;
        width: 78px;
    }

    .SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownBtn img{
        height: 24px;
        width: 24px;
    }

    .SaltCalcContainer .SaltCalcMain .calcSide .calcDropDown .dropDownOptions{
        font-size: 16px;
    }

    .SaltCalcContainer .SaltCalcMain .calcSide input{
        font-size: 36px;
        line-height: 54px;
        margin-top: 24px;
    }

    .SaltCalcContainer .SaltCalcMain .calcLeft,
    .SaltCalcContainer .SaltCalcMain .calcRight{
        margin: 0;
    }
}


@media screen and (max-width: 550px){
    .SaltCalcContainer{
        margin: 0 28px 40px;
    }

    .SaltCalcContainer .SaltCalcHead{
        font-size: 24px;
        line-height: 36px;
    }
    
    .SaltCalcContainer .SaltCalcMain{
        margin-top: 24px;
        flex-direction: column;
    }

    .SaltCalcContainer .SaltCalcMain > img{
        top: calc(50% - 13.5px);
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    .SaltCalcContainer .SaltCalcMain img.changeDir{
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
    }

    .SaltCalcContainer .SaltCalcMain .calcLeft,
    .SaltCalcContainer .SaltCalcMain .calcRight{
        padding: 16px;
    }

    .SaltCalcContainer .SaltCalcMain .calcSeperator{
        width: calc(100% + 3px);
        height: 0.5px;
        background: var(--bluePrimaryLight);
        top: 50%;
        bottom: 0;
        position: absolute;
        left: -1.5px;
    }

    .SaltCalcContainer .SaltCalcMain .calcSide.calcLeft{
        border-radius: 12px 12px 0 0;
    }
    
    .SaltCalcContainer .SaltCalcMain .calcRight{
        border-radius: 0 0 12px 12px;
    }

}
.saltIndexContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 4em 0 0;
    margin-top: 50px;
}

.saltIndexContainer .saltIndexHeading {
    font-weight: 600;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
}

.saltIndexContainer .saltIndexTable {
    width: 68%;
    margin: 3.5em auto 5.5em;
}

.new-26{
    width: 100%;
    height: 25%;
    margin-top: -60px;
    margin-bottom: 30px;
}

.new-26 img{
    width: 68%;
    border-radius: 10px;
}





.saltIndexContainer .saltIndexTable .saltIndexTableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.saltIndexContainer .saltIndexTable .saltIndexTableHeader img.tabletSearchIcon {
    display: none;
}

.saltIndexContainer .saltIndexTable .saltIndexTableHeader .searchBar {
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 25%;
}

.saltIndexContainer .saltIndexTable .saltIndexTableHeader .searchBar input {
    border: none;
    outline: none;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    margin-left: 1em;
    width: 90%;
}

.saltIndexContainer .saltIndexTable .searchBar input::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.saltIndexContainer .saltIndexTable .searchBar input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.saltIndexContainer .saltIndexTable .saltPaginationContainer {
    display: flex;
    margin: 0 -6px;
}

.saltIndexContainer .saltIndexTable .saltPaginationContainer img {
    background-color: var(--bluePrimaryLight);
    margin: 0 6px;
    border-radius: 8px;
    padding: 6px;
    cursor: pointer;
}

.saltIndexContainer .saltIndexTable .saltPaginationContainer .paginationPgNo {
    font-weight: 500;
    font-size: 1em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    padding: 4px 15px;
    margin: 0 6px;
    cursor: pointer;
    display: none;
}

.saltIndexContainer .saltIndexTable .saltPaginationContainer .paginationPgNo.thisPage {
    background-color: var(--bluePrimaryLight);
    color: white;
}

.saltIndexContainer .saltIndexTable .saltPaginationContainer .paginationPgNo.showPageNo {
    display: inherit;
}

.saltIndexContainer .saltIndexTable input.tabletSearchBar {
    display: none;
}









.saltIndexContainer .saltIndexTable table {
    background-color: var(--lightBlueBg);
    position: relative;
    text-align: left;
    border-collapse: collapse;
    border-radius: 1.25em;
    width: 100%;
    margin: 1.25em auto;
    padding: 1.375em 0 0 1.5em;
}

.saltIndexContainer .saltIndexTable table::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    /* background: url("../../../images/pngs/logo\ icon.png"); */
    background: url(/static/media/coalIndexWaterMark.4d076aee.svg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
}

.saltIndexContainer .saltIndexTable table thead {
    color: var(--bluePrimaryLight);
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.75em;
}

.saltIndexContainer .saltIndexTable table thead tr {
    height: 5em;
}

.saltIndexContainer .saltIndexTable table thead tr th:first-of-type {
    padding-left: 1.5em;
}

.saltIndexContainer .saltIndexTable table tbody {
    font-size: 1em;
    line-height: 1.75em;
    font-weight: 500;
}

.saltIndexContainer .saltIndexTable table tbody tr {
    border-top: 1px solid var(--gray5);
    height: 6em;
}

.saltIndexContainer .saltIndexTable table tbody td {
    vertical-align: top;
    padding: 1.5em 0 0 0;
}

.saltIndexContainer .saltIndexTable table tr td:first-of-type {
    padding-left: 1.5em;
    padding-right: 7vw;
    width: 12.7vw;
}

.saltIndexContainer .saltIndexTable table tbody td .saltIndexDataTime {
    color: var(--gray3);
    font-size: 0.75em;
    line-height: 175%;
}

.saltIndexContainer .saltIndexTable table tbody td .priceChangeBlock {
    display: flex;
    align-items: center;
}

.saltIndexContainer .saltIndexTable table tbody td .priceChangeBlock img {
    margin-left: 5px;
}

.saltIndexContainer .saltIndexTable table tbody td .priceChangePer {
    font-size: 0.75em;
    line-height: 175%;
}


.saltIndexContainer .saltIndexTable table tr td.specialData {
    padding: 1em 0;
    text-align: center;
    margin: 1em 0;
    font-weight: 400;
    color: var(--gray3);
    border-top: 1px solid var(--gray5);

}


.saltIndexContainer .saltIndexTable .saltIndexTableFooter {
    display: flex;
    justify-content: right;
}


.subscribe-box {
    box-sizing: border-box;
    border: 1px solid #415AA9;
    border-radius: 20px;
    margin: 50px auto;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 68%;
}

.subscribe-info {
    margin: 0 auto;
    position: relative;
}

@-webkit-keyframes wiggle {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   80% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   85% { -webkit-transform: rotate(20deg); transform: rotate(20deg); }
   95% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
}

@keyframes wiggle {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   80% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   85% { -webkit-transform: rotate(20deg); transform: rotate(20deg); }
   95% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
}

.bell-icon{
    position: absolute;
    right: 50px;
}

.bell-icon img{
    width: 100px;
    -webkit-animation: wiggle 1.5s infinite;
            animation: wiggle 1.5s infinite;
}

.title-line-1 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    /* identical to box height */

    text-align: center;

    color: #415AA9;

}

.title-line-2 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;
    padding-top: 6px;
    color: #415AA9;

    opacity: 0.8;
}

.title-line-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    cursor: pointer;
    /* transition: all 2s ease; */
}

.title-line-3:before {
    content: "";
    position: absolute;
    /* top: 0; */
    left: 40px; 
    z-index: -1;
    display: block;
    border-radius: 28px;
    background: transparent;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.title-line-3:hover:before {
    width: 68%;
    background: #415AA9;
}

/* .title-line-3:hover{
    background: #415AA9;
    border-radius: 10px;
    padding: 12px;
 
    width: 200px;
} */

.title-line-3:hover span{
    color: #FFFFFF;
}

.title-line-3 span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */
    /* padding-top: 30px; */
    padding-right: 20px;

    color: #415AA9;
}

.subscribe-banner {
    padding: 18px 30px;
    z-index: 1;
}

.custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subscribe-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    /* text-align: justify; */
    margin: 0px;
    color: #282828;
}

.subscribe-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: justify;

    color: #7A7878;
    margin: 0px;
    padding-bottom: 35px;

}

.close-icon {
    align-self: start;
    cursor: pointer;
}

.background-box {
    position: relative;
}

.background-box svg {
    position: absolute;
    left: 0px;
    width: 612px;
    height: 590px;
}

.form-area {
    margin-top: 30px;
}

.field-box {

    margin-bottom: 20px;
}

.field-box input,textarea {
    width: 90%;
    background: #FDFDFF;
    border: 1px solid rgba(23, 104, 172, 0.23);
    border-radius: 6px;
    padding: 17px 23px;
}

.field-box input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.field-box input::placeholder,textarea::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    /* margin-left: 25px; */
}

.submit-btn:hover > p,svg{
    color: #3c8cd0;
}

.submit-btn p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    color: #1768AC;
    padding-right: 10px;
    margin: 0px;
}

.custom-footer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: justify;

    color: #161616;

}

.image-box svg {
    width: 100%;
}

.custom-body{
    display: flex;
    /* justify-content: space-between; */
}

.form-area{
    margin-left: 25px;
}

.custom-subscribe div.MuiDialog-paper{
    width: 70%;
    height: 590px;
    max-width: unset;
    overflow: hidden;
}

.custom-body-img{
    width: 50%;
}

.custom-body-form{
    width: 50%;
}

@media  (max-width:991px){
    .new-26{
        margin-top: 30px;
    }
    .new-26 img{
        width: 100%;
    }
    .background-box {
        display: none;
    }
    .custom-body-img{
        width: 100%;
    }
    
    .custom-body-form{
        width: 100%;
    }
    .custom-body{
        display: block;
        /* justify-content: space-between; */
    }
    .custom-subscribe div.MuiDialog-paper{
        width: 100%;
        height: auto;
        overflow: auto;
    }
    .form-area{
        width: 100%;
        margin-left: 0px;
    }
    .field-box input,textarea {
        width: 82%;
    }
    .subscribe-banner {
        padding: 18px 15px;
        z-index: 1;
    }
    /* .subscribe-title {
        font-size: 28px;
        line-height: 40px;
        margin-right: 10px;
        margin-bottom: 15px;
    } */
}

@media screen and (max-width: 1050px) {
    .saltIndexContainer {
        padding: 48px 50px 0;
        margin-bottom: 88px;
    }

    .saltIndexContainer .saltIndexTable {
        width: inherit;
        margin: 0;
    }

    .saltIndexContainer .saltIndexTable .view {
        width: inherit;
        margin: 20px 0 40px;
    }

    .saltIndexContainer .saltIndexTable .view .wrapper {
        position: relative;
        overflow: auto;
        white-space: nowrap;
        border-radius: 1.25em;
    }

    .saltIndexContainer .saltIndexTable table {
        margin: 0;
    }

    .saltIndexContainer .saltIndexTable table tbody tr td,
    .saltIndexContainer .saltIndexTable table thead tr th {
        padding-left: 16px;
    }

    .saltIndexContainer .saltIndexTable .view .wrapper table tbody tr td.stickyCol {
        padding-bottom: 1em;
    }

    .saltIndexContainer .saltIndexTable .view .wrapper table tbody tr td.stickyCol,
    .saltIndexContainer .saltIndexTable .view .wrapper table thead tr th.stickyCol {
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--lightBlueBg);
        left: -1px;
        white-space: pre-wrap;
        padding-right: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        /* white-space: nowrap; */
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }

}

@media screen and (max-width: 900px) {
    .saltIndexContainer {
        margin-bottom: 48px;
    }

    .saltIndexContainer .saltIndexTable .saltIndexTableHeader .searchBar {
        display: none;
    }

    .saltIndexContainer .saltIndexTable .saltIndexTableHeader img.tabletSearchIcon {
        display: block;
        margin-left: 8px;
    }

    .saltIndexContainer .saltIndexTable input.tabletSearchBar {
        border: none;
        outline: none;
        display: block;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
        width: calc(100% - 20px);
        border-radius: 8px;
        padding: 0 10px;
        max-height: 0;
        transition: all 0.2s;
        margin: 0;
        margin-top: 20px;
    }

    .saltIndexContainer .saltIndexTable input.tabletSearchBar::-webkit-input-placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .saltIndexContainer .saltIndexTable input.tabletSearchBar::placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .saltIndexContainer .saltIndexTable input.tabletSearchBar.showTabletSearchbar {
        max-height: 5em;
        padding: 6px 10px;
    }

    .saltIndexContainer .saltIndexTable .saltIndexTableHeader {
        margin-top: 40px;
    }

    .saltIndexContainer .saltIndexTable .saltPaginationContainer {
        margin: 0 -3px;
    }

    .saltIndexContainer .saltIndexTable .saltPaginationContainer img {
        height: 13px;
        width: 13px;
        margin: 0 3px;
        border-radius: 4px;
        padding: 6px;
    }

    .saltIndexContainer .saltIndexTable .saltPaginationContainer .paginationPgNo {
        font-size: 12px;
        line-height: 175%;
        padding: 0;
        height: 23px;
        width: 23px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    .saltIndexContainer .saltIndexTable .saltIndexTableFooter {
        justify-content: center;
    }

    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 600px) {
    .saltIndexContainer {
        padding: 40px 28px 0;
        margin-bottom: 40px;
    }

    .saltIndexContainer .saltIndexHeading {
        font-size: 24px;
        line-height: 36px;
    }

    .saltIndexContainer .saltIndexTable input.tabletSearchBar {
        margin-top: 16px;
    }

    .saltIndexContainer .saltIndexTable .view {
        margin: 16px 0 24px;
    }

    .saltIndexContainer .saltIndexTable table {
        border-radius: 8px;
    }

    .saltIndexContainer .saltIndexTable table thead tr th {
        font-size: 12px;
        line-height: 175%;
    }

    .saltIndexContainer .saltIndexTable table tbody tr td {
        font-size: 14px;
        line-height: 175%;
        padding-top: 25px;
    }

    .saltIndexContainer .saltIndexTable .view .wrapper table tbody tr td.stickyCol {
        display: block;
        white-space: normal;
        margin-right: 0;
        width: 127px;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 300px) {

    .saltIndexContainer .saltIndexTable .view .wrapper table tbody tr td.stickyCol,
    .saltIndexContainer .saltIndexTable .view .wrapper table thead tr th.stickyCol {
        position: initial;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}
.CoalCalcContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
}

.CoalCalcContainer .CoalCalcHead{
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.02em;
}

.CoalCalcContainer .CoalCalcMain{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 46px auto 0;
    border: 1.5px solid var(--bluePrimaryLight);
    border-radius: 20px;
    width: 68%;
    position: relative;
}

.CoalCalcContainer .CoalCalcMain .disableInput{
    background-color: var(--lightBlueBg);   
}

.CoalCalcContainer .CoalCalcMain > img{
    padding: 0px;
    border-radius: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1.5px solid var(--bluePrimaryLight);
    position: absolute;
    left: calc(50% - 19.5px);
    top: calc(50% - 19.5px);
    background-color: white;
}

.CoalCalcContainer .CoalCalcMain img.changeDir{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.CoalCalcContainer .CoalCalcMain .calcLeft,
.CoalCalcContainer .CoalCalcMain .calcRight{
    padding: 24px 0 38px 36px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items:baseline ;
    margin: 0 0;
}

.CoalCalcContainer .CoalCalcMain .calcSeperator{
    height: calc(100% + 3px);
    width: 0.5px;
    background: var(--bluePrimaryLight);
    top: -1.5px;
    bottom: 0;
    position: absolute;
    left: 50%;
}

.CoalCalcContainer .CoalCalcMain .calcLeft{
    border-radius: 20px 0 0 20px;
}

.CoalCalcContainer .CoalCalcMain .calcRight{
    border-radius: 0 20px 20px 0;
}

.CoalCalcContainer .CoalCalcMain .calcSide .calcDirection{
    font-weight: 600;
    font-size: 24px;
    line-height: 175%;
    color: var(--gray4);
}

.CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownBtn{
    font-weight: 600;
    font-size: 32px;
    line-height: 175%;
    color: var(--gray2);
    margin: 11px 0 0;
    width: 107px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown:hover .dropDownBtn img{
    transition: all 0.1s;
}

.CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownOptions{
    position: absolute;
    font-weight: 400;
    font-size: 24px;
    line-height: 175%;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    background-color: white;
    transition: all 0.1s;
    border-radius: 12px;
    padding: 0 24px;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownOptions.openDropDown{
    padding: 16px 24px;
    font-weight: 400;
    max-height: 15em;
}

.CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownOptions .selectedOption{
    color: var(--bluePrimaryLight);
}


@media (hover:hover){
    .CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownBtn:hover{
        cursor: pointer;
    }

    .CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownOptions .dropDownOptionItem:hover{
        color: var(--bluePrimaryLight);
        cursor: pointer;
    }
}


.CoalCalcContainer .CoalCalcMain .calcSide input::-webkit-outer-spin-button,
.CoalCalcContainer .CoalCalcMain .calcSide input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.CoalCalcContainer .CoalCalcMain .calcSide input[type=number] {
  -moz-appearance: textfield;
}

.CoalCalcContainer .CoalCalcMain .calcSide input{
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    color: var(--bluePrimaryLight);
    width: 50%;
    border: none;
    outline: none;
    margin-top: 36px;
}

.CoalCalcContainer .CoalCalcMain .calcSide.disableInput input{
    background-color: var(--lightBlueBg);
}




@media screen and (max-width: 900px){
    .CoalCalcContainer{
        margin: 0 84px 48px;
    }

    .CoalCalcContainer .CoalCalcMain{
        width: inherit;
        border-radius: 12px;
    }

    .CoalCalcContainer .CoalCalcMain .calcLeft,
    .CoalCalcContainer .CoalCalcMain .calcRight{
        padding-bottom: 24px;
    }

    .CoalCalcContainer .CoalCalcMain .calcLeft{
        border-radius: 12px 0 0 12px;
    }
    
    .CoalCalcContainer .CoalCalcMain .calcRight{
        border-radius: 0 12px 12px 0;
    }

    .CoalCalcContainer .CoalCalcMain .calcSide .calcDirection{
        font-size: 16px;
        line-height: 175%;
    }

    .CoalCalcContainer .CoalCalcMain > img{
        top: calc(50% - 13.5px);
        left: calc(50% - 13.5px);
        height: 24px;
        width: 24px;
    }

    .CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownBtn{
        font-size: 24px;
        line-height: 175%;
        margin-top: 8px;
        width: 78px;
    }

    .CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownBtn img{
        height: 24px;
        width: 24px;
    }

    .CoalCalcContainer .CoalCalcMain .calcSide .calcDropDown .dropDownOptions{
        font-size: 16px;
    }

    .CoalCalcContainer .CoalCalcMain .calcSide input{
        font-size: 36px;
        line-height: 54px;
        margin-top: 24px;
    }

    .CoalCalcContainer .CoalCalcMain .calcLeft,
    .CoalCalcContainer .CoalCalcMain .calcRight{
        margin: 0;
    }
}


@media screen and (max-width: 550px){
    .CoalCalcContainer{
        margin: 0 28px 40px;
    }

    .CoalCalcContainer .CoalCalcHead{
        font-size: 24px;
        line-height: 36px;
    }
    
    .CoalCalcContainer .CoalCalcMain{
        margin-top: 24px;
        flex-direction: column;
    }

    .CoalCalcContainer .CoalCalcMain > img{
        top: calc(50% - 13.5px);
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    .CoalCalcContainer .CoalCalcMain img.changeDir{
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
    }

    .CoalCalcContainer .CoalCalcMain .calcLeft,
    .CoalCalcContainer .CoalCalcMain .calcRight{
        padding: 16px;
    }

    .CoalCalcContainer .CoalCalcMain .calcSeperator{
        width: calc(100% + 3px);
        height: 0.5px;
        background: var(--bluePrimaryLight);
        top: 50%;
        bottom: 0;
        position: absolute;
        left: -1.5px;
    }

    .CoalCalcContainer .CoalCalcMain .calcSide.calcLeft{
        border-radius: 12px 12px 0 0;
    }
    
    .CoalCalcContainer .CoalCalcMain .calcRight{
        border-radius: 0 0 12px 12px;
    }

}
.coalIndexContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 4em 0 0;
    margin-top: 50px;
}

.coalIndexContainer .coalIndexHeading {
    font-weight: 600;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
}

.coalIndexContainer .coalIndexTable {
    width: 68%;
    margin: 3.5em auto 5.5em;
}

.new-26{
    width: 100%;
    height: 25%;
    margin-top: -60px;
    margin-bottom: 30px;
}

.new-26 img{
    width: 68%;
    border-radius: 10px;
}





.coalIndexContainer .coalIndexTable .coalIndexTableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.coalIndexContainer .coalIndexTable .coalIndexTableHeader img.tabletSearchIcon {
    display: none;
}

.coalIndexContainer .coalIndexTable .coalIndexTableHeader .searchBar {
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 25%;
}

.coalIndexContainer .coalIndexTable .coalIndexTableHeader .searchBar input {
    border: none;
    outline: none;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    margin-left: 1em;
    width: 90%;
}

.coalIndexContainer .coalIndexTable .searchBar input::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.coalIndexContainer .coalIndexTable .searchBar input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer {
    display: flex;
    margin: 0 -6px;
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer img {
    background-color: var(--bluePrimaryLight);
    margin: 0 6px;
    border-radius: 8px;
    padding: 6px;
    cursor: pointer;
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer .paginationPgNo {
    font-weight: 500;
    font-size: 1em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    padding: 4px 15px;
    margin: 0 6px;
    cursor: pointer;
    display: none;
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer .paginationPgNo.thisPage {
    background-color: var(--bluePrimaryLight);
    color: white;
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer .paginationPgNo.showPageNo {
    display: inherit;
}

.coalIndexContainer .coalIndexTable input.tabletSearchBar {
    display: none;
}









.coalIndexContainer .coalIndexTable table {
    background-color: var(--lightBlueBg);
    position: relative;
    text-align: left;
    border-collapse: collapse;
    border-radius: 1.25em;
    width: 100%;
    margin: 1.25em auto;
    padding: 1.375em 0 0 1.5em;
}

.coalIndexContainer .coalIndexTable table::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    /* background: url("../../../images/pngs/logo\ icon.png"); */
    background: url(/static/media/coalIndexWaterMark.4d076aee.svg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
}

.coalIndexContainer .coalIndexTable table thead {
    color: var(--bluePrimaryLight);
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.75em;
}

.coalIndexContainer .coalIndexTable table thead tr {
    height: 5em;
}

.coalIndexContainer .coalIndexTable table thead tr th:first-of-type {
    padding-left: 1.5em;
}

.coalIndexContainer .coalIndexTable table tbody {
    font-size: 1em;
    line-height: 1.75em;
    font-weight: 500;
}

.coalIndexContainer .coalIndexTable table tbody tr {
    border-top: 1px solid var(--gray5);
    height: 6em;
}

.coalIndexContainer .coalIndexTable table tbody td {
    vertical-align: top;
    padding: 1.5em 0 0 0;
}

.coalIndexContainer .coalIndexTable table tr td:first-of-type {
    padding-left: 1.5em;
    padding-right: 7vw;
    width: 12.7vw;
}

.coalIndexContainer .coalIndexTable table tbody td .coalIndexDataTime {
    color: var(--gray3);
    font-size: 0.75em;
    line-height: 175%;
}

.coalIndexContainer .coalIndexTable table tbody td .priceChangeBlock {
    display: flex;
    align-items: center;
}

.coalIndexContainer .coalIndexTable table tbody td .priceChangeBlock img {
    margin-left: 5px;
}

.coalIndexContainer .coalIndexTable table tbody td .priceChangePer {
    font-size: 0.75em;
    line-height: 175%;
}


.coalIndexContainer .coalIndexTable table tr td.specialData {
    padding: 1em 0;
    text-align: center;
    margin: 1em 0;
    font-weight: 400;
    color: var(--gray3);
    border-top: 1px solid var(--gray5);

}


.coalIndexContainer .coalIndexTable .coalIndexTableFooter {
    display: flex;
    justify-content: right;
}


.subscribe-box {
    box-sizing: border-box;
    border: 1px solid #415AA9;
    border-radius: 20px;
    margin: 50px auto;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 68%;
}

.subscribe-info {
    margin: 0 auto;
    position: relative;
}

@-webkit-keyframes wiggle {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   80% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   85% { -webkit-transform: rotate(20deg); transform: rotate(20deg); }
   95% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
}

@keyframes wiggle {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   80% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   85% { -webkit-transform: rotate(20deg); transform: rotate(20deg); }
   95% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
}

.bell-icon{
    position: absolute;
    right: 50px;
}

.bell-icon img{
    width: 100px;
    -webkit-animation: wiggle 1.5s infinite;
            animation: wiggle 1.5s infinite;
}

.title-line-1 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    /* identical to box height */

    text-align: center;

    color: #415AA9;

}

.title-line-2 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;
    padding-top: 6px;
    color: #415AA9;

    opacity: 0.8;
}

.title-line-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    cursor: pointer;
    /* transition: all 2s ease; */
}

.title-line-3:before {
    content: "";
    position: absolute;
    /* top: 0; */
    left: 40px; 
    z-index: -1;
    display: block;
    border-radius: 28px;
    background: transparent;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.title-line-3:hover:before {
    width: 68%;
    background: #415AA9;
}

/* .title-line-3:hover{
    background: #415AA9;
    border-radius: 10px;
    padding: 12px;
 
    width: 200px;
} */

.title-line-3:hover span{
    color: #FFFFFF;
}

.title-line-3 span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */
    /* padding-top: 30px; */
    padding-right: 20px;

    color: #415AA9;
}

.subscribe-banner {
    padding: 18px 30px;
    z-index: 1;
}

.custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subscribe-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    /* text-align: justify; */
    margin: 0px;
    color: #282828;
}

.subscribe-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: justify;

    color: #7A7878;
    margin: 0px;
    padding-bottom: 35px;

}

.close-icon {
    align-self: start;
    cursor: pointer;
}

.background-box {
    position: relative;
}

.background-box svg {
    position: absolute;
    left: 0px;
    width: 612px;
    height: 590px;
}

.form-area {
    margin-top: 30px;
}

.field-box {

    margin-bottom: 20px;
}

.field-box input,textarea {
    width: 90%;
    background: #FDFDFF;
    border: 1px solid rgba(23, 104, 172, 0.23);
    border-radius: 6px;
    padding: 17px 23px;
}

.field-box input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.field-box input::placeholder,textarea::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    /* margin-left: 25px; */
}

.submit-btn:hover > p,svg{
    color: #3c8cd0;
}

.submit-btn p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    color: #1768AC;
    padding-right: 10px;
    margin: 0px;
}

.custom-footer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: justify;

    color: #161616;

}

.image-box svg {
    width: 100%;
}

.custom-body{
    display: flex;
    /* justify-content: space-between; */
}

.form-area{
    margin-left: 25px;
}

.custom-subscribe div.MuiDialog-paper{
    width: 70%;
    height: 590px;
    max-width: unset;
    overflow: hidden;
}

.custom-body-img{
    width: 50%;
}

.custom-body-form{
    width: 50%;
}

@media  (max-width:991px){
    .new-26{
        margin-top: 30px;
    }
    .new-26 img{
        width: 100%;
    }
    .background-box {
        display: none;
    }
    .custom-body-img{
        width: 100%;
    }
    
    .custom-body-form{
        width: 100%;
    }
    .custom-body{
        display: block;
        /* justify-content: space-between; */
    }
    .custom-subscribe div.MuiDialog-paper{
        width: 100%;
        height: auto;
        overflow: auto;
    }
    .form-area{
        width: 100%;
        margin-left: 0px;
    }
    .field-box input,textarea {
        width: 82%;
    }
    .subscribe-banner {
        padding: 18px 15px;
        z-index: 1;
    }
    /* .subscribe-title {
        font-size: 28px;
        line-height: 40px;
        margin-right: 10px;
        margin-bottom: 15px;
    } */
}

@media screen and (max-width: 1050px) {
    .coalIndexContainer {
        padding: 48px 50px 0;
        margin-bottom: 88px;
    }

    .coalIndexContainer .coalIndexTable {
        width: inherit;
        margin: 0;
    }

    .coalIndexContainer .coalIndexTable .view {
        width: inherit;
        margin: 20px 0 40px;
    }

    .coalIndexContainer .coalIndexTable .view .wrapper {
        position: relative;
        overflow: auto;
        white-space: nowrap;
        border-radius: 1.25em;
    }

    .coalIndexContainer .coalIndexTable table {
        margin: 0;
    }

    .coalIndexContainer .coalIndexTable table tbody tr td,
    .coalIndexContainer .coalIndexTable table thead tr th {
        padding-left: 16px;
    }

    .coalIndexContainer .coalIndexTable .view .wrapper table tbody tr td.stickyCol {
        padding-bottom: 1em;
    }

    .coalIndexContainer .coalIndexTable .view .wrapper table tbody tr td.stickyCol,
    .coalIndexContainer .coalIndexTable .view .wrapper table thead tr th.stickyCol {
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--lightBlueBg);
        left: -1px;
        white-space: pre-wrap;
        padding-right: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        /* white-space: nowrap; */
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }

}

@media screen and (max-width: 900px) {
    .coalIndexContainer {
        margin-bottom: 48px;
    }

    .coalIndexContainer .coalIndexTable .coalIndexTableHeader .searchBar {
        display: none;
    }

    .coalIndexContainer .coalIndexTable .coalIndexTableHeader img.tabletSearchIcon {
        display: block;
        margin-left: 8px;
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar {
        border: none;
        outline: none;
        display: block;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
        width: calc(100% - 20px);
        border-radius: 8px;
        padding: 0 10px;
        max-height: 0;
        transition: all 0.2s;
        margin: 0;
        margin-top: 20px;
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar::-webkit-input-placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar::placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar.showTabletSearchbar {
        max-height: 5em;
        padding: 6px 10px;
    }

    .coalIndexContainer .coalIndexTable .coalIndexTableHeader {
        margin-top: 40px;
    }

    .coalIndexContainer .coalIndexTable .coalPaginationContainer {
        margin: 0 -3px;
    }

    .coalIndexContainer .coalIndexTable .coalPaginationContainer img {
        height: 13px;
        width: 13px;
        margin: 0 3px;
        border-radius: 4px;
        padding: 6px;
    }

    .coalIndexContainer .coalIndexTable .coalPaginationContainer .paginationPgNo {
        font-size: 12px;
        line-height: 175%;
        padding: 0;
        height: 23px;
        width: 23px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    .coalIndexContainer .coalIndexTable .coalIndexTableFooter {
        justify-content: center;
    }

    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 600px) {
    .coalIndexContainer {
        padding: 40px 28px 0;
        margin-bottom: 40px;
    }

    .coalIndexContainer .coalIndexHeading {
        font-size: 24px;
        line-height: 36px;
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar {
        margin-top: 16px;
    }

    .coalIndexContainer .coalIndexTable .view {
        margin: 16px 0 24px;
    }

    .coalIndexContainer .coalIndexTable table {
        border-radius: 8px;
    }

    .coalIndexContainer .coalIndexTable table thead tr th {
        font-size: 12px;
        line-height: 175%;
    }

    .coalIndexContainer .coalIndexTable table tbody tr td {
        font-size: 14px;
        line-height: 175%;
        padding-top: 25px;
    }

    .coalIndexContainer .coalIndexTable .view .wrapper table tbody tr td.stickyCol {
        display: block;
        white-space: normal;
        margin-right: 0;
        width: 127px;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 300px) {

    .coalIndexContainer .coalIndexTable .view .wrapper table tbody tr td.stickyCol,
    .coalIndexContainer .coalIndexTable .view .wrapper table thead tr th.stickyCol {
        position: initial;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}
.TimberCalcContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
}

.TimberCalcContainer .TimberCalcHead{
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.02em;
}

.TimberCalcContainer .TimberCalcMain{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 46px auto 0;
    border: 1.5px solid var(--bluePrimaryLight);
    border-radius: 20px;
    width: 68%;
    position: relative;
}

.TimberCalcContainer .TimberCalcMain .disableInput{
    background-color: var(--lightBlueBg);   
}

.TimberCalcContainer .TimberCalcMain > img{
    padding: 0px;
    border-radius: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1.5px solid var(--bluePrimaryLight);
    position: absolute;
    left: calc(50% - 19.5px);
    top: calc(50% - 19.5px);
    background-color: white;
}

.TimberCalcContainer .TimberCalcMain img.changeDir{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.TimberCalcContainer .TimberCalcMain .calcLeft,
.TimberCalcContainer .TimberCalcMain .calcRight{
    padding: 24px 0 38px 36px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items:baseline ;
    margin: 0 0;
}

.TimberCalcContainer .TimberCalcMain .calcSeperator{
    height: calc(100% + 3px);
    width: 0.5px;
    background: var(--bluePrimaryLight);
    top: -1.5px;
    bottom: 0;
    position: absolute;
    left: 50%;
}

.TimberCalcContainer .TimberCalcMain .calcLeft{
    border-radius: 20px 0 0 20px;
}

.TimberCalcContainer .TimberCalcMain .calcRight{
    border-radius: 0 20px 20px 0;
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDirection{
    font-weight: 600;
    font-size: 24px;
    line-height: 175%;
    color: var(--gray4);
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownBtn{
    font-weight: 600;
    font-size: 32px;
    line-height: 175%;
    color: var(--gray2);
    margin: 11px 0 0;
    width: 107px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown:hover .dropDownBtn img{
    transition: all 0.1s;
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions{
    position: absolute;
    font-weight: 400;
    font-size: 24px;
    line-height: 175%;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    background-color: white;
    transition: all 0.1s;
    border-radius: 12px;
    padding: 0 24px;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions.openDropDown{
    padding: 16px 24px;
    font-weight: 400;
    max-height: 15em;
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions .selectedOption{
    color: var(--bluePrimaryLight);
}


@media (hover:hover){
    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownBtn:hover{
        cursor: pointer;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions .dropDownOptionItem:hover{
        color: var(--bluePrimaryLight);
        cursor: pointer;
    }
}


.TimberCalcContainer .TimberCalcMain .calcSide input::-webkit-outer-spin-button,
.TimberCalcContainer .TimberCalcMain .calcSide input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.TimberCalcContainer .TimberCalcMain .calcSide input[type=number] {
  -moz-appearance: textfield;
}

.TimberCalcContainer .TimberCalcMain .calcSide input{
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    color: var(--bluePrimaryLight);
    width: 50%;
    border: none;
    outline: none;
    margin-top: 36px;
}

.TimberCalcContainer .TimberCalcMain .calcSide.disableInput input{
    background-color: var(--lightBlueBg);
}




@media screen and (max-width: 900px){
    .TimberCalcContainer{
        margin: 0 84px 48px;
    }

    .TimberCalcContainer .TimberCalcMain{
        width: inherit;
        border-radius: 12px;
    }

    .TimberCalcContainer .TimberCalcMain .calcLeft,
    .TimberCalcContainer .TimberCalcMain .calcRight{
        padding-bottom: 24px;
    }

    .TimberCalcContainer .TimberCalcMain .calcLeft{
        border-radius: 12px 0 0 12px;
    }
    
    .TimberCalcContainer .TimberCalcMain .calcRight{
        border-radius: 0 12px 12px 0;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDirection{
        font-size: 16px;
        line-height: 175%;
    }

    .TimberCalcContainer .TimberCalcMain > img{
        top: calc(50% - 13.5px);
        left: calc(50% - 13.5px);
        height: 24px;
        width: 24px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownBtn{
        font-size: 24px;
        line-height: 175%;
        margin-top: 8px;
        width: 78px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownBtn img{
        height: 24px;
        width: 24px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions{
        font-size: 16px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide input{
        font-size: 36px;
        line-height: 54px;
        margin-top: 24px;
    }

    .TimberCalcContainer .TimberCalcMain .calcLeft,
    .TimberCalcContainer .TimberCalcMain .calcRight{
        margin: 0;
    }
}


@media screen and (max-width: 550px){
    .TimberCalcContainer{
        margin: 0 28px 40px;
    }

    .TimberCalcContainer .TimberCalcHead{
        font-size: 24px;
        line-height: 36px;
    }
    
    .TimberCalcContainer .TimberCalcMain{
        margin-top: 24px;
        flex-direction: column;
    }

    .TimberCalcContainer .TimberCalcMain > img{
        top: calc(50% - 13.5px);
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    .TimberCalcContainer .TimberCalcMain img.changeDir{
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
    }

    .TimberCalcContainer .TimberCalcMain .calcLeft,
    .TimberCalcContainer .TimberCalcMain .calcRight{
        padding: 16px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSeperator{
        width: calc(100% + 3px);
        height: 0.5px;
        background: var(--bluePrimaryLight);
        top: 50%;
        bottom: 0;
        position: absolute;
        left: -1.5px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide.calcLeft{
        border-radius: 12px 12px 0 0;
    }
    
    .TimberCalcContainer .TimberCalcMain .calcRight{
        border-radius: 0 0 12px 12px;
    }

}
.timberIndexContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 4em 0 0;
    margin-top: 50px;
}

.timberIndexContainer .timberIndexHeading {
    font-weight: 600;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
}

.timberIndexContainer .timberIndexTable {
    width: 68%;
    margin: 3.5em auto 5.5em;
}

.new-26{
    width: 100%;
    height: 25%;
    margin-top: -60px;
    margin-bottom: 30px;
}

.new-26 img{
    width: 68%;
    border-radius: 10px;
}





.timberIndexContainer .timberIndexTable .timberIndexTableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timberIndexContainer .timberIndexTable .timberIndexTableHeader img.tabletSearchIcon {
    display: none;
}

.timberIndexContainer .timberIndexTable .timberIndexTableHeader .searchBar {
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 25%;
}

.timberIndexContainer .timberIndexTable .timberIndexTableHeader .searchBar input {
    border: none;
    outline: none;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    margin-left: 1em;
    width: 90%;
}

.timberIndexContainer .timberIndexTable .searchBar input::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.timberIndexContainer .timberIndexTable .searchBar input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer {
    display: flex;
    margin: 0 -6px;
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer img {
    background-color: var(--bluePrimaryLight);
    margin: 0 6px;
    border-radius: 8px;
    padding: 6px;
    cursor: pointer;
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer .paginationPgNo {
    font-weight: 500;
    font-size: 1em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    padding: 4px 15px;
    margin: 0 6px;
    cursor: pointer;
    display: none;
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer .paginationPgNo.thisPage {
    background-color: var(--bluePrimaryLight);
    color: white;
}

.timberIndexContainer .timberIndexTable .timberPaginationContainer .paginationPgNo.showPageNo {
    display: inherit;
}

.timberIndexContainer .timberIndexTable input.tabletSearchBar {
    display: none;
}









.timberIndexContainer .timberIndexTable table {
    background-color: var(--lightBlueBg);
    position: relative;
    text-align: left;
    border-collapse: collapse;
    border-radius: 1.25em;
    width: 100%;
    margin: 1.25em auto;
    padding: 1.375em 0 0 1.5em;
}

.timberIndexContainer .timberIndexTable table::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    /* background: url("../../../images/pngs/logo\ icon.png"); */
    background: url(/static/media/coalIndexWaterMark.4d076aee.svg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
}

.timberIndexContainer .timberIndexTable table thead {
    color: var(--bluePrimaryLight);
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.75em;
}

.timberIndexContainer .timberIndexTable table thead tr {
    height: 5em;
}

.timberIndexContainer .timberIndexTable table thead tr th:first-of-type {
    padding-left: 1.5em;
}

.timberIndexContainer .timberIndexTable table tbody {
    font-size: 1em;
    line-height: 1.75em;
    font-weight: 500;
}

.timberIndexContainer .timberIndexTable table tbody tr {
    border-top: 1px solid var(--gray5);
    height: 6em;
}

.timberIndexContainer .timberIndexTable table tbody td {
    vertical-align: top;
    padding: 1.5em 0 0 0;
}

.timberIndexContainer .timberIndexTable table tr td:first-of-type {
    padding-left: 1.5em;
    padding-right: 7vw;
    width: 12.7vw;
}

.timberIndexContainer .timberIndexTable table tbody td .timberIndexDataTime {
    color: var(--gray3);
    font-size: 0.75em;
    line-height: 175%;
}

.timberIndexContainer .timberIndexTable table tbody td .priceChangeBlock {
    display: flex;
    align-items: center;
}

.timberIndexContainer .timberIndexTable table tbody td .priceChangeBlock img {
    margin-left: 5px;
}

.timberIndexContainer .timberIndexTable table tbody td .priceChangePer {
    font-size: 0.75em;
    line-height: 175%;
}


.timberIndexContainer .timberIndexTable table tr td.specialData {
    padding: 1em 0;
    text-align: center;
    margin: 1em 0;
    font-weight: 400;
    color: var(--gray3);
    border-top: 1px solid var(--gray5);

}


.timberIndexContainer .timberIndexTable .timberIndexTableFooter {
    display: flex;
    justify-content: right;
}


.subscribe-box {
    box-sizing: border-box;
    border: 1px solid #415AA9;
    border-radius: 20px;
    margin: 50px auto;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 68%;
}

.subscribe-info {
    margin: 0 auto;
    position: relative;
}

@-webkit-keyframes wiggle {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   80% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   85% { -webkit-transform: rotate(20deg); transform: rotate(20deg); }
   95% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
}

@keyframes wiggle {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   80% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   85% { -webkit-transform: rotate(20deg); transform: rotate(20deg); }
   95% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
}

.bell-icon{
    position: absolute;
    right: 50px;
}

.bell-icon img{
    width: 100px;
    -webkit-animation: wiggle 1.5s infinite;
            animation: wiggle 1.5s infinite;
}

.title-line-1 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    /* identical to box height */

    text-align: center;

    color: #415AA9;

}

.title-line-2 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;
    padding-top: 6px;
    color: #415AA9;

    opacity: 0.8;
}

.title-line-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    cursor: pointer;
    /* transition: all 2s ease; */
}

.title-line-3:before {
    content: "";
    position: absolute;
    /* top: 0; */
    left: 40px; 
    z-index: -1;
    display: block;
    border-radius: 28px;
    background: transparent;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.title-line-3:hover:before {
    width: 68%;
    background: #415AA9;
}

/* .title-line-3:hover{
    background: #415AA9;
    border-radius: 10px;
    padding: 12px;
 
    width: 200px;
} */

.title-line-3:hover span{
    color: #FFFFFF;
}

.title-line-3 span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */
    /* padding-top: 30px; */
    padding-right: 20px;

    color: #415AA9;
}

.subscribe-banner {
    padding: 18px 30px;
    z-index: 1;
}

.custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subscribe-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    /* text-align: justify; */
    margin: 0px;
    color: #282828;
}

.subscribe-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: justify;

    color: #7A7878;
    margin: 0px;
    padding-bottom: 35px;

}

.close-icon {
    align-self: start;
    cursor: pointer;
}

.background-box {
    position: relative;
}

.background-box svg {
    position: absolute;
    left: 0px;
    width: 612px;
    height: 590px;
}

.form-area {
    margin-top: 30px;
}

.field-box {

    margin-bottom: 20px;
}

.field-box input,textarea {
    width: 90%;
    background: #FDFDFF;
    border: 1px solid rgba(23, 104, 172, 0.23);
    border-radius: 6px;
    padding: 17px 23px;
}

.field-box input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.field-box input::placeholder,textarea::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    /* margin-left: 25px; */
}

.submit-btn:hover > p,svg{
    color: #3c8cd0;
}

.submit-btn p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    color: #1768AC;
    padding-right: 10px;
    margin: 0px;
}

.custom-footer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: justify;

    color: #161616;

}

.image-box svg {
    width: 100%;
}

.custom-body{
    display: flex;
    /* justify-content: space-between; */
}

.form-area{
    margin-left: 25px;
}

.custom-subscribe div.MuiDialog-paper{
    width: 70%;
    height: 590px;
    max-width: unset;
    overflow: hidden;
}

.custom-body-img{
    width: 50%;
}

.custom-body-form{
    width: 50%;
}

@media  (max-width:991px){
    .new-26{
        margin-top: 30px;
    }
    .new-26 img{
        width: 100%;
    }
    .background-box {
        display: none;
    }
    .custom-body-img{
        width: 100%;
    }
    
    .custom-body-form{
        width: 100%;
    }
    .custom-body{
        display: block;
        /* justify-content: space-between; */
    }
    .custom-subscribe div.MuiDialog-paper{
        width: 100%;
        height: auto;
        overflow: auto;
    }
    .form-area{
        width: 100%;
        margin-left: 0px;
    }
    .field-box input,textarea {
        width: 82%;
    }
    .subscribe-banner {
        padding: 18px 15px;
        z-index: 1;
    }
    /* .subscribe-title {
        font-size: 28px;
        line-height: 40px;
        margin-right: 10px;
        margin-bottom: 15px;
    } */
}

@media screen and (max-width: 1050px) {
    .timberIndexContainer {
        padding: 48px 50px 0;
        margin-bottom: 88px;
    }

    .timberIndexContainer .timberIndexTable {
        width: inherit;
        margin: 0;
    }

    .timberIndexContainer .timberIndexTable .view {
        width: inherit;
        margin: 20px 0 40px;
    }

    .timberIndexContainer .timberIndexTable .view .wrapper {
        position: relative;
        overflow: auto;
        white-space: nowrap;
        border-radius: 1.25em;
    }

    .timberIndexContainer .timberIndexTable table {
        margin: 0;
    }

    .timberIndexContainer .timberIndexTable table tbody tr td,
    .timberIndexContainer .timberIndexTable table thead tr th {
        padding-left: 16px;
    }

    .timberIndexContainer .timberIndexTable .view .wrapper table tbody tr td.stickyCol {
        padding-bottom: 1em;
    }

    .timberIndexContainer .timberIndexTable .view .wrapper table tbody tr td.stickyCol,
    .timberIndexContainer .timberIndexTable .view .wrapper table thead tr th.stickyCol {
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--lightBlueBg);
        left: -1px;
        white-space: pre-wrap;
        padding-right: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        /* white-space: nowrap; */
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }

}

@media screen and (max-width: 900px) {
    .timberIndexContainer {
        margin-bottom: 48px;
    }

    .timberIndexContainer .timberIndexTable .timberIndexTableHeader .searchBar {
        display: none;
    }

    .timberIndexContainer .timberIndexTable .timberIndexTableHeader img.tabletSearchIcon {
        display: block;
        margin-left: 8px;
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar {
        border: none;
        outline: none;
        display: block;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
        width: calc(100% - 20px);
        border-radius: 8px;
        padding: 0 10px;
        max-height: 0;
        transition: all 0.2s;
        margin: 0;
        margin-top: 20px;
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar::-webkit-input-placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar::placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar.showTabletSearchbar {
        max-height: 5em;
        padding: 6px 10px;
    }

    .timberIndexContainer .timberIndexTable .timberIndexTableHeader {
        margin-top: 40px;
    }

    .timberIndexContainer .timberIndexTable .timberPaginationContainer {
        margin: 0 -3px;
    }

    .timberIndexContainer .timberIndexTable .timberPaginationContainer img {
        height: 13px;
        width: 13px;
        margin: 0 3px;
        border-radius: 4px;
        padding: 6px;
    }

    .timberIndexContainer .timberIndexTable .timberPaginationContainer .paginationPgNo {
        font-size: 12px;
        line-height: 175%;
        padding: 0;
        height: 23px;
        width: 23px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    .timberIndexContainer .timberIndexTable .timberIndexTableFooter {
        justify-content: center;
    }

    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 600px) {
    .timberIndexContainer {
        padding: 40px 28px 0;
        margin-bottom: 40px;
    }

    .timberIndexContainer .timberIndexHeading {
        font-size: 24px;
        line-height: 36px;
    }

    .timberIndexContainer .timberIndexTable input.tabletSearchBar {
        margin-top: 16px;
    }

    .timberIndexContainer .timberIndexTable .view {
        margin: 16px 0 24px;
    }

    .timberIndexContainer .timberIndexTable table {
        border-radius: 8px;
    }

    .timberIndexContainer .timberIndexTable table thead tr th {
        font-size: 12px;
        line-height: 175%;
    }

    .timberIndexContainer .timberIndexTable table tbody tr td {
        font-size: 14px;
        line-height: 175%;
        padding-top: 25px;
    }

    .timberIndexContainer .timberIndexTable .view .wrapper table tbody tr td.stickyCol {
        display: block;
        white-space: normal;
        margin-right: 0;
        width: 127px;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 300px) {

    .timberIndexContainer .timberIndexTable .view .wrapper table tbody tr td.stickyCol,
    .timberIndexContainer .timberIndexTable .view .wrapper table thead tr th.stickyCol {
        position: initial;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}
.navbarContainer {
    height: 70px;
    /* align-items: center; */
    padding-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    z-index: 20;
    background-color: white;
    position: relative;
}

.navbarContainer .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

.navbarContainer::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 65px;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: url(/static/media/navbar.60189873.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 0.3;
}

.stickyNavbar {
    position: fixed;
    top: 0;
    width: 100%;
}

.navbarContainer .hamburgerMenu {
    display: none;
}

.navbarContainer .navbarLogo {
    margin-left: 3em;
}

.navbarContainer .navbarLogo img {
    height: 60px;
    flex: 0 1;
    margin-top: 12px;
}

.navbarContainer .navbarOptions {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-right: 3em;

}

.navbarContainer .navbarOptions .navbarLinks {
    display: flex;
    align-items: center;
    color: var(--blackCharcol);
    margin-right: 1.5em;
    padding: 0 -1em;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems {
    /* margin: 0 1em; */
    line-height: 1.5em;
    width: 114px;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems a {
    text-decoration: none;
    padding: 7px 5px;
    color: var(--blackCharcol);
    position: relative;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems a:hover {
    background-color: #fff;
    border-radius: 8px;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems:hover.navbarLinkItems>a::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    left: 7px;
    bottom: 0;
    background-color: var(--bluePrimaryLight);
}

.navbarContainer .navbarOptions .navbarLinks .dropDownItem a::after {
    content: '';
    width: 0;
    transition: 0.5s;
}

.navbarContainer .navbarOptions .navbarLinks .dropDownItem:hover.dropDownItem>a::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 3px;
    left: 0px;
    top: 20px;
    background-color: var(--bluePrimaryLight);
}

.navbarContainer .navbarOptions .navbarLinks .dropDownItem a {
    transition: 0.5s;
}

.navbarContainer .navbarOptions .navbarLinks .dropDownItem:hover.dropDownItem>a {
    padding-left: 20px;
}




.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.selected,
.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.selected a {
    color: var(--bluePrimaryLight);
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems .dropDownBtn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownBtn .caretDownLogo {
    margin-left: 5px;
    transition: all 0.5s;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    margin-top: 3.5em;
    background-color: white;
    transition: all 0.5s;
    border-radius: 0.75em;
    padding: 0 0 0 1.5em;
    width: 8em;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems .dropDownBtn .rotateArrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .showDropDownList {
    max-height: 10em;
    padding: 0.5em 0 0.5em 1.5em;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList .dropDownItem {
    margin: 0.5em 0;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList .dropDownItem a {
    color: var(--blackCharcol);
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList .dropDownItem.selected a {
    color: var(--bluePrimaryLight);
}

.navbarContainer .navbarOptions .navbarGetStarted a {
    color: white;
    background-color: var(--bluePrimaryLight);
    padding: 0.7em 1.2em;
    border-radius: 0.75em;
    text-decoration: none;
    font-size: 0.875em;
    line-height: 1.5em;
    height: 2.7em;
    white-space: nowrap;
    transition: all 0.2s;
}


.scrollToTop {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    height: 50px;
    width: 50px;
    border-radius: 8px;
    /* border-color: black; */
    background-color: var(--whiteBlueTint);
    color: var(--bluePrimaryLight);
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 24px;
    left: 24px;
    transition: all 0.2s;
    z-index: 19;
}

.scrollToTop.showScrollToTop {
    display: flex;
    opacity: 0.8;
    box-shadow: 0 0 2px 0 var(--gray3);
}

.navbarLinkItems.arrow-nav{
    /* position: relative;
    width: 120px;
    height: 70px; */
/*     
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
overflow: hidden; */
} 
/* .circuler-menu{
    position: absolute;
    top: 0px;
   
    width: 100%;
    transition: 0.5s;
} */
.circuler-menu-item {
    width: 100%;
    /* transform: scale(0);
    transition:  0.5s; */
    display: none;
    white-space: nowrap;
}

.circuler-menu-item.active {
    /* transform: scale(1); */
display: block;
}
.up-arrow,.down-arrow{
    position: relative;
    z-index: 99;
}
.cursor {
    cursor: pointer;
}


@media (hover:hover) {
    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown:hover {
        cursor: pointer;
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown:hover .dropDownBtn .caretDownLogo {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown:hover .dropDownList {
        max-height: 10em;
        padding: 0.5em 0 0.5em 1.5em;
        box-shadow: 0 0 2px 0 rgb(197, 197, 197);
    }

    .navbarContainer .navbarOptions .navbarLinks a:hover,
    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList .dropDownItem a:hover {
        color: var(--bluePrimaryLight);
    }

    .navbarContainer .navbarOptions .navbarGetStarted a:hover {
        background-color: var(--blueSecondaryDark);
    }

    .scrollToTop:hover {
        opacity: 1;
        cursor: pointer;
        box-shadow: 0 0 2px 0 var(--gray3);
    }
}




@media screen and (max-width: 900px) {

    .navbarContainer {
        height: 5em;
        justify-content: space-between;
    }

    .navbarContainer .navbarLogo {
        display: block;
        margin-left: 10.6vw;
    }

    .navbarContainer .navbarLogo a {
        display: inline-block;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .navbarContainer .navbarLogo a img {
        vertical-align: middle;
        height: 50px;
        /* width: 144px; */
    }

    .navbarContainer .hamburgerMenu {
        display: inline-block;
        margin-right: 10.1vw;
    }

    .navbarContainer .hamburgerMenu img {
        display: block;
    }

    .navbarContainer .navbarOptions {
        flex-direction: column;
        position: absolute;
        width: 12.5em;
        height: 90vh;
        right: -12.5em;
        top: 5em;
        background-color: white;
        align-items: center;
        transition: all 0.5s;
        margin-right: 0;
    }

    .navbarContainer .showNavbar {
        right: 0;
    }

    .navbarContainer .navbarOptions .navbarLinks {
        flex-direction: column;
        margin-right: 0;
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems {
        margin: 1em 0;
    }

    .navbarContainer .navbarOptions .navbarGetStarted {
        margin-top: 5em;
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems .dropDownBtn {
        justify-content: center;
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList {
        position: inherit;
        margin-top: 0;
    }

    @media (hover:hover) {
        .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown:hover .dropDownList {
            margin-top: 1em;
        }
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .showDropDownList {
        margin-top: 1em;
    }

    .scrollToTop {
        opacity: 1;
    }

}


@media screen and (max-width: 600px) {
    .scrollToTop {
        height: 40px;
        width: 40px;
    }
}


@media screen and (max-width: 400px) {

    .navbarContainer .navbarLogo {
        margin-left: 28px;
    }

    .navbarContainer .hamburgerMenu {
        margin-right: 28px;
    }

    .navbarContainer .hamburgerMenu img {
        height: 24px;
        width: 24px;
    }

}

/* animating css */

@-webkit-keyframes moving {
    from {bottom: -15px;}
    to {bottom: 0px;}
}

@keyframes moving {
    from {bottom: -15px;}
    to {bottom: 0px;}
}

/* @keyframes previousMoving {
    from {top: 0px;}
    to {top: 15px;}
}
.navbarLinkItems.arrow-nav .circuler-menu-item {
    animation: previousMoving 1s;
    position: relative;
} */

.navbarLinkItems.arrow-nav .circuler-menu-item.active{
    -webkit-animation: moving 0.8s;
            animation: moving 0.8s;
    position: relative;
}


.navbarLinkItems.arrow-nav{
    
}
 html {
     box-sizing: border-box;
 }

 .footerContainer {
     background-color: var(--bluePrimaryLight);
     padding: 25px 50px;
     font-family: 'Poppins', sans-serif;
 }

 .footerContainer .footerPILWhiteLogo {
     display: flex;
     justify-content: left;
     /* margin-left: -20px; */
 }

 .footerContainer .footerPILWhiteLogo img {
     /* width: 220px; */
     height: 52px;
 }



 .footerContainer hr {
     height: 1px;
     background-color: white;
     border: none;
     opacity: 0.1;
     margin: 2.5em auto;
     mix-blend-mode: normal;
 }



 .footerContainer .footerOptions {
     display: flex;
     justify-content: space-between;
 }

 .footerContainer .footerOptions .footerNewsLetter {
     color: white;
 }

 .footerContainer .footerOptions .footerNewsLetter hr {
     display: none;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterText {
     letter-spacing: -0.3px;
     width: 11em;
     text-align: left;
     font-weight: 400;
     font-size: 1.375em;
     line-height: 1.5em;
     margin-bottom: 1em;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent {
     display: flex;
     flex-direction: row;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input,
 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent textarea {
     height: 3.125em;
     width: 15em;
     background-color: transparent;
     color: white;
     font-family: 'Poppins', sans-serif;
     font-size: 1em;
     border: none;
     border-bottom: 1px solid rgba(255, 255, 255, 10%);
     margin-right: 0.75em;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input::-webkit-input-placeholder, .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent textarea::-webkit-input-placeholder {
     color: white;
     opacity: 0.5;
     font-weight: 400;
     mix-blend-mode: normal;
     line-height: 1.4em;
     letter-spacing: -0.2px;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input::placeholder,
 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent textarea::placeholder {
     color: white;
     opacity: 0.5;
     font-weight: 400;
     mix-blend-mode: normal;
     line-height: 1.4em;
     letter-spacing: -0.2px;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input:focus,
 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent textarea:focus {
     outline: none;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button {
     height: 3.75em;
     width: 3.75em;
     border-radius: 8px;
     background-color: var(--lightBlueBg);
     border: none;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button img {
     height: 10px;
     width: 6px;
 }

 .footerContainer .footerOptions .footerPagesOptions,
 .footerContainer .footerOptions .footerImportantOptions {
     text-align: left;
     margin: 0 10px;
 }

 .footerContainer .footerOptions .footerOptionsHeading {
     color: white;
     font-weight: 600;
     font-size: 1.25em;
     line-height: 1.5em;
     letter-spacing: -0.23px;
     margin-bottom: 0.6em;
 }

 .footerContainer .footerOptions .footerPagesLinks,
 .footerContainer .footerOptions .footerImportantLinks {
     display: flex;
     flex-direction: column;
     margin: -0.5em 0;
 }

 .footerContainer .footerOptions .footerPagesLinks a,
 .footerContainer .footerOptions .footerImportantLinks a {
     text-decoration: none;
     color: var(--whiteBlueTint);
     font-size: 1em;
     line-height: 1.375em;
     font-weight: 400;
     letter-spacing: -0.2px;
     margin: 0.5em 0;
 }

 .footerContainer .footerOptions .footerBottomTablet {
     display: none;
 }

 .footerContainer .footerOptions .footerContactOptions {
     text-align: left;
 }

 .footerContainer .footerOptions .footerContactOptions .footerContactOptionsItem {
     display: flex;
     align-items: flex-start;
     margin: 0.875em 0;
 }

 .footerContainer .footerOptions .footerContactOptions .footerContactOptionsItem img {
     margin-right: 0.75em;
 }

 .footerContainer .footerOptions .footerContactOptions .contactData {
     font-size: 0.875em;
     font-weight: 500;
     line-height: 150%;
     color: var(--whiteBlueTint);
     text-align: left;
     width: 14.5em;
 }

 .footerContainer .footerOptions .footerContactOptions .contactData a {
     text-decoration: none;
     color: var(--whiteBlueTint);
 }


 .footerContainer .footerBottom {
     display: flex;
     justify-content: space-between;
 }

 .footerContainer .footerBottom .footerImportantLinks {
     display: flex;
     justify-content: center;
     margin: 0 -1.25em;
 }

 .footerContainer .footerBottom .footerImportantLinks a {
     text-decoration: none;
     font-size: 1em;
     line-height: 1.25em;
     letter-spacing: -0.2px;
     font-weight: 400;
     color: var(--whiteBlueTint);
     margin: 0 1.25em;
 }

 .footerContainer .footerBottom .footerSocialMedia {
     margin: 0 -1.25em;
 }

 .footerContainer .footerBottom .footerSocialMedia a {
     margin: 0 1.25em;
     text-decoration: none;
 }

 .footer-header {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .inquiry-form-area {
     width: 100%;
     min-height: 100%;
     padding: 0 20px;

 }

 .inquiry-form-area .inquiry-form {
     border-radius: 8px;
     border: 1px solid #fff;
     padding: 20px;
     background-color: #FFFFFF;
     color: var(--bluePrimaryLight);
 }

 .inquiry-form .inquiry-title {
     font-weight: 500;
     font-size: 20px;
     line-height: 33px;
     color: var(--bluePrimaryLight);

     margin-bottom: 20px;
     text-align: left;
 }

 .inquiry-form .form-control {
     text-align: left;
     margin-bottom: 20px;
     box-sizing: border-box;
 }

 .inquiry-form .submit-form {
     color: var(--bluePrimaryLight);
     margin-top: 20px;
     display: flex;
     align-items: center;
     justify-content: flex-end;
     cursor: pointer;
 }

 .inquiry-form .submit-form .icon {
     width: 35px;
     height: 35px;
     display: flex;
     align-items: center;
     justify-content: center;
     border: 1px solid var(--bluePrimaryLight);
     border-radius: 50%;
     margin-left: 15px;
 }

 .inquiry-form-area .inquiry-form input,
 .inquiry-form-area .inquiry-form textarea {
     background-color: #6077BF;
     border-radius: 5px;
     color: #fff;
     padding: 12px 25px;
     border: none;
     width: 100%;
     box-sizing: border-box;
 }

 .inquiry-form-area .inquiry-form textarea {
     resize: none;
     height: 100px;
     font-family: 'Poppins', sans-serif !important;

 }

 .inquiry-form-area .inquiry-form input::-webkit-input-placeholder, .inquiry-form-area .inquiry-form textarea::-webkit-input-placeholder {
     color: #fff;

 }

 .inquiry-form-area .inquiry-form input::placeholder,
 .inquiry-form-area .inquiry-form textarea::placeholder {
     color: #fff;

 }

 .inquiry-form-area .inquiry-form input:focus,
 .inquiry-form-area .inquiry-form textarea:focus {
     border: none;
     outline: none;
 }


 @media (hover:hover) {
     .footerContainer .footerOptions .footerPagesLinks a:hover {
         color: white;
     }

     .footerContainer .footerOptions .footerImportantLinks a:hover {
         color: white;
     }

     .footerContainer .footerBottom .footerImportantLinks a:hover {
         color: white;
     }

     .footerContainer .footerOptions .footerContactOptions .contactData a:hover {
         color: white;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button:hover {
         cursor: pointer;
     }
 }


 @media screen and (max-width: 1150px) {
     .footerContainer {
         padding: 44px 70px 48px 84px;
     }
 }

 @media screen and (max-width: 991px) {

     .inquiry-form-area {

         padding: 0px;

     }
 }

 @media screen and (max-width: 950px) {

     .inquiry-form-area {

         padding: 0px;

     }

     .footerContainer>hr {
         display: none;
     }

     .footerContainer .footerPILWhiteLogo {
         /* margin-left: -12px; */
     }

     .footerContainer .footerPILWhiteLogo img {
         /* margin-bottom: 49px; */
         /* height: 34px; */
         /* width: 145px; */
     }

     /* 
    .footerContainer .footerOptions{
        display: grid;
        grid-template-columns: repeat(4,25%);
    } */

     .footerContainer .footerOptions .footerNewsLetter hr {
         display: inherit;
         height: 1px;
         background-color: white;
         border: none;
         opacity: 0.1;
         margin: 0 0 40px 0;
         mix-blend-mode: normal;
     }

     .footerContainer .footerOptions .footerNewsLetter {
         grid-column: 1/3;
         margin-right: 16px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterText {
         font-size: 16px;
         line-height: 24px;
         margin-bottom: 24px;
     }

     .footerContainer .footerOptions .footerNewsLetter form {
         margin-bottom: 69px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input {
         flex: 1 1;
         margin-right: 10px;
         font-size: 12px;
         line-height: 18px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button {
         width: 40px;
         height: 40px;
         flex: 0 0 40px;
         margin-right: 15px;
     }

     .footerContainer .footerOptions .footerBottomTablet {
         display: flex;
         grid-column: 1/3;
         flex-direction: column-reverse;
         justify-content: flex-end;
         text-align: left;
     }

     .footerContainer .footerBottomTablet .footerSocialMedia {
         margin: 0 -20px 28px;
     }

     .footerContainer .footerBottomTablet .footerSocialMedia a {
         margin: 0 20px;
     }

     .footerContainer .footerBottomTablet .footerImportantLinks {
         display: flex;
         flex-direction: row;
     }

     .footerContainer .footerBottomTablet .footerImportantLinks a {
         flex: 1 1;
         margin: 0 8px;
         font-size: 12px;
         line-height: 16px;
     }

     .footerContainer .footerOptions .footerOptionsHeading {
         font-size: 16px;
         line-height: 24px;
         margin-bottom: 24px;
     }

     .footerContainer .footerOptions .footerPagesLinks,
     .footerContainer .footerOptions .footerImportantLinks {
         margin: -12px 0;
     }

     .footerContainer .footerOptions .footerPagesLinks a,
     .footerContainer .footerOptions .footerImportantLinks a {
         font-size: 14px;
         line-height: 21px;
         margin: 12px 0 0 !important;
         letter-spacing: -0.204545px;
     }

     .footerContainer .footerOptions .footerContactOptions {
         grid-column: 3/5;
     }

     .footerContainer .footerOptions .footerContactOptions .footerContactOptionsItem {
         margin: 22px 0;
     }

     /* 
    .footerContainer .footerBottom{
        display: none;
    } */
 }

 .footerContainer .footerOptions .footerPagesOptions,
 .footerContainer .footerContactOptions,
 .footerContainer .footerImportantOptions {
     margin-bottom: 50px !important;
 }

 @media screen and (max-width: 600px) {
    .footer-header{
        flex-direction: column;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
     }
     .footerContainer {
         padding: 40px 28px 64px;
     }

     .footerContainer .footerPILWhiteLogo img {
         /* width: 145px; */
     }

     .footerContainer hr {
         margin: 35px 0 25px;
     }



     .footerContainer .footerOptions .footerNewsLetter {
         grid-column: 1/3;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterText {
         margin-bottom: 16px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent {
         padding-right: 15px;
     }

     .footerContainer .footerOptions .footerNewsLetter form {
         margin-bottom: 36px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button {
         height: 40px;
         width: 16px;
     }



     .footerContainer .footerOptions .footerBottomTablet {
         display: none;
     }

     .footerContainer .footerOptions .footerContactOptions {
         margin: 0;
         grid-column: 1/3;
     }

     .footerContainer .footerOptions .footerContactOptions .footerContactOptionsItem {
         margin: 24px 0;
     }

     .footerContainer .footerOptions .footerContactOptions img {
         flex: 0 0 24px;
         margin-right: 12px;
     }

     .footerContainer .footerBottom {
         display: flex;
         justify-content: flex-start;
         flex-direction: column-reverse;

     }

     /* 
    .footerContainer .footerBottom .footerSocialMedia{
        margin-bottom: 40px;
    } */

     .footerContainer .footerBottom .footerSocialMedia a {
         margin: 0 18px;
     }

     .footerContainer .footerBottom .footerImportantLinks a {
         margin: 0 12px;
         font-size: 12px;
         line-height: 16px;
     }

 }



 .office-address {
     margin: 20px 0;
 }

 .office-address hr {
     margin: 20px 0;
 }

 .office-address .footerContactOptions {
     margin: 0 !important;
 }

 .office-address-title {
     color: #fff;
     text-align: left;
 }

 .footer-support {
     flex-direction: row !important;
 }

 .footer-support a {
     width: 50%;
 }

 .footer-support a:nth-child(2) {
     margin: 8px 10px !important;
 }

 .office-address-area {

     align-items: flex-start;
     display: flex;
 }

 .office-address-area .footerOptions {
     width: 100%;
     grid-template-columns: 100%;
 }

 .office-address-area .address-arrow {
     background: #6077BF;
     border-radius: 8px;
     padding: 10px 12px;
     margin: 15px;
     display: flex;
     align-items: center;
 }
.allBlogsContainer{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4em auto 0;
    grid-column-gap: 1em;
    grid-row-gap: 2.5em;
}

.allBlogsLoadMore{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    color: var(--whiteBlueTint);
    background-color: var(--bluePrimaryLight);
    padding: 16px 32px;
    border-radius: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 40px auto 0;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.2s;
}

.noBlogFound img{
    width: 464px;
    margin: 94px auto 40px;
}

.noBlogFound .noBlogFoundMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.noBlogFound .bacKToHomeBtn{
    padding: 16px 32px;
    white-space: nowrap;
    border-radius: 16px;
    background-color: var(--bluePrimaryLight);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5em;
    color: var(--whiteBlueTint);
    transition: all 0.2s;
}



@media (hover:hover){
    .allBlogsLoadMore:hover{
        background-color: var(--blueSecondaryDark);
    }

    .noBlogFound .bacKToHomeBtn:hover{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }
}



@media screen and (max-width: 1000px){
    .allBlogsContainer{
        grid-template-columns: 1fr 1fr;
        margin-top: 48px;
        grid-row-gap: 1em;
    }

    .allBlogsLoadMore{
        margin-top: 40px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
        padding: 8px 17px;
    }
}

@media screen and (max-width: 900px){
    .noBlogFound img{
        margin-top: 48px;
    }

    .noBlogFound .noBlogFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
    }

    .noBlogFound .bacKToHomeBtn{
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 12px;
    }
}

@media screen and (max-width: 650px){
    .allBlogsContainer{
        grid-template-columns: 1fr;
        margin-top: 40px;
        grid-row-gap: 24px;
    }

    .noBlogFound img{
        width: 232px;
    }
}

@media screen and (max-width: 400px){
    .noBlogFound img{
        margin-top: 40px;
        width: 80%;
    }
    .noBlogFound .noBlogFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
        padding: 0 10px;
    }
}
.blogNavbarContainer{
    display: flex;
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    background-color: var(--lightBlueBg);
    margin-top: 140px;
    padding: 16px 0;
}

.blogNavbarContainer .navbarWrapper{
    width: 944px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}

.blogNavbarContainer .searchBar {
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 25%;
}

.blogNavbarContainer .searchBar input{
    border: none;
    outline: none;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    background-color: var(--lightBlueBg);
    margin-left: 1em;
    width: 90%;
}

.blogNavbarContainer .searchBar input::-webkit-input-placeholder{
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.blogNavbarContainer .searchBar input::placeholder{
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}


.blogNavbarContainer .rightOps{
    display: flex;
    margin: 0 -10px;
}


.blogNavbarContainer .rightOps .dropDown{
    position: relative;
    display: block;
}


.blogNavbarContainer .rightOps .dropDown .dropDownBtn{
    font-weight: 500;
    font-size: 12px;
    line-height: 175%;
    color: var(--lightBlueBg);
    margin: 0 10px;
    border-radius: 8px;
    width: inherit;
    justify-content: space-between;
    display: flex;
    align-items: center;
}


.blogNavbarContainer .rightOps .dropDown .dropDownOptions{
    position: absolute;
    font-weight: 600;
    font-size: 12px;
    line-height: 175%;
    margin-top: 8px;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: white;
    transition: all 0.1s;
    border-radius: 8px;
    padding: 0 8px;
    right: 0;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.blogNavbarContainer .rightOps .dropDown .dropDownOptions .dropDownOptionItem{
    padding: 5px 10px;
    border-radius: 8px;
}

.blogNavbarContainer .rightOps .dropDown .dropDownOptions.openDropDown{
    padding: 8px 8px;
    max-height: 20em;
}

.blogNavbarContainer .rightOps .dropDown .dropDownOptions .selectedOption{
    color: var(--bluePrimaryLight);
    background-color: var(--lightBlueBg);
}




@media (hover:hover){

    .blogNavbarContainer .rightOps .dropDown:hover{
        cursor: pointer;
    }
    
    .blogNavbarContainer .rightOps .dropDown .dropDownOptions .dropDownOptionItem:hover{
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
    }

}

.tabletSearchBlogWrapper{
    display: none;
}


@media screen and (max-width: 1000px){

    .blogNavbarContainer .navbarWrapper{
        width: 624px;
    }

    .blogNavbarContainer .searchBar {
        border: none;
        padding: 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .blogNavbarContainer .searchBar input{
        display: none;
    }

    .tabletSearchBlogWrapper{
        display: block;
        margin: 0 auto;
        width: calc(624px - 20px);;
    }

    input.tabletBlogSearch{
        border: none;
        outline: none;
        display: block;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
        width: calc(100% - 18px);
        border-radius: 8px;
        padding: 0 10px;
        max-height: 0;
        transition: all 0.2s;
    }
    
    input.tabletBlogSearch::-webkit-input-placeholder{
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }
    
    input.tabletBlogSearch::placeholder{
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    input.tabletBlogSearch.showTabletBlogSearchbar{
        max-height: 5em;
        padding: 6px 10px;
        margin-top: 10px;
    }

}


@media screen and (max-width: 900px){
    .blogNavbarContainer{
        margin-top: 5em;
    }
}


@media screen and (max-width: 650px){
    .blogNavbarContainer{
        padding: 16px 0;
    }

    .blogNavbarContainer .navbarWrapper{
        width: 304px;
    }

    .tabletSearchBlogWrapper{
        width: calc(304px - 20px);
    }
}

@media screen and (max-width: 650px){
    .blogNavbarContainer{
        padding: 16px 28px;
    }

    .blogNavbarContainer .navbarWrapper{
        width: 304px;
    }

    .tabletSearchBlogWrapper{
        width: inherit;
        margin: 0 28px;
    }
}

.newsLetterContainer{
    font-family: 'Poppins', sans-serif;
    background-color: var(--bluePrimaryLight);
    background-image: url(/static/media/getStartedBg.aadd7243.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin: 6em 0;
    padding: 4.375em 1em 45px;
}

.newsLetterContainer .newsLetterTitle{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--whiteBlueTint);
    text-transform: capitalize;
}

.newsLetterContainer .newsLetterSubTitle{
    font-weight: 500;
    font-size: 1.125;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--lightBlueBg);
}

.newsLetterContainer .newsLetterForm{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 2.5em auto 0;
}

.newsLetterContainer .newsLetterForm input{
    font-size: 0.75em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    margin-right: 1.3em;
    padding: 1em 1.3em;
    border-radius: 1em;
    font-weight: 500;
    border: none;
    color: var(--blackCharcol);
    background-color: var(--whiteBlueTint);
    width: 19em;
    font-family: 'Poppins', sans-serif;
}

.newsLetterContainer .newsLetterForm input::-webkit-input-placeholder{
    color: var(--gray4);
}

.newsLetterContainer .newsLetterForm input::placeholder{
    color: var(--gray4);
}

.newsLetterContainer .newsLetterForm input:focus{
    outline: none;
}

.newsLetterContainer .newsLetterForm .newsLetterBtn button{
    border: none;
    color: var(--blackCharcol);
    font-size: 0.875em;
    line-height: 1.5em;
    font-weight: 500;
    background-color: var(--whiteBlueTint);
    border-radius: 0.75em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 11px 19px;
    font-family: 'Poppins', sans-serif;
    transition: all 0.2s;
}




@media (hover:hover){
    .newsLetterContainer .newsLetterForm .newsLetterBtn button:hover{
        background-color: white;
        cursor: pointer;
    }
}





@media screen and (max-width: 900px){
    .newsLetterContainer{
        margin: 48px 0;
        padding: 48px 28px 23px;
    }

    .newsLetterContainer .newsLetterForm input{
        padding: 12px;
        font-size: 10px;
        line-height: 15px;
        border-radius: 8px;
        margin-right: 11px;
    }

    .newsLetterContainer .newsLetterForm .newsLetterBtn button{
        padding: 10px 14px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
    }
}

@media screen and (max-width: 400px){
    .newsLetterContainer {
        margin: 40px 0;
        padding: 40px 28px 15px;
    }

    .newsLetterContainer .newsLetterTitle{
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
    }

    .newsLetterContainer .newsLetterSubTitle{
        font-size: 18px;
        line-height: 27px;
    }

    .newsLetterContainer .newsLetterForm input{
        flex: 0 1;
        width: auto;
    }
}



.recomBlogContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin: 0 0 6em;
}

.recomBlogContainer .recomBlogHeadline{
    font-size: 2.25em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.recomBlogContainer .recomBlogs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2em auto 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-column-gap: 1em;
}



@media screen and (max-width: 1000px){
    .recomBlogContainer{
        margin-bottom: 48px;
    }
    
    .recomBlogContainer .recomBlogs{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }

    .recomBlogContainer .recomBlogHeadline{
        font-size: 24px;
        line-height: 140%;
    }
}


@media screen and (max-width: 650px){
    .recomBlogContainer{
        padding: 0 28px;
        margin: 40px 0;
    }

    .recomBlogContainer .recomBlogs{
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
    }

}
.showBlogContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.showBlogContainer .goBackbtn{
    background-color: var(--lightBlueBg);
    margin-bottom: 64px;
    width: inherit;
    padding: 14px 0 14px 16%;
    display: flex;
}

.showBlogContainer .goBackbtn img{
    display: block;
}

.showBlogContainer .showBlogTitle{
    font-size: 2.25em;
    font-weight: 700;
    line-height: 130%;
    width: 45%;
    margin: 0 auto;
    text-align: left;
}

.showBlogContainer .showBlogMain img{
    margin: 4em auto;
    border-radius: 1.25em;
    width: 68%;
}

.showBlogContainer .showBlogContent{
    text-align: left;
    width: 45%;
    margin: 0 auto;
}

.showBlogContainer .showBlogContent p{
    font-size: 1em;
    line-height: 160%;
    font-weight: 500;
    margin: 32px 0;
}

.showBlogContainer .showBlogContent h2{
    font-size: 1.5em;
    line-height: 130%;
    font-weight: 700;
    margin: 40px 0;
}

.showBlogContainer .showBlogContent img{ 
    margin: 64px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* max-width: 100%; */
    width: 100%;
    height: auto;
    display: block;
}

.showBlogContainer .showBlogContent iframe{
    /* border: none;
    margin: 0 auto; */
    text-align: center;
    width: inherit;
}

.showBlogContainer .showBlogContent blockquote{
    background-color: var(--lightBlueBg);
    padding: 5px 10px;
    border-left: 8px solid var(--gray3);
}

@media (hover:hover){
    .showBlogContainer .goBackbtn img:hover{
        cursor: pointer;
    }
}


@media screen and (max-width: 900px){
    .showBlogContainer{
        margin: 0;
        margin-top: 5em;
    }

    .showBlogContainer .goBackbtn{
        padding-left: 85px;
        margin-bottom: 0;
    }

    .showBlogContainer .showBlogMain{
        padding: 48px 84px 0;
    }

    .showBlogContainer .showBlogMain img{
        margin: 48px 0;
        width: 100%;
    }

    .showBlogContainer .showBlogTitle,
    .showBlogContainer .showBlogContent{
        width: 100%;
    }

    .showBlogContainer .showBlogContent img{
        margin: 64px 0;
    }
}

@media screen and (max-width: 500px){

    .showBlogContainer{
        margin: 0;
        margin-top: 5em;
    }

    .showBlogContainer .goBackbtn{
        padding: 16px 0 16px 40px;
    }

    .showBlogContainer .goBackbtn img{
        height: 24px;
        width: 24px;
    }

    .showBlogContainer .showBlogMain{
        padding: 28px 40px 0;
    }

    .showBlogContainer .showBlogTitle{
        font-size: 24px;
        line-height: 130%;
    }

    .showBlogContainer .showBlogMain img{
        margin: 24px 0;
        border-radius: 8px;
    }

    .showBlogContainer .showBlogContent p{
        margin: 16px 0;
    }

    .showBlogContainer .showBlogContent h2{
        margin: 36px 0 24px;
    }

    .showBlogContainer .showBlogContent img{
        margin: 36px 0;
        border-radius: 8px;
    }

}
.socialMediaContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 64px auto 0;
}

.socialMediaContainer .shareHeading{
    font-size: 16px;
    line-height: 130%;
    font-weight: 700;
    margin-bottom: 12px;
}

.socialMediaContainer .socialMediaIcons{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.socialMediaContainer .socialMediaIcons .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 4px;
    background-color: var(--whiteBlueTint);
    border-radius: 4px;
}

.socialMediaContainer .socialMediaIcons .icon img{
    display: block;
}




@media screen and (max-width: 900px){
    .socialMediaContainer{
        margin-top: 48px;
    }
}


@media screen and (max-width: 400px){
    .socialMediaContainer{
        margin-top: 40px;
    }
    
    .socialMediaContainer .socialMediaIcons .icon{
        border-radius: 4px;
    }
}
.allCareerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.allCareerContainer .allCareerHead{
    font-size: 3em;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    white-space: nowrap;
}

.allCareerContainer .allCareerSubHead{
    color: var(--gray3);
    font-size: 1.5em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    font-weight: 500;
    margin-top: 8px;
}

.allCareerContainer .allCareers{
    width: 41%;
    margin: 4em auto 6em;
}

.allCareerContainer .allCareers .careerCard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E5E7EE;
    padding: 1.44em 1.5em 1.44em 2em;
    border-radius: 0.75em;
    margin: 1.5em 0;
}

.allCareerContainer .allCareers .careerCard .careerCardTitle{
    color: black;
    font-size: 1.125em;
    line-height: 140%;
    font-weight: 600;
    text-align: left;
}

.allCareerContainer .allCareers .careerCard .careerCardMoreBtn{
    color: var(--whiteBlueTint);
    font-size: 0.875em;
    line-height: 1.5em;
    font-weight: 500;
    background-color: var(--bluePrimaryLight);
    padding: 11px 19px;
    border-radius: 12px;
    white-space: nowrap;
}

.allCareerContainer .allCareers .careerCard a{
    text-decoration: none;
    color: var(--whiteBlueTint);;
}

.noJobFound img{
    width: 464px;
    margin: 32px auto 40px;
}

.noJobFound .noJobFoundMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.noJobFound .bacKToHomeBtn{
    padding: 16px 32px;
    white-space: nowrap;
    border-radius: 16px;
    background-color: var(--bluePrimaryLight);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5em;
    color: var(--whiteBlueTint);
    transition: all 0.2s;
}


@media (hover:hover){
    .noJobFound .bacKToHomeBtn:hover{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }
}


@media screen and (max-width: 900px){
    .allCareerContainer {
        padding: 48px 84px 0;
        margin-bottom: 48px;
        margin-top: 5em;
    }

    .allCareerContainer .allCareers{
        margin-bottom: 0;
        width: inherit;
    }

    .noJobFound img{
        margin-top: 0;
    }
    
    .noJobFound .noJobFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
    }

    .noJobFound .bacKToHomeBtn{
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 12px;
    }
}

@media screen and (max-width: 500px){
    .allCareerContainer{
        padding: 40px 28px 0;
        margin-bottom: 40px;
    }

    .allCareerContainer .allCareerHead{
        font-size: 24px;
        line-height: 36px;
    }

    .allCareerContainer .allCareerSubHead{
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    .allCareerContainer .allCareers{
        margin-top: 53px;
    }

    .allCareerContainer .allCareers .careerCard{
        padding: 16px;
        margin: 16px 0;
    }

    .allCareerContainer .allCareers .careerCard .careerCardTitle{
        font-size: 12px;
        line-height: 140%;
    }

    .allCareerContainer .allCareers .careerCard .careerCardMoreBtn{
        padding: 8px 14px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
    }

    .noJobFound img{
        width: 100%;
    }
}
.showCareerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.showCareerContainer .showCareerHeader{
    background-color: var(--bluePrimaryLight);
    padding: 57px 0;
    color: var(--whiteBlueTint);
}

.showCareerContainer .showCareerHeader .showCareerSubHeader{
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}


.showCareerContainer .showCareerHeader .showCareerHeaderDetails{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin: auto;
    left: -20px;
}

.showCareerContainer .showCareerHeader .showCareerHeaderDetails .showCareerHeaderTitle{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    white-space: nowrap;
}

.showCareerContainer .showCareerHeader .showCareerHeaderDetails .showCareerHeaderLocation{
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.02em;
}

.showCareerContainer .showCareerHeader .showCareerHeaderDetails  .showCareerHeaderDate{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    margin-top: 12px;
}


.showCareerContainer .showCareerDescription{
    text-align: left;
    width: 41%;
    margin: 2.5em auto 0;
}

.showCareerContainer .showCareerDescription .showCareerDecs{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
}

.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecSubHead{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.02em;
    margin: 24px 0 12px;
    white-space: nowrap;
}

.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent{
    color: var(--gray3);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent > *{
    margin-top: 12px;
}

.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent ul,
.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent ol{
    padding-left: 30px;
}

.showCareerContainer a {
    text-decoration: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--whiteBlueTint);
    white-space: nowrap;
    text-decoration: none;
}

.showCareerContainer .submitCVBtn{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4em auto 6em;
    background-color: var(--bluePrimaryLight);
    border-radius: 16px;
    padding: 16px 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.2s;
}




@media (hover:hover){
    .showCareerContainer .submitCVBtn:hover{
        background-color: var(--blueSecondaryDark);
    }
}



@media screen and (max-width: 900px){
    .showCareerContainer{
        margin-top: 5em;
    }

    .showCareerContainer .showCareerHeader{
        padding: 57px 84px;
    }

    .showCareerContainer .showCareerHeader .showCareerSubHeader{
        width: inherit;
    }

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails{
        width: inherit;
    }

    .showCareerContainer .showCareerDescription{
        width: inherit;
        margin: 48px 84px;
    }

    .showCareerContainer .submitCVBtn{
        margin: 0 auto 48px;
        font-size: 12px;
        line-height: 18px;
        padding: 8px 16.5px;
        border-radius: 8px;
    }
}

@media screen and (max-width: 550px){

    .showCareerContainer .showCareerHeader{
        padding: 40px 28px 76px;
    }
    
    .showCareerContainer .showCareerHeader .showCareerSubHeader{
        flex-direction: column;
    }

    .showCareerContainer .showCareerHeader .showCareerSubHeader img{
        display: block;
        height: 24px;
        width: 24px;
        margin-bottom: 12px;
    }

    .showCareerContainer .showCareerHeader .showCareerSubHeader a{
        justify-self: left;
        align-self: stretch;
    }

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails{
        position: initial;
    }

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails .showCareerHeaderTitle{
        font-size: 16px;
        line-height: 24px;
    } 

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails .showCareerHeaderLocation{
        font-size: 12px;
        line-height: 18px;
        margin: 12px 0 4px;
    }

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails  .showCareerHeaderDate{
        font-size: 9px;
        line-height: 13px;
    }

    .showCareerContainer .showCareerDescription{
        margin: 40px 28px;
    }

    .showCareerContainer .showCareerDescription .showCareerDecs{
        font-size: 16px;
        line-height: 24px;
    }

    .showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecSubHead{
        font-size: 12px;
        line-height: 18px;
    }

    .showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent{
        font-size: 12px;
        line-height: 18px;
    }

    .showCareerContainer .submitCVBtn{
        margin: 0 auto 40px;
        border-radius: 6px;
    }
}

@media screen and (max-width: 400px){
    .showCareerContainer .submitCVBtn{
        margin: 0 28px 40px;
        width: inherit;
    }
}
.aboutSectionContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
}

.aboutSectionContainer .aboutSectionItem{
    display: flex;
    margin: 96px 0;
    justify-content: center;
    align-items: center;
}

.aboutSectionContainer .rowAboutItemdata{
    flex-direction: row;
}

.aboutSectionContainer .rowReverseAboutItemdata{
    flex-direction: row-reverse;
}

.aboutSectionContainer .aboutSectionItem img{
    height: 28.75em;
    width: 28.75em;
    margin: 0;
    border-radius: 20px;
    display: block;
}

.aboutSectionContainer .aboutSectionItem .imageContainer{
    position: relative;
    margin: 0 20px;
}

.aboutSectionContainer .aboutSectionItem .imageContainer::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(65, 90, 169, 0.25);
    border-radius: 20px;
}

.aboutSectionContainer .aboutSectionItem:nth-of-type(2) .imageContainer::after{
    background-color: rgba(65, 90, 169, 0.50);
}


.aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
    width: 443px;
    margin: 0 20px;
    text-align: left;
}

.aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail .aboutSectionItemHead{
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
}

.aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail .aboutSectionItemContent{
    font-weight: 500;
    font-size: 16px;
    line-height: 175%;
}




@media screen and (max-width: 900px){

    .aboutSectionContainer{
        margin: 0;
        padding: 12px 84px;
    }

    .aboutSectionContainer .aboutSectionItem{
        margin: 36px -8px;
        width: inherit;
    }

    .aboutSectionContainer .aboutSectionItem .imageContainer{
        margin: 0 8px;
        border-radius: 8px;
    }

    .aboutSectionContainer .aboutSectionItem .imageContainer::after{
        border-radius: 8px;
    }

    .aboutSectionContainer .aboutSectionItem img{
        height: 304px;
        width: 304px;
        border-radius: 8px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
        margin: 0 8px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail .aboutSectionItemHead{
        font-size: 24px;
        line-height: 140%;
        width: 100%;
        margin-bottom: 12px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail .aboutSectionItemContent{
        font-size: 12px;
        line-height: 175%;
    }
}

@media screen and (max-width: 700px){
    .aboutSectionContainer{
        padding: 0 28px;
    }

    .aboutSectionContainer .aboutSectionItem{
        flex-direction: column;
        margin: 40px 20px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
        margin: 0;
    }

    .aboutSectionContainer .aboutSectionItem img{
        width: 100%;
        height: 100%;
    }

    .aboutSectionContainer .aboutSectionItem .imageContainer{
        margin-bottom: 24px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 450px){
    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
        width: 100%;
    }
}

@media screen and (max-width: 300px){
    .aboutSectionContainer .aboutSectionItem img{
        width: 100%;
        height: 100%;
    }
}
.landingAboutCompContainer{
    font-family: 'Poppins', sans-serif;
    margin-top: 7em;
    color: var(--whiteBlueTint);
    padding: 146px 0 143px;
    background: linear-gradient( rgba(40, 40, 40, 0.75) 100%, rgba(40, 40, 40, 0.75)100%), url(/static/media/aboutLandingBg.9d5e6a68.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-position: center;
}

.landingAboutCompContainer .landingAboutCompHead{   
    font-size: 48px;
    line-height: 130.5%;
    text-align: center;
    font-weight: 700;
    letter-spacing: -0.02em;
    width: 53.3%;
    margin: 0 auto 24px;
}

.landingAboutCompContainer .landingAboutCompSubHead{
    font-size: 24px;
    line-height: 140.5%;
    text-align: center;
    letter-spacing: -0.02em;
    width: 44%;
    margin: 0 auto;
}





@media screen and (max-width: 900px){

    .landingAboutCompContainer{
        padding: 155px 164px;
        margin-top: 5em;
        background-size: auto 100%;
    }

    .landingAboutCompContainer .landingAboutCompHead{
        font-size: 36px;
        line-height: 130.5%;
        margin: 0 0 24px;
        width: 100%;
    }

    .landingAboutCompContainer .landingAboutCompSubHead{
        width: 100%;
        font-size: 16px;
        line-height: 140.5%;
    }
}

@media screen and (max-width: 600px){
    .landingAboutCompContainer{
        padding: 157px 28px;
    }
}


@media screen and (max-width: 400px){
    .landingAboutCompContainer .landingAboutCompHead{
        font-size: 24px;
        line-height: 130.5%;
    }
}
.lightBlueBgAboutBannerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--bluePrimaryLight);
    background-color: var(--lightBlueBg);
    padding: 64px 0;
    margin: 96px 0;
}

.lightBlueBgAboutBannerContainer .lightBlueBgAboutBannerHead{
    width: 41%;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0 auto;
}



@media screen and (max-width: 900px){
    .lightBlueBgAboutBannerContainer{
        margin: 48px 0;
    }
}

@media screen and (max-width: 800px){
    .lightBlueBgAboutBannerContainer{
        padding: 48px 84px;
        margin-bottom: 48px;
    }
    
    .lightBlueBgAboutBannerContainer .lightBlueBgAboutBannerHead{
        width: 100%;
        font-size: 16px;
        line-height: 140%;
    }
}


@media screen and (max-width: 450px){
    .lightBlueBgAboutBannerContainer{
        padding: 40px 28px;
        margin: 40px 0;
    }
    .lightBlueBgAboutBannerContainer .lightBlueBgAboutBannerHead{
        font-size: 10px;
        line-height: 140%;
    }
}
.visMisMain{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    display: grid;
    grid-template-columns: repeat(2,463px);
    grid-gap: 1em;
    margin: 0 auto;
    justify-content: center;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.visMisMain .visMisItem{
    background-color: var(--lightBlueBg);
    padding: 2.5em 1.5em;
    border-radius: 1.25em;
}

.visMisMain .visMisItem img {
    margin-bottom: 1em;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
}

.visMisMain .visMisItem .visMisTitle{
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: var(--bluePrimaryLight);
}

.visMisMain .visMisItem .visMisContent{
    font-weight: 500;
    font-size: 1em;
    line-height: 150%;
    text-transform: capitalize;
    margin-top: 1em;
}


@media screen and (max-width: 1000px){

    .visMisMain{
        grid-template-columns: 1fr 1fr;
        margin: 0 20px;
    }

    .visMisMain .visMisItem{
        padding: 16px;
        border-radius: 12px;
    }

    .visMisMain .visMisItem img{
        margin-bottom: 0;
    }

    .visMisMain .visMisItem .visMisTitle{
        margin: 16px 0 12px;
        font-size: 14px;
        line-height: 150%;
    }

    .visMisMain .visMisItem .visMisContent{
        font-size: 12px;
        line-height: 150%;
    }
}

@media screen and (max-width: 900px){
    .visMisMain{
        margin: 0 84px 48px;
    }
}

@media screen and (max-width: 900px){
    .visMisMain{
        margin: 0 84px 48px;
    }
}

@media screen and (max-width: 700px){
    .visMisMain{
        margin: 0 48px 48px;
    }
}

@media screen and (max-width: 500px){
    .visMisMain{
        margin-top: 0;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
    }
}
.lightBlueBgBannerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    background-color: var(--lightBlueBg);
    padding: 4em 18vw;
    margin-top: 7em;
    display: flex;
    align-items: center;
}

.lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
    font-size: 3em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    font-weight: 700;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    flex: 1 1;
    position: relative;
    left: -20px;
}



@media (hover:hover){
    .lightBlueBgBannerContainer .ppGoBackBtn:hover{
        cursor: pointer;
    }
}



@media screen and (max-width: 900px){
    .lightBlueBgBannerContainer{
        margin-top: 5em;
        padding: 3em 10vw;
    }

    .lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
        font-size: 36px;
    }
}

@media screen and (max-width:  550px){
    .lightBlueBgBannerContainer{
        padding: 40px 10vw;
        flex-direction: column;
    }

    .lightBlueBgBannerContainer .ppGoBackBtn{
        align-self: flex-start;
    }

    .lightBlueBgBannerContainer img {
        display: block;
        height: 24px;
        width: 24px;
    }

    .lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
        font-size: 24px;
        line-height: 54px;
        left: 0;
    }
}


.ppContentMain{
    margin: 40px 29.5vw 64px;
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    text-align: left;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.ppContentMain strong{
    color: var(--blackCharcol);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    margin: 40px 0 24px;
}

@media screen and (max-width:  1000px){
    .ppContentMain{
        margin: 24px 15vw 40px;
        font-size: 14px;
    }

    .ppContentMain strong{
        font-size: 18px;
    }
}

@media screen and (max-width:  450px){
    .ppContentMain{
        margin: 24px 8vw 40px;
    }
}
.ppContentMain{
    margin: 40px 29.5vw 64px;
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    text-align: left;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.ppContentMain strong{
    color: var(--blackCharcol);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    margin: 40px 0 24px;
}

@media screen and (max-width:  1000px){
    .ppContentMain{
        margin: 24px 15vw 40px;
        font-size: 14px;
    }

    .ppContentMain strong{
        font-size: 18px;
    }
}

@media screen and (max-width:  450px){
    .ppContentMain{
        margin: 24px 8vw 40px;
    }
}
.tNcContentMain{
    margin: 40px 29.5vw 64px;
    font-family: 'Poppins', sans-serif;
    color: var(--gray3);
    text-align: left;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.tNcContentMain strong{
    color: var(--blackCharcol);
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
}




@media screen and (max-width:  1000px){
    .tNcContentMain{
        margin: 24px 15vw 40px;
        font-size: 14px;
    }

    .tNcContentMain strong{
        font-size: 18px;
    }
}


@media screen and (max-width:  450px){
    .tNcContentMain{
        margin: 24px 8vw 40px;
    }
}
.notFoundCard{
    margin-top: 7em;
    color: var(--blackCharcol);
    font-family: 'Poppins', sans-serif;
    padding: 96px 0;
}

.notFoundCard .errCode{
    font-size: 48px;
    line-height: 120.5%;
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.notFoundCard .errImg img{
    display: inline-block;
    width: 362px;
    margin: 40px 0;
}

.notFoundCard .errMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.notFoundCard .goHomeBtn{
    padding: 16px 32px;
    white-space: nowrap;
    border-radius: 16px;
    background-color: var(--bluePrimaryLight);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5em;
    color: var(--whiteBlueTint);
    transition: all 0.2s;
}


@media (hover:hover){
    .notFoundCard .goHomeBtn:hover{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }
}


@media screen and (max-width:  900px){
    .notFoundCard{
        margin-top: 5em;
        padding: 48px 0;
    }

    .notFoundCard .errMsg{
        margin-bottom: 48px;
        font-size: 18px;
        margin-bottom: 32px;
    }

    .notFoundCard .goHomeBtn{
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 12px;
    }
}

@media screen and (max-width:  600px){

    .notFoundCard .errImg img{
        width: 250px;
        margin: 20px auto;
    }

    .notFoundCard .errCode{
        font-size: 36px;
    }

    .notFoundCard .errMsg{
        font-size: 14px;
        padding: 0 10px;
    }
}

@media screen and (max-width:  350px){
    .notFoundCard .errImg img{
        width: 70%;
    }
}
.portDescContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.portDescContainer .portDescHeader{
    background-color: var(--bluePrimaryLight);
    padding: 57px 0;
    color: var(--whiteBlueTint);
}

.portDescContainer .portDescHeader .portDescSubHeader{
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}


.portDescContainer .portDescHeader .portDescHeaderDetails{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin: auto;
    left: -20px;
}

.portDescContainer .portDescHeader .portDescSubHeader img {
    display: block;
}

.portDescContainer .portDescHeader .portDescHeaderDetails .portDescHeaderTitle{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    white-space: nowrap;
}

/* .portDescContainer .goBackbtn{
    background-color: var(--lightBlueBg);
    margin-bottom: 64px;
    width: inherit;
    padding: 14px 0 14px 16%;
    display: flex;
}

.portDescContainer .goBackbtn img{
    display: block;
} */

.portDescContainer .portDescMain{
    margin-bottom: 64px;
}

.portDescContainer .portDescContent{
    text-align: left;
    width: 45%;
    margin: 0 auto;
}

.portDescContainer .portDescContent p{
    font-size: 1em;
    line-height: 160%;
    font-weight: 500;
    margin: 32px 0;
}

.portDescContainer .portDescContent h1{
    font-size: 1.5em;
    line-height: 130%;
    font-weight: 700;
    margin: 40px 0;
}

.portDescContainer .portDescContent img{ 
    margin: 0 auto;
    display: block;
    height: auto;
    width: 100%;
    border-radius: 1.25em;
}

.portDescContainer .portDescContent ul{
    list-style-position: inside;
}

.portDescContainer .portDescContent iframe{
    /* border: none;
    margin: 0 auto; */
    text-align: center;
    width: inherit;
}

.portDescContainer .portDescContent blockquote{
    background-color: var(--lightBlueBg);
    padding: 5px 10px;
    border-left: 8px solid var(--gray3);
}

.portDescContainer .portDescContent table{
    background-color: var(--lightBlueBg);
    text-align: left;
    border-collapse: collapse;
    border: 0px solid black;
    border-radius: 1.25em;
    table-layout: fixed;
    max-width: 100%;
    overflow-x: auto;
    display: block;
    width: 100%;
    margin: 1.25em auto;
    white-space: nowrap;
    font-size: 1em;
    line-height: 1.75em;
    font-weight: 500;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.portDescContainer .portDescContent table tr:first-of-type{
    color: var(--bluePrimaryLight);
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.75em;
    height: 5em;
    border-top: 0px solid var(--gray5);
}

.portDescContainer .portDescContent table tr{
    border-top: 1px solid var(--gray5);
    height: 6em;
}

.portDescContainer .portDescContent table td{
    vertical-align: top;
    padding: 1.5em 0 0 0;
    border: 0px;
    padding-right: 2vw;
}

.portDescContainer .portDescContent table tr td:first-of-type{
    padding-left: 1.5em;
}


@media (hover:hover){
    .portDescContainer .portDescHeader .portDescSubHeader img{
        cursor: pointer;
    }
}

@media screen and (max-width: 1050px){
    /* .portDescContainer .portDescContent table{
        margin: 1.0;
    } */

    .portDescContainer .portDescContent table tr td{
        padding-left: 16px;
    }
    
    .portDescContainer .portDescContent table tr td:first-of-type{
        padding-bottom: 1em;
    }

    .portDescContainer .portDescContent table tr td:first-of-type{
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--lightBlueBg);
        left: -1px;
        white-space: pre-wrap;
        padding-right: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        /* white-space: nowrap; */
    }
}

@media screen and (max-width: 900px){
    .portDescContainer{
        margin: 0;
        margin-top: 5em;
    }

    .portDescContainer .portDescHeader{
        padding: 57px 84px;
    }

    .portDescContainer .portDescHeader .portDescSubHeader{
        width: inherit;
    }

    .portDescContainer .portDescHeader .portDescHeaderDetails{
        width: inherit;
    }

    /* .portDescContainer .goBackbtn{
        padding-left: 85px;
        margin-bottom: 0;
    } */

    .portDescContainer .portDescMain{
        padding: 48px 84px;
        margin-bottom: 0;
    }

    .portDescContainer .showBlogTitle,
    .portDescContainer .portDescContent{
        width: 100%;
    }

    .portDescContainer .portDescContent img{
        margin: 64px 0;
    }
}

@media screen and (max-width: 800px){
    .portDescContainer .portDescContent table tr td{
        font-size: 14px;
        line-height: 175%;
        padding-top: 25px;
    }

    .portDescContainer .portDescContent table tr:first-of-type td{
        font-size: 12px;
        line-height: 175%;
    }
}

@media screen and (max-width: 500px){

    .portDescContainer{
        margin: 0;
        margin-top: 5em;
    }

    .portDescContainer .portDescHeader{
        padding: 40px 28px 76px;
    }
    
    .portDescContainer .portDescHeader .portDescSubHeader{
        flex-direction: column;
    }

    .portDescContainer .portDescHeader .portDescSubHeader img{
        display: block;
        height: 24px;
        width: 24px;
        margin-bottom: 12px;
        justify-self: left;
        align-self: stretch;
    }

    .portDescContainer .portDescHeader .portDescHeaderDetails{
        position: initial;
    }

    .portDescContainer .portDescHeader .portDescHeaderDetails .portDescHeaderTitle{
        font-size: 16px;
        line-height: 24px;
    } 

    /* .portDescContainer .goBackbtn{
        padding: 16px 0 16px 40px;
    }

    .portDescContainer .goBackbtn img{
        height: 24px;
        width: 24px;
    } */

    .portDescContainer .portDescMain{
        padding: 28px 40px;
    }

    .portDescContainer .portDescContent p{
        margin: 16px 0;
    }

    .portDescContainer .portDescContent h1{
        margin: 36px 0 24px;
    }

    .portDescContainer .portDescContent img{
        margin: 36px 0;
        border-radius: 8px;
    }

}
.product-list-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;

}

.product-list-header {
    background-color: var(--lightBlueBg);
    padding: 20px;
    text-align: left;

}

.product-listing-area {
    margin: 4em 0;

}

.product-listing-header {
    height: 450px;
    background-color: var(--lightBlueBg);
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: left;
}

.product-listing-header .list-title {
    font-size: 30px;
    font-weight: 600;
    padding: 20px 0;
    color: var(--bluePrimaryLight);
}

.product-listing-header .list-header-detail {
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
}

.product-listing-detail-section {
    padding: 20px;
}

.product-listing-detail-section .product-list-detail-card {
    padding: 20px;
    padding-left: 30px;
    text-align: left;
    
}

.product-listing-detail-section .product-list-detail-card .product-list-detail-card-title {
    font-size: 22px;
    font-weight: 600;
    color: var(--gray6);
}

.product-listing-detail-section .product-list-detail-card .title {
    position: relative;
    display: inline-block;
}

.product-listing-detail-section .product-list-detail-card:hover .title:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -13px;
    height: 50px;
    width: 4px;
    background-color: var(--bluePrimaryLight);
    border-radius: 25px;
}
.product-listing-detail-section .product-list-detail-card:hover .title:after {
    content: '';
    position: absolute;
    top: 7px;
    right: -35px;
    height: 20px;
    width: 20px;
    background-color: var(--bluePrimaryLight);
    -webkit-clip-path: polygon(45% 50%, 0 100%, 0 3%);
            clip-path: polygon(45% 50%, 0 100%, 0 3%);

}

.product-listing-detail-section .product-list-detail-card .product-list-detail-card-title .product-card-img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
}

.product-listing-detail-section .product-list-detail-card .product-list-detail-card-title img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* .product-listing-detail-section .product-list-detail-card .product-list-detail-card-body {} */

.products .rightOps {
    display: none;
}

a{
    text-decoration: none;
    color: #000000;
}
.service-list-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
}

.service-list-header {
    background-color: var(--lightBlueBg);
    padding: 45px;
    text-align: left;
}
.service-list-header input{
    padding: 8px !important;
    border: 1px solid var(--bluePrimaryLight) !important;
    border-radius: 8px !important;
    width: 300px !important;
}
.service-list-header input:focus{
    outline: none;

}
.service-listing-area {
    margin: 3em 0;

}

.service-listing-header {
    font-size: 30px;
    font-weight: 600;
    padding: 20px 0;
    color: var(--bluePrimaryLight);
}

.service-listing-detail-section {
    padding: 20px;
}
.service-listing-detail-section .MuiGrid-container{
    justify-content: center;
}
.service-listing-detail-section .service-list-detail-card {
    padding: 20px;
    padding-left: 40px;
    text-align: left;
    background-color: var(--lightBlueBg);
    border-radius: 15px;
    position: relative;
    padding-top: 50px;
    transition: 0.5s;
    margin: 10px;
    margin-top: 50px;

}
.service-listing-detail-section .service-list-detail-card:hover {
    background-color: var(--lightBlue);
}

.service-listing-detail-section .service-list-detail-card .service-list-detail-card-title {
    font-size: 22px;
    font-weight: 600;
    color: var(--gray6);
}

.service-listing-detail-section .service-list-detail-card .service-list-detail-card-title .title {
    padding-bottom: 15px;
    position: relative;
    display: inline-block;


}
.service-listing-detail-section .service-list-detail-card:hover .title:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -18px;
    height: 45px;
    width: 4px;
    background-color: var(--bluePrimaryLight);
    border-radius: 25px;
}
.service-listing-detail-section .service-list-detail-card:hover .title:after {
    content: '';
    position: absolute;
    top: 7px;
    right: -35px;
    height: 20px;
    width: 20px;
    background-color: var(--bluePrimaryLight);
    -webkit-clip-path: polygon(45% 50%, 0 100%, 0 3%);
            clip-path: polygon(45% 50%, 0 100%, 0 3%);
}
.service-listing-detail-section .service-list-detail-card .service-list-detail-card-title .service-card-img {
    position: absolute;
    background-color: var(--bluePrimaryLight);
    width: 80px;
    height: 80px;
    position: absolute;
    left: 40px;
    top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.service-listing-detail-section .service-list-detail-card .service-list-detail-card-title .service-card-img img {
    width: 40px;
    height: 40px;
    object-fit: contain;

}

/* .service-listing-detail-section .service-list-detail-card .service-list-detail-card-body {} */


.services .rightOps{
    display: none;
}
.product-about-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
    margin: 30px 0;
}

.product-about-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 20px;
}

.product-about-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}
.product-about-container .product-details{
    margin: 50px 0 0;
}
.product-about-container .product-details .main-header{
display: inline-flex;
}
.about-product-tags-area{
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
}
.about-product-tags-area .about-product-tag{
    border-radius: 8px;
    background-color: var(--lightBlueBg);
    color: var(--bluePrimaryLight);
    padding: 12px 20px;
    cursor: pointer;
}

.product-info-container{
    font-family: 'Poppins';
}
.product-main-img{
    width: 90%;
    margin: auto;
    height: 350px;
}
.product-main-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.product-main-info{
    padding:  25px;
    font-weight: 700;
font-size: 30px;
}

.get-quot-section{
    padding: 50px;
    background-color: var(--bluePrimaryLight);
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    margin: 50px 0;
}
.quot-btn{
    margin-top: 20px;
}

.quot-btn a{
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

}
.quot-btn a div{
    height: 24px;
    padding: 0 10px;
}
/* More product */
.product-more-container{
    font-family: 'Poppins';
    margin: 50px 0;
}

.product-more-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 25px;
}


.product-more-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}
.more-product-card{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
.product-img{
    width: 100%;
    height: 100%;
}
.product-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}


.product-title{
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    color: #fff;
    position: absolute;
    bottom: -20px;
    /* width: 100%; */
    /* padding: 15px; */
    opacity: 0;
    transition: 0.5s;
    z-index: 2;
    left: 15px;
    right: 15px;
}
.more-product-card:hover .product-title{

    bottom: 0;
    opacity: 1;
}

.more-product-card::after{
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background-color: #00000088;
   z-index: 1;
   opacity: 0;
   -webkit-transform: 0.5s;
           transform: 0.5s;
}
.more-product-card:hover.more-product-card::after{
    opacity: 1;
}
.mt-5{
    margin-top: 1.5rem;
}
.px-2{
    padding: 0 1rem;
}
.about-product-tag.active {
    background-color: var(--bluePrimaryLight);

    color: #fff;
}
.product-info-container{
    font-family: 'Poppins';
    margin: 50px 0;
}

.product-info-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 25px;
    display: block;
}


.product-info-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}

.product-info-container .product-works-detail{
    text-align: justify;
}

.product-info-container .product-works-img{
    width: 50%;
    height: 350px;
    margin: 50px auto;

}
.product-info-container .product-works-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.service-key-pionts-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
    margin: 50px 0;
}

.service-key-pionts-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 20px;
}

.service-key-pionts-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}

.service-key-pionts-container ul{
    font-weight: 600;
font-size: 18px;
display: flex;
flex-wrap: wrap;
padding-left: 20px;
}
.service-key-pionts-container ul li{
    color: #202124;
    padding: 15px 0;
    width: 50%;
    text-align: left;
}
.get-quot-section{
    padding: 50px;
    background-color: var(--bluePrimaryLight);
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    margin: 50px 0;
}
.quot-btn{
    margin-top: 20px;
}
.lightBlueBgBannerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    background-color: var(--lightBlueBg);
    padding: 4em 0;
    margin-top: 7em;
}

.lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
    font-size: 3em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    font-weight: 700;
}


@media screen and (max-width: 900px){
    .lightBlueBgBannerContainer{
        margin-top: 5em;
        padding: 3em 0;
    }
}

@media screen and (max-width:  400px){
    .lightBlueBgBannerContainer{
        padding: 40px 0;
    }
    .lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
        font-size: 36px;
        line-height: 54px;
    }
}


.service-about-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
    margin: 50px 0;
}

.service-about-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 20px;
}

.service-about-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}
.service-about-container .service-details{
    margin: 50px 0 0;
}
.service-about-container .service-details .main-header{
display: inline-flex;
}
.about-service-tags-area{
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.about-service-tags-area .about-service-tag{
    border-radius: 8px;
    background-color: var(--lightBlueBg);
    color: var(--bluePrimaryLight);
    padding: 12px 20px;

}
.mt-5{
    margin-top: 1.5rem;
}
.p-0{
    padding: 0 !important;
}

.quot-btn a{
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quot-btn a div{
    height: 24px;
    padding: 0 10px;

}
.px-2{
    padding: 0 1rem;
}
.about-service-tag.active {
    background-color: var(--bluePrimaryLight);

    color: #fff;
}
.service-info-container{
    font-family: 'Poppins';
}
.service-main-img{
    width: 90%;
    margin: auto;
    height: 350px;
}
.service-main-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.service-main-info{
    padding:  25px;
    font-weight: 700;
font-size: 30px;
}


.service-key-pionts-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
    margin: 50px 0;
}

.service-key-pionts-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 20px;
}

.service-key-pionts-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}

.service-key-pionts-container ul{
    font-weight: 600;
font-size: 18px;
display: flex;
flex-wrap: wrap;
padding-left: 20px;
}
.service-key-pionts-container ul li{
    color: #202124;
    padding: 15px 0;
    width: 50%;
    text-align: left;
}
.service-more-container{
    font-family: 'Poppins';
    margin: 50px 0;
}

.service-more-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 25px;
}


.service-more-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}
.more-service-card{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
   -webkit-transform: 0.5s;
           transform: 0.5s;

}
.service-img{
    width: 100%;
    height: 100%;
}
.service-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.service-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    position: absolute;
    bottom: -20px;
    width: 100%;
    padding: 15px;
    opacity: 0;
    transition: 0.5s;
    z-index: 2;
}
.more-service-card:hover .service-title{

    bottom: 0;
    opacity: 1;
}

.more-service-card::after{
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background-color: #00000088;
   z-index: 1;
   opacity: 0;
   -webkit-transform: 0.5s;
           transform: 0.5s;
}
.more-service-card:hover.more-service-card::after{
    opacity: 1;
}
.service-info-container{
    font-family: 'Poppins';
    margin: 50px 0;
}

.service-info-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 25px;
}


.service-info-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}

.service-info-container .service-works-detail{
    text-align: justify;
}

.service-info-container .service-works-img{
    width: 50%;
    height: 350px;
    margin: 50px auto;

}
.service-info-container .service-works-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.allNewsContainer{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4em auto 0;
    grid-column-gap: 1em;
    grid-row-gap: 2.5em;
}

.allNewsLoadMore{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    color: var(--whiteBlueTint);
    background-color: var(--bluePrimaryLight);
    padding: 16px 32px;
    border-radius: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 40px auto 0;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.2s;
}

.noNewsFound img{
    width: 464px;
    margin: 94px auto 40px;
}

.noNewsFound .noNewsFoundMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.noNewsFound .bacKToHomeBtn{
    padding: 16px 32px;
    white-space: nowrap;
    border-radius: 16px;
    background-color: var(--bluePrimaryLight);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5em;
    color: var(--whiteBlueTint);
    transition: all 0.2s;
}



@media (hover:hover){
    .allNewsLoadMore:hover{
        background-color: var(--blueSecondaryDark);
    }

    .noNewsFound .bacKToHomeBtn:hover{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }
}



@media screen and (max-width: 1000px){
    .allNewsContainer{
        grid-template-columns: 1fr 1fr;
        margin-top: 48px;
        grid-row-gap: 1em;
    }

    .allNewsLoadMore{
        margin-top: 40px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
        padding: 8px 17px;
    }
}

@media screen and (max-width: 900px){
    .noNewsFound img{
        margin-top: 48px;
    }

    .noNewsFound .noNewsFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
    }

    .noNewsFound .bacKToHomeBtn{
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 12px;
    }
}

@media screen and (max-width: 650px){
    .allNewsContainer{
        grid-template-columns: 1fr;
        margin-top: 40px;
        grid-row-gap: 24px;
    }

    .noNewsFound img{
        width: 232px;
    }
}

@media screen and (max-width: 400px){
    .noNewsFound img{
        margin-top: 40px;
        width: 80%;
    }
    .noNewsFound .noNewsFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
        padding: 0 10px;
    }
}
.blogOverContainer{
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    border-radius: 1.25em;
    width: 19em;
}

.blogOverContainer a{
    text-decoration: none;
    color: var(--blackCharcol);
}

.blogOverContainer img{
    display: block;
    width: 100%;
    height: 16em;
}

.blogOverContainer .blogDetails{
    background-color: var(--lightBlueBg);
    padding: 1.5em;
    text-align: left;
    border-radius: 0 0 1.25em 1.25em;
}

.blogOverContainer .blogDetails .blogCommodity{
    font-size: 0.75em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--bluePrimaryLight);
}

.blogOverContainer .blogDetails .blogTitle{
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.5em;
    margin: 8px 0;
}

.blogOverContainer .blogDetails .blogDate{
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

@media screen and (max-width: 350px){
    .blogOverContainer{
        width: auto;
        margin: 0 3.4vw;
    }

    .blogOverContainer .blogDetails{
        padding: 6.8vw;
    }

    .blogOverContainer .blogDetails .blogTitle{
        font-size: 5vw;
    }

    .blogOverContainer .blogDetails .blogDate,
    .blogOverContainer .blogDetails .blogCommodity{
        font-size: 4vw;
    }

    .blogOverContainer img{
        height: auto;
    }
}
.newsNavbarContainer{
    display: flex;
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    background-color: var(--lightBlueBg);
    margin-top: 140px;
    padding: 16px 0;
}

.newsNavbarContainer .navbarWrapper{
    width: 944px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}

.newsNavbarContainer .searchBar {
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 25%;
}

.newsNavbarContainer .searchBar input{
    border: none;
    outline: none;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    background-color: var(--lightBlueBg);
    margin-left: 1em;
    width: 90%;
}

.newsNavbarContainer .searchBar input::-webkit-input-placeholder{
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.newsNavbarContainer .searchBar input::placeholder{
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}


.newsNavbarContainer .rightOps{
    display: flex;
    margin: 0 -10px;
}


.newsNavbarContainer .rightOps .dropDown{
    position: relative;
    display: block;
}


.newsNavbarContainer .rightOps .dropDown .dropDownBtn{
    font-weight: 500;
    font-size: 12px;
    line-height: 175%;
    color: var(--lightBlueBg);
    margin: 0 10px;
    border-radius: 8px;
    width: inherit;
    justify-content: space-between;
    display: flex;
    align-items: center;
}


.newsNavbarContainer .rightOps .dropDown .dropDownOptions{
    position: absolute;
    font-weight: 600;
    font-size: 12px;
    line-height: 175%;
    margin-top: 8px;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: white;
    transition: all 0.1s;
    border-radius: 8px;
    padding: 0 8px;
    right: 0;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.newsNavbarContainer .rightOps .dropDown .dropDownOptions .dropDownOptionItem{
    padding: 5px 10px;
    border-radius: 8px;
}

.newsNavbarContainer .rightOps .dropDown .dropDownOptions.openDropDown{
    padding: 8px 8px;
    max-height: 20em;
}

.newsNavbarContainer .rightOps .dropDown .dropDownOptions .selectedOption{
    color: var(--bluePrimaryLight);
    background-color: var(--lightBlueBg);
}




@media (hover:hover){

    .newsNavbarContainer .rightOps .dropDown:hover{
        cursor: pointer;
    }
    
    .newsNavbarContainer .rightOps .dropDown .dropDownOptions .dropDownOptionItem:hover{
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
    }

}

.tabletSearchNewsWrapper{
    display: none;
}


@media screen and (max-width: 1000px){

    .newsNavbarContainer .navbarWrapper{
        width: 624px;
    }

    .newsNavbarContainer .searchBar {
        border: none;
        padding: 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .newsNavbarContainer .searchBar input{
        display: none;
    }

    .tabletSearchNewsWrapper{
        display: block;
        margin: 0 auto;
        width: calc(624px - 20px);;
    }

    input.tabletNewsSearch{
        border: none;
        outline: none;
        display: block;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
        width: calc(100% - 18px);
        border-radius: 8px;
        padding: 0 10px;
        max-height: 0;
        transition: all 0.2s;
    }
    
    input.tabletNewsSearch::-webkit-input-placeholder{
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }
    
    input.tabletNewsSearch::placeholder{
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    input.tabletNewsSearch.showTabletNewsSearchbar{
        max-height: 5em;
        padding: 6px 10px;
        margin-top: 10px;
    }

}


@media screen and (max-width: 900px){
    .newsNavbarContainer{
        margin-top: 5em;
    }
}


@media screen and (max-width: 650px){
    .newsNavbarContainer{
        padding: 16px 0;
    }

    .newsNavbarContainer .navbarWrapper{
        width: 304px;
    }

    .tabletSearchNewsWrapper{
        width: calc(304px - 20px);
    }
}

@media screen and (max-width: 650px){
    .newsNavbarContainer{
        padding: 16px 28px;
    }

    .newsNavbarContainer .navbarWrapper{
        width: 304px;
    }

    .tabletSearchNewsWrapper{
        width: inherit;
        margin: 0 28px;
    }
}

.newsLetterContainer{
    font-family: 'Poppins', sans-serif;
    background-color: var(--bluePrimaryLight);
    background-image: url(/static/media/getStartedBg.aadd7243.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin: 6em 0;
    padding: 4.375em 1em 45px;
}

.newsLetterContainer .newsLetterTitle{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--whiteBlueTint);
    text-transform: capitalize;
}

.newsLetterContainer .newsLetterSubTitle{
    font-weight: 500;
    font-size: 1.125;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--lightBlueBg);
}

.newsLetterContainer .newsLetterForm{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 2.5em auto 0;
}

.newsLetterContainer .newsLetterForm input{
    font-size: 0.75em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    margin-right: 1.3em;
    padding: 1em 1.3em;
    border-radius: 1em;
    font-weight: 500;
    border: none;
    color: var(--blackCharcol);
    background-color: var(--whiteBlueTint);
    width: 19em;
    font-family: 'Poppins', sans-serif;
}

.newsLetterContainer .newsLetterForm input::-webkit-input-placeholder{
    color: var(--gray4);
}

.newsLetterContainer .newsLetterForm input::placeholder{
    color: var(--gray4);
}

.newsLetterContainer .newsLetterForm input:focus{
    outline: none;
}

.newsLetterContainer .newsLetterForm .newsLetterBtn button{
    border: none;
    color: var(--blackCharcol);
    font-size: 0.875em;
    line-height: 1.5em;
    font-weight: 500;
    background-color: var(--whiteBlueTint);
    border-radius: 0.75em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 11px 19px;
    font-family: 'Poppins', sans-serif;
    transition: all 0.2s;
}




@media (hover:hover){
    .newsLetterContainer .newsLetterForm .newsLetterBtn button:hover{
        background-color: white;
        cursor: pointer;
    }
}





@media screen and (max-width: 900px){
    .newsLetterContainer{
        margin: 48px 0;
        padding: 48px 28px 23px;
    }

    .newsLetterContainer .newsLetterForm input{
        padding: 12px;
        font-size: 10px;
        line-height: 15px;
        border-radius: 8px;
        margin-right: 11px;
    }

    .newsLetterContainer .newsLetterForm .newsLetterBtn button{
        padding: 10px 14px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
    }
}

@media screen and (max-width: 400px){
    .newsLetterContainer {
        margin: 40px 0;
        padding: 40px 28px 15px;
    }

    .newsLetterContainer .newsLetterTitle{
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
    }

    .newsLetterContainer .newsLetterSubTitle{
        font-size: 18px;
        line-height: 27px;
    }

    .newsLetterContainer .newsLetterForm input{
        flex: 0 1;
        width: auto;
    }
}



.recomNewsContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin: 0 0 6em;
}

.recomNewsContainer .recomNewsHeadline{
    font-size: 2.25em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.recomNewsContainer .recomNews{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2em auto 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-column-gap: 1em;
}



@media screen and (max-width: 1000px){
    .recomNewsContainer{
        margin-bottom: 48px;
    }
    
    .recomNewsContainer .recomNews{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }

    .recomNewsContainer .recomNewsHeadline{
        font-size: 24px;
        line-height: 140%;
    }
}


@media screen and (max-width: 650px){
    .recomNewsContainer{
        padding: 0 28px;
        margin: 40px 0;
    }

    .recomNewsContainer .recomNews{
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
    }

}
.showNewsContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.showNewsContainer .goBackbtn{
    background-color: var(--lightBlueBg);
    margin-bottom: 64px;
    width: inherit;
    padding: 14px 0 14px 16%;
    display: flex;
}

.showNewsContainer .goBackbtn img{
    display: block;
}

.showNewsContainer .showNewsTitle{
    font-size: 2.25em;
    font-weight: 700;
    line-height: 130%;
    width: 45%;
    margin: 0 auto;
    text-align: left;
}

.showNewsContainer .showNewsMain img{
    margin: 4em auto;
    border-radius: 1.25em;
    width: 68%;
}

.showNewsContainer .showNewsContent{
    text-align: left;
    width: 45%;
    margin: 0 auto;
}

.showNewsContainer .showNewsContent p{
    font-size: 1em;
    line-height: 160%;
    font-weight: 500;
    margin: 32px 0;
}

.showNewsContainer .showNewsContent h2{
    font-size: 1.5em;
    line-height: 130%;
    font-weight: 700;
    margin: 40px 0;
}

.showNewsContainer .showNewsContent img{ 
    margin: 64px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* max-width: 100%; */
    width: 100%;
    height: auto;
    display: block;
}

.showNewsContainer .showNewsContent iframe{
    /* border: none;
    margin: 0 auto; */
    text-align: center;
    width: inherit;
}

.showNewsContainer .showNewsContent blockquote{
    background-color: var(--lightBlueBg);
    padding: 5px 10px;
    border-left: 8px solid var(--gray3);
}

@media (hover:hover){
    .showNewsContainer .goBackbtn img:hover{
        cursor: pointer;
    }
}


@media screen and (max-width: 900px){
    .showNewsContainer{
        margin: 0;
        margin-top: 5em;
    }

    .showNewsContainer .goBackbtn{
        padding-left: 85px;
        margin-bottom: 0;
    }

    .showNewsContainer .showNewsMain{
        padding: 48px 84px 0;
    }

    .showNewsContainer .showNewsMain img{
        margin: 48px 0;
        width: 100%;
    }

    .showNewsContainer .showNewsTitle,
    .showNewsContainer .showNewsContent{
        width: 100%;
    }

    .showNewsContainer .showNewsContent img{
        margin: 64px 0;
    }
}

@media screen and (max-width: 500px){

    .showNewsContainer{
        margin: 0;
        margin-top: 5em;
    }

    .showNewsContainer .goBackbtn{
        padding: 16px 0 16px 40px;
    }

    .showNewsContainer .goBackbtn img{
        height: 24px;
        width: 24px;
    }

    .showNewsContainer .showNewsMain{
        padding: 28px 40px 0;
    }

    .showNewsContainer .showNewsTitle{
        font-size: 24px;
        line-height: 130%;
    }

    .showNewsContainer .showNewsMain img{
        margin: 24px 0;
        border-radius: 8px;
    }

    .showNewsContainer .showNewsContent p{
        margin: 16px 0;
    }

    .showNewsContainer .showNewsContent h2{
        margin: 36px 0 24px;
    }

    .showNewsContainer .showNewsContent img{
        margin: 36px 0;
        border-radius: 8px;
    }

}
.socialMediaContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 64px auto 0;
}

.socialMediaContainer .shareHeading{
    font-size: 16px;
    line-height: 130%;
    font-weight: 700;
    margin-bottom: 12px;
}

.socialMediaContainer .socialMediaIcons{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.socialMediaContainer .socialMediaIcons .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 4px;
    background-color: var(--whiteBlueTint);
    border-radius: 4px;
}

.socialMediaContainer .socialMediaIcons .icon img{
    display: block;
}




@media screen and (max-width: 900px){
    .socialMediaContainer{
        margin-top: 48px;
    }
}


@media screen and (max-width: 400px){
    .socialMediaContainer{
        margin-top: 40px;
    }
    
    .socialMediaContainer .socialMediaIcons .icon{
        border-radius: 4px;
    }
}
.coalIndexContainer {
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 4em 0 0;
    margin-top: 50px;
}

.coalIndexContainer .coalIndexHeading {
    font-weight: 600;
    font-size: 2.25em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
}

.coalIndexContainer .coalIndexTable {
    width: 68%;
    margin: 3.5em auto 5.5em;
}

.new-26{
    width: 100%;
    height: 25%;
    margin-top: -60px;
    margin-bottom: 30px;
}

.new-26 img{
    width: 68%;
    border-radius: 10px;
}





.coalIndexContainer .coalIndexTable .coalIndexTableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.coalIndexContainer .coalIndexTable .coalIndexTableHeader img.tabletSearchIcon {
    display: none;
}

.coalIndexContainer .coalIndexTable .coalIndexTableHeader .searchBar {
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 25%;
}

.coalIndexContainer .coalIndexTable .coalIndexTableHeader .searchBar input {
    border: none;
    outline: none;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    margin-left: 1em;
    width: 90%;
}

.coalIndexContainer .coalIndexTable .searchBar input::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.coalIndexContainer .coalIndexTable .searchBar input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer {
    display: flex;
    margin: 0 -6px;
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer img {
    background-color: var(--bluePrimaryLight);
    margin: 0 6px;
    border-radius: 8px;
    padding: 6px;
    cursor: pointer;
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer .paginationPgNo {
    font-weight: 500;
    font-size: 1em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    padding: 4px 15px;
    margin: 0 6px;
    cursor: pointer;
    display: none;
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer .paginationPgNo.thisPage {
    background-color: var(--bluePrimaryLight);
    color: white;
}

.coalIndexContainer .coalIndexTable .coalPaginationContainer .paginationPgNo.showPageNo {
    display: inherit;
}

.coalIndexContainer .coalIndexTable input.tabletSearchBar {
    display: none;
}









.coalIndexContainer .coalIndexTable table {
    background-color: var(--lightBlueBg);
    position: relative;
    text-align: left;
    border-collapse: collapse;
    border-radius: 1.25em;
    width: 100%;
    margin: 1.25em auto;
    padding: 1.375em 0 0 1.5em;
}

.coalIndexContainer .coalIndexTable table::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    /* background: url("../../../images/pngs/logo\ icon.png"); */
    background: url(/static/media/coalIndexWaterMark.4d076aee.svg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
}

.coalIndexContainer .coalIndexTable table thead {
    color: var(--bluePrimaryLight);
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.75em;
}

.coalIndexContainer .coalIndexTable table thead tr {
    height: 5em;
}

.coalIndexContainer .coalIndexTable table thead tr th:first-of-type {
    padding-left: 1.5em;
}

.coalIndexContainer .coalIndexTable table tbody {
    font-size: 1em;
    line-height: 1.75em;
    font-weight: 500;
}

.coalIndexContainer .coalIndexTable table tbody tr {
    border-top: 1px solid var(--gray5);
    height: 6em;
}

.coalIndexContainer .coalIndexTable table tbody td {
    vertical-align: top;
    padding: 1.5em 0 0 0;
}

.coalIndexContainer .coalIndexTable table tr td:first-of-type {
    padding-left: 1.5em;
    padding-right: 0vw;
    width: unset;
}

.coalIndexContainer .coalIndexTable table tbody td .coalIndexDataTime {
    color: var(--gray3);
    font-size: 0.75em;
    line-height: 175%;
}

.coalIndexContainer .coalIndexTable table tbody td .priceChangeBlock {
    display: flex;
    align-items: center;
}

.coalIndexContainer .coalIndexTable table tbody td .priceChangeBlock img {
    margin-left: 5px;
}

.coalIndexContainer .coalIndexTable table tbody td .priceChangePer {
    font-size: 0.75em;
    line-height: 175%;
}


.coalIndexContainer .coalIndexTable table tr td.specialData {
    padding: 1em 0;
    text-align: center;
    margin: 1em 0;
    font-weight: 400;
    color: var(--gray3);
    border-top: 1px solid var(--gray5);

}


.coalIndexContainer .coalIndexTable .coalIndexTableFooter {
    display: flex;
    justify-content: right;
}


.subscribe-box {
    box-sizing: border-box;
    border: 1px solid #415AA9;
    border-radius: 20px;
    margin: 50px auto;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 68%;
}

.subscribe-info {
    margin: 0 auto;
    position: relative;
}

@-webkit-keyframes wiggle {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   80% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   85% { -webkit-transform: rotate(20deg); transform: rotate(20deg); }
   95% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
}

@keyframes wiggle {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   80% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   85% { -webkit-transform: rotate(20deg); transform: rotate(20deg); }
   95% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
}

.bell-icon{
    position: absolute;
    right: 50px;
}

.bell-icon img{
    width: 100px;
    -webkit-animation: wiggle 1.5s infinite;
            animation: wiggle 1.5s infinite;
}

.title-line-1 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    /* identical to box height */

    text-align: center;

    color: #415AA9;

}

.title-line-2 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;
    padding-top: 6px;
    color: #415AA9;

    opacity: 0.8;
}

.title-line-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    cursor: pointer;
    /* transition: all 2s ease; */
}

.title-line-3:before {
    content: "";
    position: absolute;
    /* top: 0; */
    left: 40px; 
    z-index: -1;
    display: block;
    border-radius: 28px;
    background: transparent;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.title-line-3:hover:before {
    width: 68%;
    background: #415AA9;
}

/* .title-line-3:hover{
    background: #415AA9;
    border-radius: 10px;
    padding: 12px;
 
    width: 200px;
} */

.title-line-3:hover span{
    color: #FFFFFF;
}

.title-line-3 span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */
    /* padding-top: 30px; */
    padding-right: 20px;

    color: #415AA9;
}

.subscribe-banner {
    padding: 18px 30px 0;
    z-index: 1;
}

.custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.subscribe-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    /* text-align: justify; */
    margin: 0px;
    color: #282828;
}

.subscribe-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    text-align: justify;

    color: #7A7878;
    margin: 0px;
    padding-bottom: 35px;

}

.close-icon {
    align-self: start;
    cursor: pointer;
}

.background-box {
    position: relative;
}

.background-box svg {
    position: absolute;
    left: 0px;
    width: 612px;
    height: 590px;
}

.form-area {
    margin-top: 30px;
}

.field-box {

    margin-bottom: 20px;
}

.field-box input,textarea {
    width: 90%;
    background: #FDFDFF;
    border: 1px solid rgba(23, 104, 172, 0.23);
    border-radius: 6px;
    padding: 17px 23px;
}

.field-box input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.field-box input::placeholder,textarea::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #BFBABA;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    /* margin-left: 25px; */
}

.submit-btn:hover > p,svg{
    color: #3c8cd0;
}

.submit-btn p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    color: #1768AC;
    padding-right: 10px;
    margin: 0px;
}

.custom-footer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: justify;

    color: #161616;

}

.image-box svg {
    width: 100%;
}

.custom-body{
    display: flex;
    /* justify-content: space-between; */
}

.form-area{
    margin-left: 25px;
}

.custom-subscribe div.MuiDialog-paper{
    width: 70%;
    height: 590px;
    max-width: unset;
    overflow: hidden;
}

.custom-body-img{
    width: 50%;
}

.custom-body-form{
    width: 50%;
}

@media  (max-width:991px){
    .new-26{
        margin-top: 30px;
    }
    .new-26 img{
        width: 100%;
    }
    .background-box {
        display: none;
    }
    .custom-body-img{
        width: 100%;
    }
    
    .custom-body-form{
        width: 100%;
    }
    .custom-body{
        display: block;
        /* justify-content: space-between; */
    }
    .custom-subscribe div.MuiDialog-paper{
        width: 100%;
        height: auto;
        overflow: auto;
    }
    .form-area{
        width: 100%;
        margin-left: 0px;
    }
    .field-box input,textarea {
        width: 82%;
    }
    .subscribe-banner {
        padding: 18px 15px;
        z-index: 1;
    }
    /* .subscribe-title {
        font-size: 28px;
        line-height: 40px;
        margin-right: 10px;
        margin-bottom: 15px;
    } */
}

@media screen and (max-width: 1050px) {
    .coalIndexContainer {
        padding: 48px 50px 0;
        margin-bottom: 88px;
    }

    .coalIndexContainer .coalIndexTable {
        width: inherit;
        margin: 0;
    }

    .coalIndexContainer .coalIndexTable .view {
        width: inherit;
        margin: 20px 0 40px;
    }

    .coalIndexContainer .coalIndexTable .view .wrapper {
        position: relative;
        overflow: auto;
        white-space: nowrap;
        border-radius: 1.25em;
    }

    .coalIndexContainer .coalIndexTable table {
        margin: 0;
    }

    .coalIndexContainer .coalIndexTable table tbody tr td,
    .coalIndexContainer .coalIndexTable table thead tr th {
        padding-left: 16px;
    }

    .coalIndexContainer .coalIndexTable .view .wrapper table tbody tr td.stickyCol {
        padding-bottom: 1em;
    }

    .coalIndexContainer .coalIndexTable .view .wrapper table tbody tr td.stickyCol,
    .coalIndexContainer .coalIndexTable .view .wrapper table thead tr th.stickyCol {
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--lightBlueBg);
        left: -1px;
        white-space: pre-wrap;
        padding-right: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        /* white-space: nowrap; */
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }

}

@media screen and (max-width: 900px) {
    .coalIndexContainer {
        margin-bottom: 48px;
    }

    .coalIndexContainer .coalIndexTable .coalIndexTableHeader .searchBar {
        display: none;
    }

    .coalIndexContainer .coalIndexTable .coalIndexTableHeader img.tabletSearchIcon {
        display: block;
        margin-left: 8px;
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar {
        border: none;
        outline: none;
        display: block;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
        width: calc(100% - 20px);
        border-radius: 8px;
        padding: 0 10px;
        max-height: 0;
        transition: all 0.2s;
        margin: 0;
        margin-top: 20px;
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar::-webkit-input-placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar::placeholder {
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar.showTabletSearchbar {
        max-height: 5em;
        padding: 6px 10px;
    }

    .coalIndexContainer .coalIndexTable .coalIndexTableHeader {
        margin-top: 40px;
        flex-direction: column;
        grid-gap: 15px;
        gap: 15px;
    }

    .coalIndexContainer .coalIndexTable .coalPaginationContainer {
        margin: 0 -3px;
    }

    .coalIndexContainer .coalIndexTable .coalPaginationContainer img {
        height: 13px;
        width: 13px;
        margin: 0 3px;
        border-radius: 4px;
        padding: 6px;
    }

    .coalIndexContainer .coalIndexTable .coalPaginationContainer .paginationPgNo {
        font-size: 12px;
        line-height: 175%;
        padding: 0;
        height: 23px;
        width: 23px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    .coalIndexContainer .coalIndexTable .coalIndexTableFooter {
        justify-content: center;
    }

    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
    .import-export-svg svg{
        display: none;
    }
    .form-area-import .MuiFormControl-fullWidth{
        width: 75vw;
    }
    .imp-submit-btn{
        display: flex;
    justify-content: center;
    width: 100%;
    }
    .main-container div.MuiDialog-paper{
        width: 100% !important;
    }
}


@media screen and (max-width: 600px) {
    .coalIndexContainer {
        padding: 40px 28px 0;
        margin-bottom: 40px;
    }

    .coalIndexContainer .coalIndexHeading {
        font-size: 24px;
        line-height: 36px;
    }

    .coalIndexContainer .coalIndexTable input.tabletSearchBar {
        margin-top: 16px;
    }

    .coalIndexContainer .coalIndexTable .view {
        margin: 16px 0 24px;
    }

    .coalIndexContainer .coalIndexTable table {
        border-radius: 8px;
    }

    .coalIndexContainer .coalIndexTable table thead tr th {
        font-size: 12px;
        line-height: 175%;
    }

    .coalIndexContainer .coalIndexTable table tbody tr td {
        font-size: 14px;
        line-height: 175%;
        padding-top: 25px;
    }

    .coalIndexContainer .coalIndexTable .view .wrapper table tbody tr td.stickyCol {
        display: block;
        white-space: normal;
        margin-right: 0;
        width: 127px;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}


@media screen and (max-width: 300px) {

    .coalIndexContainer .coalIndexTable .view .wrapper table tbody tr td.stickyCol,
    .coalIndexContainer .coalIndexTable .view .wrapper table thead tr th.stickyCol {
        position: initial;
    }
    .subscribe-box {
        padding: 25px 8px;
        width: inherit;
    }

    .bell-icon{
        top: -32px;
        right: -5px;
    }
    
    .bell-icon img{
        width: 45px;
    }
}

.main-container div.MuiDialog-paper{
        width: 70%; 
    height: unset;
    max-width: unset;
    overflow: auto;
    border-radius: 8px;
}

.form-area-import{
    margin-block: 15px;
}

.import-export-svg{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    height: 50vh;
    align-self: center;
}

.import-export-svg svg{
    width: 140%;
    height: auto;
    position: absolute;
    right: -30px;
    bottom: 0;
    z-index: -1;
}

.imp-exp-btn{
    color: white;
    background-color: var(--bluePrimaryLight);
    padding: 0.7em 1.2em;
    border-radius: 0.75em;
    text-decoration: none;
    font-size: 0.875em;
    line-height: 1.5em;
    white-space: nowrap;
    transition: all 0.2s;
}

.imp-exp-btn:hover{
    background-color: var(--blueSecondaryDark);
}

.google-import-label{
    position: absolute;
    z-index: 1000;
    background: #fff;
    margin-left: 9px;
    margin-top: -7px;
    padding-inline: 10px !important;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 10px;
    padding-inline: 20px;
}

.header-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    margin: 0px;
    color: #202124;
}

.header-subcontent-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #98D2EC;
    padding: 15px 20px;
    border-radius: 6px;
    margin-bottom: 15px;
}

.header-subcontent-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    color: #202124;
    margin: 0px;
}

.star {
    color: #F44336;
}

.import-export-container {
    background: #FFFFFF;
    width: 100%;
    border-radius: 6px;
    margin: 0px;
    margin-top: 15px;
    /* background-image: url(/Winasia/css/image/import_export-svg-image.svg); */
    background-position: right;
    background-size: 73%;
    background-repeat: no-repeat;
}

.import-export-head-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    text-transform: uppercase;
    color: #2541B2;
    padding-top: 15px;
    padding-left: 25px;
}

input#exampleFormControlInput1:focus {
    box-shadow: none;
    border: 1px solid #1768AC;
}

select.form-control {
    margin-block: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #343434;
}

input#exampleFormControlInput1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #BFBABA;
}

select.form-contro.form-control-lg {
    width: 100%;
    margin-block: 10px;
}

.col-md-12 {
    margin-top: 12px;
}

.col-md-6.import-export-leftside {
    padding-left: 23px;
}

label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1768AC;
    padding-bottom: 3px;
}

.footer-button {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 10px;
    gap: 10px;
    margin-block: 10px;
}

.footer-btn-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #1768AC;
    margin: 0px;
}

.icon-box {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #1768AC;
}

@media screen and (max-width:767px) {
    .import-export-container {
        background-image: none;
    }
}
