.portsMapContainer {
    font-family: 'Poppins', sans-serif;
}

.portsMapContainer .portsMapHead {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: var(--gray3);
}

.portsMapContainer .portsMapMain {
    width: 60%;
    margin: 0 auto;
}
.d-flex{
    display: flex;
}
.map-box .map-img{
    width: 72px;
    height: 72px;
}
.map-box .map-img img{
    width: 72px;
    height: 72px;
    object-fit: cover;
}   
@media screen and (max-width: 900px) {
    .portsMapContainer {
        padding-top: 48px;
    }

    .portsMapContainer .portsMapHead {
        font-size: 12px;
    }

    .portsMapContainer .portsMapMain {
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .portsMapContainer .portsMapHead {
        font-size: 8px;
    }

    .portsMapContainer .portsMapMain {
        width: 100%;
    }
}

.portsMapMain a g {
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.5s;
}

.portsMapMain a:hover g {
    opacity: 1;
    transform: scale(1);
}


.portsMapMain {
    width: 100%;
}

.portsMapMain svg {
    object-fit: contain;
    width: 100%;
}

.section-head {
    font-weight: 600;
    font-size: 35px;
    color: #343434;
    padding-bottom: 35px;

}

.section-head span {
    color: #415AA9;
}

.global-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.filter-btn {
    cursor: pointer;
    border: 1px solid #B0B3C8;
    color: var(--bluePrimaryLight);
    border-radius: 8px;
    padding: 4px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;

}

.global-map-section {
    display: flex;

}

.map-area {
    width: 100%;

    height: 500px;
}

.map-area .map {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 20px;
}

.map-details {
    height: 500px;
    overflow: auto;
    padding: 0 0 0 15px;
}

.map-details .map-card-area {
    background-color: var(--lightBlueBg);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
}
.map-details .map-card {
    display: flex;
    align-items: center;
   gap: 15px;
}

.map-details .map-img {
    flex-shrink: 0;

    border-radius: 50%;
    overflow: hidden;
}

.map-details .map-img {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.map-details .map-card-content h3 {
    color: var(--bluePrimaryLight);
    margin-bottom: 7px;
    text-align: left;
}

.map-details .map-card-content .port-type {
    text-align: left;
    display: flex;
    align-items: center;
}
.map-details .map-card-content .port-type div {
    margin-right: 10px !important;

}
.map-details .port-location {
    text-align: right;
    color: var(--bluePrimaryLight);
    font-size: 12px;
    line-height: 0.5;
}
.text-orange{
color: #EF9626;
}
.text-purple{
color: #6F41A9;
}
.text-blue{
color: #415AA9;
}
.map-card-area.active{
    background-color: var(--bluePrimaryLight);
}
.map-card-area.active h3{
    color: #fff;
}
.map-card-area.active .port-location{
    color: #fff;
}
.markers{
    display: flex;
    font-weight: 700;
    font-size: 18px;
    padding-right: 20px;
}
.markers img{
    width: 25px;
    object-fit: contain;
    margin-right: 10px;
}
.port-type img{
    width: 20px;
    object-fit: contain;
    margin-right: 10px;
}
.markers span{
    vertical-align: middle;
}
.port-text{
    text-transform: capitalize;
}
/* 
.map-box{
    padding: 15px;
} */
.map-box .map-img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
}
.map-box .map-img{
    width: 45px;
    height: 45px;
 object-fit: cover;
}
.map-box h3{
    color: var(--bluePrimaryLight);
    text-align: left;
    margin: 15px 0;
    font-size: 18px;
}
.map-box p{
    font-size: 12px;
line-height: 15px;
text-align: left;
color: #7A7878;
}
.gm-style-iw{
    max-width: 250px !important;
}

.map-marker img{
    width: 15px;
    object-fit: contain;
}