.landingAboutCompContainer{
    font-family: 'Poppins', sans-serif;
    margin-top: 7em;
    color: var(--whiteBlueTint);
    padding: 146px 0 143px;
    background: linear-gradient( rgba(40, 40, 40, 0.75) 100%, rgba(40, 40, 40, 0.75)100%), url("../../images/pngs/aboutLandingBg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-position: center;
}

.landingAboutCompContainer .landingAboutCompHead{   
    font-size: 48px;
    line-height: 130.5%;
    text-align: center;
    font-weight: 700;
    letter-spacing: -0.02em;
    width: 53.3%;
    margin: 0 auto 24px;
}

.landingAboutCompContainer .landingAboutCompSubHead{
    font-size: 24px;
    line-height: 140.5%;
    text-align: center;
    letter-spacing: -0.02em;
    width: 44%;
    margin: 0 auto;
}





@media screen and (max-width: 900px){

    .landingAboutCompContainer{
        padding: 155px 164px;
        margin-top: 5em;
        background-size: auto 100%;
    }

    .landingAboutCompContainer .landingAboutCompHead{
        font-size: 36px;
        line-height: 130.5%;
        margin: 0 0 24px;
        width: 100%;
    }

    .landingAboutCompContainer .landingAboutCompSubHead{
        width: 100%;
        font-size: 16px;
        line-height: 140.5%;
    }
}

@media screen and (max-width: 600px){
    .landingAboutCompContainer{
        padding: 157px 28px;
    }
}


@media screen and (max-width: 400px){
    .landingAboutCompContainer .landingAboutCompHead{
        font-size: 24px;
        line-height: 130.5%;
    }
}