.faqContainer{
    font-family: 'Poppins', sans-serif;
}

.faqContainer .faqHeading{
    font-size: 2.25em;
    line-height: 140%;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: var(--blackCharcol);
    text-transform: capitalize;
    margin-bottom: 0.9em;
}

.faqContainer .faqQueAnsContainer{
    display: flex;
    flex-direction: column;
    margin: -0.75em 0;
}

.faqContainer .faqQueAnsContainer .faqItem{
    display: flex;
    background-color: var(--lightBlueBg);
    width: 70%;
    padding: 2em;
    text-align: left;
    color: var(--blackCharcol);
    justify-content: space-between;
    border-radius: 12px;
    margin: 0.75em auto;
}

.faqContainer .faqQueAnsContainer .faqItem .faqContent{
    height: fit-content;
    align-self: center;
}

.faqContainer .faqQueAnsContainer .faqItem .radioLabel{
    height: 32px;
}

.faqContainer .faqQueAnsContainer .faqItem .faqQue{
    font-weight: 600;
    line-height: 140%;
    font-size: 1.125;
    width: 80%;
}

.faqContainer .faqQueAnsContainer .faqItem .faqAns{
    font-size: 18px;
    line-height: 140%;
    width: 80%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
}

.faqContainer .faqQueAnsContainer .faqItem .faqAns a{
    text-decoration: underline;
    color: var(--bluePrimaryLight);
}

.faqContainer .faqQueAnsContainer .faqItem .radioButton{
    position: absolute;
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}

.faqContainer .faqQueAnsContainer .faqItem .radioLabel img{
    transition: all 0.5s;
}

.faqContainer .faqQueAnsContainer .faqItem .radioLabel img.rotate{
    transform: rotate(45deg);
}

.faqContainer .faqQueAnsContainer .faqItem .showFaq{
    margin-top: 1.334em;
    max-height: 10em;
}


.faqPricingHelper{
    margin-bottom: 96px;
}




@media (hover:hover) {
    .faqContainer .faqQueAnsContainer .faqItem{
        cursor: pointer;
    }
}



@media screen and (max-width: 900px){
    .faqContainer{
        padding: 48px 84px;
    }

    .faqContainer .faqQueAnsContainer{
        width: inherit;
        margin: -12px 0;
    }

    .faqContainer .faqQueAnsContainer .faqItem{
        width: inherit;
        padding: 24px;
        margin: 15px 0;
        border-radius: 8px;
    }

    .faqContainer .faqQueAnsContainer .faqItem .radioLabel{
        height: fit-content;
    }

    .faqContainer .faqQueAnsContainer .faqItem .radioLabel img{
        height: 24px;
        width: 24px;
        display: block;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqContent{
        flex: 1;
        margin-right: 24px;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqQue{
        margin: 0 12px 0 0;
        width: inherit;
        font-size: 14px;
        line-height: 140%;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqAns{
        font-size: 12px;
        line-height: 140%;
        margin: 0;
        width: inherit;
    }

    .faqContainer .faqQueAnsContainer .faqItem .showFaq{
        margin-top: 12px;
    }

    .faqPricingHelper{
        margin-bottom: 0;
    }
}

@media screen and (max-width: 500px){
    .faqContainer{
        padding: 40px 28px;
    }

    .faqContainer .faqHeading{
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 40px;
    }

    .faqContainer .faqQueAnsContainer{
        width: inherit;
        margin: -12px 0;
    }

    .faqContainer .faqQueAnsContainer .faqItem{
        width: inherit;
        padding: 16px;
        margin: 16px 0;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqContent{
        margin-right: 0;    
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqQue{
        margin: 0 12px 0 0;
        width: 90%;
        font-size: 14px;
        line-height: 140%;
    }

    .faqContainer .faqQueAnsContainer .faqItem .faqAns{
        font-size: 12px;
        line-height: 140%;
        margin-right: -24px;
        margin: 0;
        width: 105%;
    }

    .faqContainer .faqQueAnsContainer .faqItem .showFaq{
        margin-top: 12px;
    }
}
