.statsContainer {
    font-family: 'Poppins', sans-serif;
}

.statsContainer .statsHeadline{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: var(--blackCharcol);
}

.statsContainer .statsDetails{
    display: flex;
    padding: 2em 0;
    width: 70%;
    margin: 2.5em auto 0;
    background-color: var(--lightBlueBg);
    border-radius: 1em;
    text-align: left;
}

.statsContainer .statsDetails .statsItem{
    padding-left: 2.5em;
    height: 5.25em;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.statsContainer .statsDetails .statsItem:nth-child(2),
.statsContainer .statsDetails .statsItem:nth-child(3),
.statsContainer .statsDetails .statsItem:nth-child(4){
    border-left: 1px solid rgba(130, 130, 130, 0.4);
}

.statsContainer .statsDetails .statsItem .statsData{
    font-weight: 600;
    color: var(--bluePrimaryLight);
    font-size: 2.25em;
    line-height: 130%;
}

.statsContainer .statsDetails .statsItem .statsTag{
    font-weight: 500;
    color: var(--gray3);
    line-height: 140%;
}






@media screen and (max-width: 800px){
    .statsContainer .statsDetails{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: inherit;
        margin: 46px 84px 0;
        padding: 24px 0;
        grid-row-gap: 24px;
    }

    .statsContainer .statsDetails .statsItem:nth-child(3){
        border-left: none;
    }
    
    .statsContainer .statsDetails .statsItem{
        padding-left: 31%;
    }

    /* To add Active users Again do:border: 
        1. Comment next 2 design blocks.
        2. Uncomment 3rd block. 
    */

    .statsContainer .statsDetails .statsItem:nth-child(1){
        padding-left: 41%;
    }

    .statsContainer .statsDetails .statsItem:nth-child(3){
        grid-column: 1/3;
        padding-left: 0;
        width: fit-content;
        margin: 0 auto;
    }

    /* .statsContainer .statsDetails .statsItem:nth-child(1),
    .statsContainer .statsDetails .statsItem:nth-child(3){
        padding-left: 41%;
    } */
}


@media screen and (max-width: 500px){
    .statsContainer .statsHeadline{
        font-size: 24px;
        line-height: 140%;
    }

    .statsContainer .statsDetails{
        margin: 24px 28px 0;
        padding: 24px 0;
        grid-row-gap: 24px;
    }

    .statsContainer .statsDetails .statsItem{
        padding-left: 16px;
    }

    .statsContainer .statsDetails .statsItem:nth-child(1),
    .statsContainer .statsDetails .statsItem:nth-child(3){
        padding-left: 24px;
    }

    .statsContainer .statsDetails .statsItem .statsData{
        font-size: 24px;
        line-height: 130%;
    }
    
    .statsContainer .statsDetails .statsItem .statsTag{
        font-size: 16px;
        line-height: 140%;
    }
}