.ppContentMain{
    margin: 40px 29.5vw 64px;
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    text-align: left;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.ppContentMain strong{
    color: var(--blackCharcol);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    margin: 40px 0 24px;
}

@media screen and (max-width:  1000px){
    .ppContentMain{
        margin: 24px 15vw 40px;
        font-size: 14px;
    }

    .ppContentMain strong{
        font-size: 18px;
    }
}

@media screen and (max-width:  450px){
    .ppContentMain{
        margin: 24px 8vw 40px;
    }
}