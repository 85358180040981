.service-info-container{
    font-family: 'Poppins';
    margin: 50px 0;
}

.service-info-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 25px;
}


.service-info-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}

.service-info-container .service-works-detail{
    text-align: justify;
}

.service-info-container .service-works-img{
    width: 50%;
    height: 350px;
    margin: 50px auto;

}
.service-info-container .service-works-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}