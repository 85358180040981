.lightBlueBgBannerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    background-color: var(--lightBlueBg);
    padding: 4em 18vw;
    margin-top: 7em;
    display: flex;
    align-items: center;
}

.lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
    font-size: 3em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    font-weight: 700;
    width: fit-content;
    margin: 0 auto;
    flex: 1;
    position: relative;
    left: -20px;
}



@media (hover:hover){
    .lightBlueBgBannerContainer .ppGoBackBtn:hover{
        cursor: pointer;
    }
}



@media screen and (max-width: 900px){
    .lightBlueBgBannerContainer{
        margin-top: 5em;
        padding: 3em 10vw;
    }

    .lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
        font-size: 36px;
    }
}

@media screen and (max-width:  550px){
    .lightBlueBgBannerContainer{
        padding: 40px 10vw;
        flex-direction: column;
    }

    .lightBlueBgBannerContainer .ppGoBackBtn{
        align-self: flex-start;
    }

    .lightBlueBgBannerContainer img {
        display: block;
        height: 24px;
        width: 24px;
    }

    .lightBlueBgBannerContainer .lightBlueBgBannerHeadline{
        font-size: 24px;
        line-height: 54px;
        left: 0;
    }
}

