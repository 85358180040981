.aboutSectionContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
}

.aboutSectionContainer .aboutSectionItem{
    display: flex;
    margin: 96px 0;
    justify-content: center;
    align-items: center;
}

.aboutSectionContainer .rowAboutItemdata{
    flex-direction: row;
}

.aboutSectionContainer .rowReverseAboutItemdata{
    flex-direction: row-reverse;
}

.aboutSectionContainer .aboutSectionItem img{
    height: 28.75em;
    width: 28.75em;
    margin: 0;
    border-radius: 20px;
    display: block;
}

.aboutSectionContainer .aboutSectionItem .imageContainer{
    position: relative;
    margin: 0 20px;
}

.aboutSectionContainer .aboutSectionItem .imageContainer::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(65, 90, 169, 0.25);
    border-radius: 20px;
}

.aboutSectionContainer .aboutSectionItem:nth-of-type(2) .imageContainer::after{
    background-color: rgba(65, 90, 169, 0.50);
}


.aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
    width: 443px;
    margin: 0 20px;
    text-align: left;
}

.aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail .aboutSectionItemHead{
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
}

.aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail .aboutSectionItemContent{
    font-weight: 500;
    font-size: 16px;
    line-height: 175%;
}




@media screen and (max-width: 900px){

    .aboutSectionContainer{
        margin: 0;
        padding: 12px 84px;
    }

    .aboutSectionContainer .aboutSectionItem{
        margin: 36px -8px;
        width: inherit;
    }

    .aboutSectionContainer .aboutSectionItem .imageContainer{
        margin: 0 8px;
        border-radius: 8px;
    }

    .aboutSectionContainer .aboutSectionItem .imageContainer::after{
        border-radius: 8px;
    }

    .aboutSectionContainer .aboutSectionItem img{
        height: 304px;
        width: 304px;
        border-radius: 8px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
        margin: 0 8px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail .aboutSectionItemHead{
        font-size: 24px;
        line-height: 140%;
        width: 100%;
        margin-bottom: 12px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail .aboutSectionItemContent{
        font-size: 12px;
        line-height: 175%;
    }
}

@media screen and (max-width: 700px){
    .aboutSectionContainer{
        padding: 0 28px;
    }

    .aboutSectionContainer .aboutSectionItem{
        flex-direction: column;
        margin: 40px 20px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
        margin: 0;
    }

    .aboutSectionContainer .aboutSectionItem img{
        width: 100%;
        height: 100%;
    }

    .aboutSectionContainer .aboutSectionItem .imageContainer{
        margin-bottom: 24px;
    }

    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 450px){
    .aboutSectionContainer .aboutSectionItem .aboutSectionItemDetail{
        width: 100%;
    }
}

@media screen and (max-width: 300px){
    .aboutSectionContainer .aboutSectionItem img{
        width: 100%;
        height: 100%;
    }
}