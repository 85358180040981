.allNewsContainer{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: fit-content;
    margin: 4em auto 0;
    grid-column-gap: 1em;
    grid-row-gap: 2.5em;
}

.allNewsLoadMore{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    color: var(--whiteBlueTint);
    background-color: var(--bluePrimaryLight);
    padding: 16px 32px;
    border-radius: 16px;
    width: fit-content;
    margin: 40px auto 0;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.2s;
}

.noNewsFound img{
    width: 464px;
    margin: 94px auto 40px;
}

.noNewsFound .noNewsFoundMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.noNewsFound .bacKToHomeBtn{
    padding: 16px 32px;
    white-space: nowrap;
    border-radius: 16px;
    background-color: var(--bluePrimaryLight);
    width: fit-content;
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5em;
    color: var(--whiteBlueTint);
    transition: all 0.2s;
}



@media (hover:hover){
    .allNewsLoadMore:hover{
        background-color: var(--blueSecondaryDark);
    }

    .noNewsFound .bacKToHomeBtn:hover{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }
}



@media screen and (max-width: 1000px){
    .allNewsContainer{
        grid-template-columns: 1fr 1fr;
        margin-top: 48px;
        grid-row-gap: 1em;
    }

    .allNewsLoadMore{
        margin-top: 40px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
        padding: 8px 17px;
    }
}

@media screen and (max-width: 900px){
    .noNewsFound img{
        margin-top: 48px;
    }

    .noNewsFound .noNewsFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
    }

    .noNewsFound .bacKToHomeBtn{
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 12px;
    }
}

@media screen and (max-width: 650px){
    .allNewsContainer{
        grid-template-columns: 1fr;
        margin-top: 40px;
        grid-row-gap: 24px;
    }

    .noNewsFound img{
        width: 232px;
    }
}

@media screen and (max-width: 400px){
    .noNewsFound img{
        margin-top: 40px;
        width: 80%;
    }
    .noNewsFound .noNewsFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
        padding: 0 10px;
    }
}