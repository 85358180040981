.service-info-container{
    font-family: 'Poppins';
}
.service-main-img{
    width: 90%;
    margin: auto;
    height: 350px;
}
.service-main-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.service-main-info{
    padding:  25px;
    font-weight: 700;
font-size: 30px;
}

