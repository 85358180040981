.recomBlogContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin: 0 0 6em;
}

.recomBlogContainer .recomBlogHeadline{
    font-size: 2.25em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.recomBlogContainer .recomBlogs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2em auto 0;
    width: fit-content;
    grid-column-gap: 1em;
}



@media screen and (max-width: 1000px){
    .recomBlogContainer{
        margin-bottom: 48px;
    }
    
    .recomBlogContainer .recomBlogs{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }

    .recomBlogContainer .recomBlogHeadline{
        font-size: 24px;
        line-height: 140%;
    }
}


@media screen and (max-width: 650px){
    .recomBlogContainer{
        padding: 0 28px;
        margin: 40px 0;
    }

    .recomBlogContainer .recomBlogs{
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
    }

}