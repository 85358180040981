.pricingContainer{
    font-family: 'Poppins', sans-serif;
    width: fit-content;
    margin: 4em auto 0;
    color: var(--blackCharcol);
    display: flex;
}

.pricingContainer .entityTypeOptionContainer{
    background-color: var(--lightBlueBg);
    width: 19em;
    border-radius: 1.25em;
    padding: 1.5em 1.125em;
    text-align: left;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptionHead{
    font-size: 1.125em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-bottom: 1em;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label input{
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem{
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1.125em;
    border-radius: 0.75em;
    transition: all 0.1s;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem .entityTypeOptionItemUser{
    font-size: 1em;
    font-weight: 500;
    line-height: 175%;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem .entityTypeOptionItemPrice{
    font-size: 18px;
    line-height: 175%;
    opacity: 0;
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem.entityTypeOptionSelected{
    background-color: var(--bluePrimaryLight);
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem.entityTypeOptionSelected .entityTypeOptionItemUser{
    color: var(--lightBlueBg);
}

.pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem.entityTypeOptionSelected .entityTypeOptionItemPrice{
    color: white;
    opacity: 1;
}

.pricingContainer .entityTypeOptionContainer .userDropDown{
    display: none;
}

.pricingContainer .entityTypeOptionContainer .userSelectDropDown .entityTypeOptionsItem{
    display: flex;
    width: 75%;
    justify-content: space-between;
}




.pricingContainer + a .getStartedButton{
    font-size: 1em;
    padding: 1em 2em;
    font-weight: 600;
    line-height: 1.5em;
    background: var(--bluePrimaryLight);
    font-family: 'Poppins', sans-serif;
    border-radius: 1em;
    width: fit-content;
    margin: 1.5em auto 0;
    transition: all 0.2s;
}

.pricingContainer + a{
    text-decoration: none;
    color: var(--whiteBlueTint);
}


.pricingContainer .includedModules{
    padding: 1.5em;
    border: 1px solid #C4C4C4;
    border-radius: 1.25em;
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    width: 685px;
}

.pricingContainer .includedModules .includedModulesHead{
    font-size: 1.125em;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 2.4em;
}

.pricingContainer .includedModules .includedModulesContainer{
    display: flex;
    text-align: left;
    height: fit-content;
    margin: auto 0;
}

.pricingContainer .includedModules .includedModulesContainer .left,
.pricingContainer .includedModules .includedModulesContainer .right{
    margin: -1.5em 0;
    flex: 1;
}

.pricingContainer .includedModules .includedModulesContainer .left{
    margin-right: 4.75em;
}


.pricingContainer .includedModules .includedModulesContainer .includedModulesItem{
    display: flex;
    align-items: center;
    margin: 1.5em 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    white-space: nowrap;
}

.pricingContainer .includedModules .includedModulesContainer .includedModulesItem img{
    margin-right: 1em;
}


.pricingContainer .bottomPrices{
    display: none;
}




@media (hover:hover){

    .pricingContainer + a:hover .getStartedButton{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }

    .pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem:hover{
        background-color: white;
        cursor: pointer;
    }

    .pricingContainer .entityTypeOptionContainer .entityTypeOptions label .entityTypeOptionsItem.entityTypeOptionSelected:hover{
        background-color: var(--bluePrimaryLight);
    }

}


@media screen and (max-width: 1100px){
    .pricingContainer{
        flex-direction: column;
        margin: 48px 84px 0;
        width: inherit;
    }

    .pricingContainer .entityTypeOptionContainer{
        width: inherit;
        padding: 24px;
        margin-bottom: 24px;
        border-radius: 12px;
    }

    .pricingContainer .entityTypeOptionContainer .entityTypeOptionHead{
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 16px;
    }

    .pricingContainer .entityTypeOptionContainer .entityTypeOptions{
        display: none;
    }
    

    .pricingContainer .entityTypeOptionContainer .userDropDown{
        position: relative;
        display: block;
    }


    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownBtn{
        font-weight: 500;
        font-size: 12px;
        line-height: 175%;
        color: var(--lightBlueBg);
        background-color: var(--bluePrimaryLight);
        padding: 12px 18px;
        border-radius: 8px;
        width: inherit;
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
    
    .pricingContainer .entityTypeOptionContainer .userDropDown:hover .dropDownBtn img{
        transition: all 0.1s;
    }
    
    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownOptions{
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 175%;
        margin-top: 8px;
        max-height: 0;
        overflow: hidden;
        text-align: left;
        width: calc(100% - 16px);
        background-color: white;
        transition: all 0.1s;
        border-radius: 8px;
        padding: 0 8px;
        box-shadow: 0 0 2px 0 rgb(197, 197, 197);
    }

    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownOptions .dropDownOptionItem{
        padding: 5px 10px;
        border-radius: 8px;
    }
    
    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownOptions.openDropDown{
        padding: 8px 8px;
        max-height: 20em;
    }
    
    .pricingContainer .entityTypeOptionContainer .userDropDown .dropDownOptions .selectedOption{
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
    }

    
    .pricingContainer .includedModules {
        margin-left: 0;
        width: inherit;
    }

    .pricingContainer .includedModules .includedModulesContainer .includedModulesItem{
        white-space: initial;
    }

    .pricingContainer + a{
        margin: 0 auto 48px;
        display: inherit;
    }

    .pricingContainer + a .getStartedButton{
        margin: 0 auto;
        padding: 8px 13.5px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
    }

    .pricingContainer .bottomPrices{
        display: initial;
        color: var(--bluePrimaryLight);
        font-size: 18px;
        line-height: 175%;
        margin: 48px 0 8px;
    }
}

@media screen and (max-width: 600px){
    .pricingContainer{
        margin: 40px 28px 0;
    }

    .pricingContainer .entityTypeOptionContainer{
        padding: 16px;
        margin-bottom: 40px;
    }

    .pricingContainer .includedModules{
        border-radius: 12px;
        padding: 16px;
    }

    .pricingContainer .includedModules .includedModulesContainer{
        flex-direction: column;
    }

    .pricingContainer .includedModules .includedModulesContainer .left{
        margin: 0;
        margin-bottom: 8px;
    }

    .pricingContainer .includedModules .includedModulesContainer .right{
        margin-bottom: -14px;
    }

    .pricingContainer .bottomPrices{
        margin: 40px 0 8px;
    }

    .pricingContainer + a{
        margin: 0 auto 40px;
    }
}


@media screen and (max-width: 400px){
    .pricingContainer .includedModules .includedModulesHead{
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 25px;
    }

    .pricingContainer .includedModules .includedModulesContainer .includedModulesItem{
        font-size: 12px;
        line-height: 18px;
        margin: 14px 0;
    }

    .pricingContainer .includedModules .includedModulesContainer .includedModulesItem img{
        height: 16px;
        width: 16px;
    }

}