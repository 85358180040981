.service-key-pionts-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
    margin: 50px 0;
}

.service-key-pionts-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 20px;
}

.service-key-pionts-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}

.service-key-pionts-container ul{
    font-weight: 600;
font-size: 18px;
display: flex;
flex-wrap: wrap;
padding-left: 20px;
}
.service-key-pionts-container ul li{
    color: #202124;
    padding: 15px 0;
    width: 50%;
    text-align: left;
}