.visMisMain{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    display: grid;
    grid-template-columns: repeat(2,463px);
    grid-gap: 1em;
    margin: 0 auto;
    justify-content: center;
    text-align: left;
    width: fit-content;
}

.visMisMain .visMisItem{
    background-color: var(--lightBlueBg);
    padding: 2.5em 1.5em;
    border-radius: 1.25em;
}

.visMisMain .visMisItem img {
    margin-bottom: 1em;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
}

.visMisMain .visMisItem .visMisTitle{
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: var(--bluePrimaryLight);
}

.visMisMain .visMisItem .visMisContent{
    font-weight: 500;
    font-size: 1em;
    line-height: 150%;
    text-transform: capitalize;
    margin-top: 1em;
}


@media screen and (max-width: 1000px){

    .visMisMain{
        grid-template-columns: 1fr 1fr;
        margin: 0 20px;
    }

    .visMisMain .visMisItem{
        padding: 16px;
        border-radius: 12px;
    }

    .visMisMain .visMisItem img{
        margin-bottom: 0;
    }

    .visMisMain .visMisItem .visMisTitle{
        margin: 16px 0 12px;
        font-size: 14px;
        line-height: 150%;
    }

    .visMisMain .visMisItem .visMisContent{
        font-size: 12px;
        line-height: 150%;
    }
}

@media screen and (max-width: 900px){
    .visMisMain{
        margin: 0 84px 48px;
    }
}

@media screen and (max-width: 900px){
    .visMisMain{
        margin: 0 84px 48px;
    }
}

@media screen and (max-width: 700px){
    .visMisMain{
        margin: 0 48px 48px;
    }
}

@media screen and (max-width: 500px){
    .visMisMain{
        margin-top: 0;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
    }
}