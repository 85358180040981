.allCareerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.allCareerContainer .allCareerHead{
    font-size: 3em;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    white-space: nowrap;
}

.allCareerContainer .allCareerSubHead{
    color: var(--gray3);
    font-size: 1.5em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    font-weight: 500;
    margin-top: 8px;
}

.allCareerContainer .allCareers{
    width: 41%;
    margin: 4em auto 6em;
}

.allCareerContainer .allCareers .careerCard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E5E7EE;
    padding: 1.44em 1.5em 1.44em 2em;
    border-radius: 0.75em;
    margin: 1.5em 0;
}

.allCareerContainer .allCareers .careerCard .careerCardTitle{
    color: black;
    font-size: 1.125em;
    line-height: 140%;
    font-weight: 600;
    text-align: left;
}

.allCareerContainer .allCareers .careerCard .careerCardMoreBtn{
    color: var(--whiteBlueTint);
    font-size: 0.875em;
    line-height: 1.5em;
    font-weight: 500;
    background-color: var(--bluePrimaryLight);
    padding: 11px 19px;
    border-radius: 12px;
    white-space: nowrap;
}

.allCareerContainer .allCareers .careerCard a{
    text-decoration: none;
    color: var(--whiteBlueTint);;
}

.noJobFound img{
    width: 464px;
    margin: 32px auto 40px;
}

.noJobFound .noJobFoundMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.noJobFound .bacKToHomeBtn{
    padding: 16px 32px;
    white-space: nowrap;
    border-radius: 16px;
    background-color: var(--bluePrimaryLight);
    width: fit-content;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5em;
    color: var(--whiteBlueTint);
    transition: all 0.2s;
}


@media (hover:hover){
    .noJobFound .bacKToHomeBtn:hover{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }
}


@media screen and (max-width: 900px){
    .allCareerContainer {
        padding: 48px 84px 0;
        margin-bottom: 48px;
        margin-top: 5em;
    }

    .allCareerContainer .allCareers{
        margin-bottom: 0;
        width: inherit;
    }

    .noJobFound img{
        margin-top: 0;
    }
    
    .noJobFound .noJobFoundMsg{
        font-size: 18px;
        margin-bottom: 32px;
    }

    .noJobFound .bacKToHomeBtn{
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 12px;
    }
}

@media screen and (max-width: 500px){
    .allCareerContainer{
        padding: 40px 28px 0;
        margin-bottom: 40px;
    }

    .allCareerContainer .allCareerHead{
        font-size: 24px;
        line-height: 36px;
    }

    .allCareerContainer .allCareerSubHead{
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    .allCareerContainer .allCareers{
        margin-top: 53px;
    }

    .allCareerContainer .allCareers .careerCard{
        padding: 16px;
        margin: 16px 0;
    }

    .allCareerContainer .allCareers .careerCard .careerCardTitle{
        font-size: 12px;
        line-height: 140%;
    }

    .allCareerContainer .allCareers .careerCard .careerCardMoreBtn{
        padding: 8px 14px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 18px;
    }

    .noJobFound img{
        width: 100%;
    }
}