.blogNavbarContainer{
    display: flex;
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    background-color: var(--lightBlueBg);
    margin-top: 140px;
    padding: 16px 0;
}

.blogNavbarContainer .navbarWrapper{
    width: 944px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}

.blogNavbarContainer .searchBar {
    border: 1px solid var(--bluePrimaryLight);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 25%;
}

.blogNavbarContainer .searchBar input{
    border: none;
    outline: none;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
    background-color: var(--lightBlueBg);
    margin-left: 1em;
    width: 90%;
}

.blogNavbarContainer .searchBar input::placeholder{
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    line-height: 175%;
    color: var(--bluePrimaryLight);
}


.blogNavbarContainer .rightOps{
    display: flex;
    margin: 0 -10px;
}


.blogNavbarContainer .rightOps .dropDown{
    position: relative;
    display: block;
}


.blogNavbarContainer .rightOps .dropDown .dropDownBtn{
    font-weight: 500;
    font-size: 12px;
    line-height: 175%;
    color: var(--lightBlueBg);
    margin: 0 10px;
    border-radius: 8px;
    width: inherit;
    justify-content: space-between;
    display: flex;
    align-items: center;
}


.blogNavbarContainer .rightOps .dropDown .dropDownOptions{
    position: absolute;
    font-weight: 600;
    font-size: 12px;
    line-height: 175%;
    margin-top: 8px;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    width: fit-content;
    background-color: white;
    transition: all 0.1s;
    border-radius: 8px;
    padding: 0 8px;
    right: 0;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.blogNavbarContainer .rightOps .dropDown .dropDownOptions .dropDownOptionItem{
    padding: 5px 10px;
    border-radius: 8px;
}

.blogNavbarContainer .rightOps .dropDown .dropDownOptions.openDropDown{
    padding: 8px 8px;
    max-height: 20em;
}

.blogNavbarContainer .rightOps .dropDown .dropDownOptions .selectedOption{
    color: var(--bluePrimaryLight);
    background-color: var(--lightBlueBg);
}




@media (hover:hover){

    .blogNavbarContainer .rightOps .dropDown:hover{
        cursor: pointer;
    }
    
    .blogNavbarContainer .rightOps .dropDown .dropDownOptions .dropDownOptionItem:hover{
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
    }

}

.tabletSearchBlogWrapper{
    display: none;
}


@media screen and (max-width: 1000px){

    .blogNavbarContainer .navbarWrapper{
        width: 624px;
    }

    .blogNavbarContainer .searchBar {
        border: none;
        padding: 0;
        width: fit-content;
    }

    .blogNavbarContainer .searchBar input{
        display: none;
    }

    .tabletSearchBlogWrapper{
        display: block;
        margin: 0 auto;
        width: calc(624px - 20px);;
    }

    input.tabletBlogSearch{
        border: none;
        outline: none;
        display: block;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
        background-color: var(--lightBlueBg);
        width: calc(100% - 18px);
        border-radius: 8px;
        padding: 0 10px;
        max-height: 0;
        transition: all 0.2s;
    }
    
    input.tabletBlogSearch::placeholder{
        font-family: 'Poppins', sans-serif;
        font-size: 0.875em;
        line-height: 175%;
        color: var(--bluePrimaryLight);
    }

    input.tabletBlogSearch.showTabletBlogSearchbar{
        max-height: 5em;
        padding: 6px 10px;
        margin-top: 10px;
    }

}


@media screen and (max-width: 900px){
    .blogNavbarContainer{
        margin-top: 5em;
    }
}


@media screen and (max-width: 650px){
    .blogNavbarContainer{
        padding: 16px 0;
    }

    .blogNavbarContainer .navbarWrapper{
        width: 304px;
    }

    .tabletSearchBlogWrapper{
        width: calc(304px - 20px);
    }
}

@media screen and (max-width: 650px){
    .blogNavbarContainer{
        padding: 16px 28px;
    }

    .blogNavbarContainer .navbarWrapper{
        width: 304px;
    }

    .tabletSearchBlogWrapper{
        width: inherit;
        margin: 0 28px;
    }
}
