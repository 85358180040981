.usersContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 50px 0;
    background-color: var(--lightBlueBg);

}

.usersContainer .usersHeadline{
    font-weight: 700;
    font-size: 2.25em;
    line-height: 140%;
    letter-spacing: -0.02em;
}

.usersContainer .usersMain{
    display: grid;
    grid-template-columns: repeat(3,303px);
    grid-column-gap: 1.125em;
    grid-row-gap: 24px;
    justify-content: center;
    margin-top: 2em;
}

.usersContainer .usersMain .usersItem{
    padding: 1.9em 3.375em 2.2em;
    border-radius: 0.75em;
}

.usersContainer .usersMain .usersItem:last-of-type{
    grid-column: 2/3;
}

.usersContainer .usersMain .usersItem .usersTitle{
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-top: 12px;
}
.user-img{
    width: 100px;
    margin: auto;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1000px){
    .usersContainer{
        padding: 0 84px;
    }

    .usersContainer .usersMain{
        grid-template-columns: repeat(2,50%);
        margin: 26px 0 48px;
        grid-column-gap: 16px;
    }

    .usersContainer .usersMain .usersItem{
        padding: 16px 0 ;
    }

    .usersContainer .usersMain .usersItem:last-of-type{
        grid-column: 1/2;
    }

    .usersContainer .usersMain .usersItem img{
        height: 45px;
        width: 45px;
    }
}

@media screen and (max-width: 600px){   

    .usersContainer{
        padding: 0 28px 40px;
    }

    .usersContainer .usersHeadline{
        font-size: 24px;
        line-height: 140%;
    }

    .usersContainer .usersMain{
        grid-template-columns: repeat(2,1fr);
        grid-row-gap: 24px;
        margin: 12px 0 0;
    }

}