.TimberCalcContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
}

.TimberCalcContainer .TimberCalcHead{
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.02em;
}

.TimberCalcContainer .TimberCalcMain{
    display: flex;
    width: fit-content;
    margin: 46px auto 0;
    border: 1.5px solid var(--bluePrimaryLight);
    border-radius: 20px;
    width: 68%;
    position: relative;
}

.TimberCalcContainer .TimberCalcMain .disableInput{
    background-color: var(--lightBlueBg);   
}

.TimberCalcContainer .TimberCalcMain > img{
    padding: 0px;
    border-radius: 50%;
    height: fit-content;
    width: fit-content;
    border: 1.5px solid var(--bluePrimaryLight);
    position: absolute;
    left: calc(50% - 19.5px);
    top: calc(50% - 19.5px);
    background-color: white;
}

.TimberCalcContainer .TimberCalcMain img.changeDir{
    transform: rotate(180deg);
}

.TimberCalcContainer .TimberCalcMain .calcLeft,
.TimberCalcContainer .TimberCalcMain .calcRight{
    padding: 24px 0 38px 36px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items:baseline ;
    margin: 0 0;
}

.TimberCalcContainer .TimberCalcMain .calcSeperator{
    height: calc(100% + 3px);
    width: 0.5px;
    background: var(--bluePrimaryLight);
    top: -1.5px;
    bottom: 0;
    position: absolute;
    left: 50%;
}

.TimberCalcContainer .TimberCalcMain .calcLeft{
    border-radius: 20px 0 0 20px;
}

.TimberCalcContainer .TimberCalcMain .calcRight{
    border-radius: 0 20px 20px 0;
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDirection{
    font-weight: 600;
    font-size: 24px;
    line-height: 175%;
    color: var(--gray4);
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownBtn{
    font-weight: 600;
    font-size: 32px;
    line-height: 175%;
    color: var(--gray2);
    margin: 11px 0 0;
    width: 107px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown:hover .dropDownBtn img{
    transition: all 0.1s;
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions{
    position: absolute;
    font-weight: 400;
    font-size: 24px;
    line-height: 175%;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    background-color: white;
    transition: all 0.1s;
    border-radius: 12px;
    padding: 0 24px;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions.openDropDown{
    padding: 16px 24px;
    font-weight: 400;
    max-height: 15em;
}

.TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions .selectedOption{
    color: var(--bluePrimaryLight);
}


@media (hover:hover){
    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownBtn:hover{
        cursor: pointer;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions .dropDownOptionItem:hover{
        color: var(--bluePrimaryLight);
        cursor: pointer;
    }
}


.TimberCalcContainer .TimberCalcMain .calcSide input::-webkit-outer-spin-button,
.TimberCalcContainer .TimberCalcMain .calcSide input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.TimberCalcContainer .TimberCalcMain .calcSide input[type=number] {
  -moz-appearance: textfield;
}

.TimberCalcContainer .TimberCalcMain .calcSide input{
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    color: var(--bluePrimaryLight);
    width: 50%;
    border: none;
    outline: none;
    margin-top: 36px;
}

.TimberCalcContainer .TimberCalcMain .calcSide.disableInput input{
    background-color: var(--lightBlueBg);
}




@media screen and (max-width: 900px){
    .TimberCalcContainer{
        margin: 0 84px 48px;
    }

    .TimberCalcContainer .TimberCalcMain{
        width: inherit;
        border-radius: 12px;
    }

    .TimberCalcContainer .TimberCalcMain .calcLeft,
    .TimberCalcContainer .TimberCalcMain .calcRight{
        padding-bottom: 24px;
    }

    .TimberCalcContainer .TimberCalcMain .calcLeft{
        border-radius: 12px 0 0 12px;
    }
    
    .TimberCalcContainer .TimberCalcMain .calcRight{
        border-radius: 0 12px 12px 0;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDirection{
        font-size: 16px;
        line-height: 175%;
    }

    .TimberCalcContainer .TimberCalcMain > img{
        top: calc(50% - 13.5px);
        left: calc(50% - 13.5px);
        height: 24px;
        width: 24px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownBtn{
        font-size: 24px;
        line-height: 175%;
        margin-top: 8px;
        width: 78px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownBtn img{
        height: 24px;
        width: 24px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide .calcDropDown .dropDownOptions{
        font-size: 16px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide input{
        font-size: 36px;
        line-height: 54px;
        margin-top: 24px;
    }

    .TimberCalcContainer .TimberCalcMain .calcLeft,
    .TimberCalcContainer .TimberCalcMain .calcRight{
        margin: 0;
    }
}


@media screen and (max-width: 550px){
    .TimberCalcContainer{
        margin: 0 28px 40px;
    }

    .TimberCalcContainer .TimberCalcHead{
        font-size: 24px;
        line-height: 36px;
    }
    
    .TimberCalcContainer .TimberCalcMain{
        margin-top: 24px;
        flex-direction: column;
    }

    .TimberCalcContainer .TimberCalcMain > img{
        top: calc(50% - 13.5px);
        transform: rotate(90deg);
    }

    .TimberCalcContainer .TimberCalcMain img.changeDir{
        transform: rotate(270deg);
    }

    .TimberCalcContainer .TimberCalcMain .calcLeft,
    .TimberCalcContainer .TimberCalcMain .calcRight{
        padding: 16px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSeperator{
        width: calc(100% + 3px);
        height: 0.5px;
        background: var(--bluePrimaryLight);
        top: 50%;
        bottom: 0;
        position: absolute;
        left: -1.5px;
    }

    .TimberCalcContainer .TimberCalcMain .calcSide.calcLeft{
        border-radius: 12px 12px 0 0;
    }
    
    .TimberCalcContainer .TimberCalcMain .calcRight{
        border-radius: 0 0 12px 12px;
    }

}