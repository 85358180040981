.product-about-container {
    /* margin-top: 8em; */
    font-family: 'Poppins', sans-serif;
    margin: 30px 0;
}

.product-about-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 20px;
}

.product-about-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}
.product-about-container .product-details{
    margin: 50px 0 0;
}
.product-about-container .product-details .main-header{
display: inline-flex;
}
.about-product-tags-area{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}
.about-product-tags-area .about-product-tag{
    border-radius: 8px;
    background-color: var(--lightBlueBg);
    color: var(--bluePrimaryLight);
    padding: 12px 20px;
    cursor: pointer;
}

.product-info-container{
    font-family: 'Poppins';
}
.product-main-img{
    width: 90%;
    margin: auto;
    height: 350px;
}
.product-main-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.product-main-info{
    padding:  25px;
    font-weight: 700;
font-size: 30px;
}

.get-quot-section{
    padding: 50px;
    background-color: var(--bluePrimaryLight);
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    margin: 50px 0;
}
.quot-btn{
    margin-top: 20px;
}

.quot-btn a{
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

}
.quot-btn a div{
    height: 24px;
    padding: 0 10px;
}
/* More product */
.product-more-container{
    font-family: 'Poppins';
    margin: 50px 0;
}

.product-more-container .main-header {
    font-weight: 600;
    font-size: 30px;
    color: var(--bluePrimaryLight);
    position: relative;
    text-align: left;
    margin-bottom: 25px;
}


.product-more-container .main-header:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 30px;
    height: 5px;
    background-color: var(--bluePrimaryLight);
   

}
.more-product-card{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
.product-img{
    width: 100%;
    height: 100%;
}
.product-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}


.product-title{
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    color: #fff;
    position: absolute;
    bottom: -20px;
    /* width: 100%; */
    /* padding: 15px; */
    opacity: 0;
    transition: 0.5s;
    z-index: 2;
    left: 15px;
    right: 15px;
}
.more-product-card:hover .product-title{

    bottom: 0;
    opacity: 1;
}

.more-product-card::after{
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background-color: #00000088;
   z-index: 1;
   opacity: 0;
   transform: 0.5s;
}
.more-product-card:hover.more-product-card::after{
    opacity: 1;
}
.mt-5{
    margin-top: 1.5rem;
}
.px-2{
    padding: 0 1rem;
}
.about-product-tag.active {
    background-color: var(--bluePrimaryLight);

    color: #fff;
}