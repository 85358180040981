.navbarContainer {
    height: 70px;
    /* align-items: center; */
    padding-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    z-index: 20;
    background-color: white;
    position: relative;
}

.navbarContainer .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

.navbarContainer::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 65px;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: url(images/navbar.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 0.3;
}

.stickyNavbar {
    position: fixed;
    top: 0;
    width: 100%;
}

.navbarContainer .hamburgerMenu {
    display: none;
}

.navbarContainer .navbarLogo {
    margin-left: 3em;
}

.navbarContainer .navbarLogo img {
    height: 60px;
    flex: 0 1;
    margin-top: 12px;
}

.navbarContainer .navbarOptions {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-right: 3em;

}

.navbarContainer .navbarOptions .navbarLinks {
    display: flex;
    align-items: center;
    color: var(--blackCharcol);
    margin-right: 1.5em;
    padding: 0 -1em;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems {
    /* margin: 0 1em; */
    line-height: 1.5em;
    width: 114px;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems a {
    text-decoration: none;
    padding: 7px 5px;
    color: var(--blackCharcol);
    position: relative;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems a:hover {
    background-color: #fff;
    border-radius: 8px;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems:hover.navbarLinkItems>a::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    left: 7px;
    bottom: 0;
    background-color: var(--bluePrimaryLight);
}

.navbarContainer .navbarOptions .navbarLinks .dropDownItem a::after {
    content: '';
    width: 0;
    transition: 0.5s;
}

.navbarContainer .navbarOptions .navbarLinks .dropDownItem:hover.dropDownItem>a::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 3px;
    left: 0px;
    top: 20px;
    background-color: var(--bluePrimaryLight);
}

.navbarContainer .navbarOptions .navbarLinks .dropDownItem a {
    transition: 0.5s;
}

.navbarContainer .navbarOptions .navbarLinks .dropDownItem:hover.dropDownItem>a {
    padding-left: 20px;
}




.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.selected,
.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.selected a {
    color: var(--bluePrimaryLight);
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems .dropDownBtn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownBtn .caretDownLogo {
    margin-left: 5px;
    transition: all 0.5s;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    margin-top: 3.5em;
    background-color: white;
    transition: all 0.5s;
    border-radius: 0.75em;
    padding: 0 0 0 1.5em;
    width: 8em;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems .dropDownBtn .rotateArrow {
    transform: rotate(180deg);
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .showDropDownList {
    max-height: 10em;
    padding: 0.5em 0 0.5em 1.5em;
    box-shadow: 0 0 2px 0 rgb(197, 197, 197);
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList .dropDownItem {
    margin: 0.5em 0;
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList .dropDownItem a {
    color: var(--blackCharcol);
}

.navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList .dropDownItem.selected a {
    color: var(--bluePrimaryLight);
}

.navbarContainer .navbarOptions .navbarGetStarted a {
    color: white;
    background-color: var(--bluePrimaryLight);
    padding: 0.7em 1.2em;
    border-radius: 0.75em;
    text-decoration: none;
    font-size: 0.875em;
    line-height: 1.5em;
    height: 2.7em;
    white-space: nowrap;
    transition: all 0.2s;
}


.scrollToTop {
    transform: rotate(270deg);
    height: 50px;
    width: 50px;
    border-radius: 8px;
    /* border-color: black; */
    background-color: var(--whiteBlueTint);
    color: var(--bluePrimaryLight);
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 24px;
    left: 24px;
    transition: all 0.2s;
    z-index: 19;
}

.scrollToTop.showScrollToTop {
    display: flex;
    opacity: 0.8;
    box-shadow: 0 0 2px 0 var(--gray3);
}

.navbarLinkItems.arrow-nav{
    /* position: relative;
    width: 120px;
    height: 70px; */
/*     
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
overflow: hidden; */
} 
/* .circuler-menu{
    position: absolute;
    top: 0px;
   
    width: 100%;
    transition: 0.5s;
} */
.circuler-menu-item {
    width: 100%;
    /* transform: scale(0);
    transition:  0.5s; */
    display: none;
    white-space: nowrap;
}

.circuler-menu-item.active {
    /* transform: scale(1); */
display: block;
}
.up-arrow,.down-arrow{
    position: relative;
    z-index: 99;
}
.cursor {
    cursor: pointer;
}


@media (hover:hover) {
    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown:hover {
        cursor: pointer;
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown:hover .dropDownBtn .caretDownLogo {
        transform: rotate(180deg);
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown:hover .dropDownList {
        max-height: 10em;
        padding: 0.5em 0 0.5em 1.5em;
        box-shadow: 0 0 2px 0 rgb(197, 197, 197);
    }

    .navbarContainer .navbarOptions .navbarLinks a:hover,
    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList .dropDownItem a:hover {
        color: var(--bluePrimaryLight);
    }

    .navbarContainer .navbarOptions .navbarGetStarted a:hover {
        background-color: var(--blueSecondaryDark);
    }

    .scrollToTop:hover {
        opacity: 1;
        cursor: pointer;
        box-shadow: 0 0 2px 0 var(--gray3);
    }
}




@media screen and (max-width: 900px) {

    .navbarContainer {
        height: 5em;
        justify-content: space-between;
    }

    .navbarContainer .navbarLogo {
        display: block;
        margin-left: 10.6vw;
    }

    .navbarContainer .navbarLogo a {
        display: inline-block;
        height: fit-content;
    }

    .navbarContainer .navbarLogo a img {
        vertical-align: middle;
        height: 50px;
        /* width: 144px; */
    }

    .navbarContainer .hamburgerMenu {
        display: inline-block;
        margin-right: 10.1vw;
    }

    .navbarContainer .hamburgerMenu img {
        display: block;
    }

    .navbarContainer .navbarOptions {
        flex-direction: column;
        position: absolute;
        width: 12.5em;
        height: 90vh;
        right: -12.5em;
        top: 5em;
        background-color: white;
        align-items: center;
        transition: all 0.5s;
        margin-right: 0;
    }

    .navbarContainer .showNavbar {
        right: 0;
    }

    .navbarContainer .navbarOptions .navbarLinks {
        flex-direction: column;
        margin-right: 0;
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems {
        margin: 1em 0;
    }

    .navbarContainer .navbarOptions .navbarGetStarted {
        margin-top: 5em;
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems .dropDownBtn {
        justify-content: center;
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .dropDownList {
        position: inherit;
        margin-top: 0;
    }

    @media (hover:hover) {
        .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown:hover .dropDownList {
            margin-top: 1em;
        }
    }

    .navbarContainer .navbarOptions .navbarLinks .navbarLinkItems.dropDown .showDropDownList {
        margin-top: 1em;
    }

    .scrollToTop {
        opacity: 1;
    }

}


@media screen and (max-width: 600px) {
    .scrollToTop {
        height: 40px;
        width: 40px;
    }
}


@media screen and (max-width: 400px) {

    .navbarContainer .navbarLogo {
        margin-left: 28px;
    }

    .navbarContainer .hamburgerMenu {
        margin-right: 28px;
    }

    .navbarContainer .hamburgerMenu img {
        height: 24px;
        width: 24px;
    }

}

/* animating css */

@keyframes moving {
    from {bottom: -15px;}
    to {bottom: 0px;}
}

/* @keyframes previousMoving {
    from {top: 0px;}
    to {top: 15px;}
}
.navbarLinkItems.arrow-nav .circuler-menu-item {
    animation: previousMoving 1s;
    position: relative;
} */

.navbarLinkItems.arrow-nav .circuler-menu-item.active{
    animation: moving 0.8s;
    position: relative;
}


.navbarLinkItems.arrow-nav{
    
}