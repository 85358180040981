.get-quot-section{
    padding: 50px;
    background-color: var(--bluePrimaryLight);
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    margin: 50px 0;
}
.quot-btn{
    margin-top: 20px;
}