.notFoundCard{
    margin-top: 7em;
    color: var(--blackCharcol);
    font-family: 'Poppins', sans-serif;
    padding: 96px 0;
}

.notFoundCard .errCode{
    font-size: 48px;
    line-height: 120.5%;
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.notFoundCard .errImg img{
    display: inline-block;
    width: 362px;
    margin: 40px 0;
}

.notFoundCard .errMsg{
    margin: 0 auto 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

.notFoundCard .goHomeBtn{
    padding: 16px 32px;
    white-space: nowrap;
    border-radius: 16px;
    background-color: var(--bluePrimaryLight);
    width: fit-content;
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5em;
    color: var(--whiteBlueTint);
    transition: all 0.2s;
}


@media (hover:hover){
    .notFoundCard .goHomeBtn:hover{
        background-color: var(--blueSecondaryDark);
        cursor: pointer;
    }
}


@media screen and (max-width:  900px){
    .notFoundCard{
        margin-top: 5em;
        padding: 48px 0;
    }

    .notFoundCard .errMsg{
        margin-bottom: 48px;
        font-size: 18px;
        margin-bottom: 32px;
    }

    .notFoundCard .goHomeBtn{
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 12px;
    }
}

@media screen and (max-width:  600px){

    .notFoundCard .errImg img{
        width: 250px;
        margin: 20px auto;
    }

    .notFoundCard .errCode{
        font-size: 36px;
    }

    .notFoundCard .errMsg{
        font-size: 14px;
        padding: 0 10px;
    }
}

@media screen and (max-width:  350px){
    .notFoundCard .errImg img{
        width: 70%;
    }
}