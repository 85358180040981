.showCareerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    margin-top: 7em;
}

.showCareerContainer .showCareerHeader{
    background-color: var(--bluePrimaryLight);
    padding: 57px 0;
    color: var(--whiteBlueTint);
}

.showCareerContainer .showCareerHeader .showCareerSubHeader{
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}


.showCareerContainer .showCareerHeader .showCareerHeaderDetails{
    width: fit-content;
    position: relative;
    margin: auto;
    left: -20px;
}

.showCareerContainer .showCareerHeader .showCareerHeaderDetails .showCareerHeaderTitle{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    white-space: nowrap;
}

.showCareerContainer .showCareerHeader .showCareerHeaderDetails .showCareerHeaderLocation{
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.02em;
}

.showCareerContainer .showCareerHeader .showCareerHeaderDetails  .showCareerHeaderDate{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    margin-top: 12px;
}


.showCareerContainer .showCareerDescription{
    text-align: left;
    width: 41%;
    margin: 2.5em auto 0;
}

.showCareerContainer .showCareerDescription .showCareerDecs{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
}

.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecSubHead{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.02em;
    margin: 24px 0 12px;
    white-space: nowrap;
}

.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent{
    color: var(--gray3);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent > *{
    margin-top: 12px;
}

.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent ul,
.showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent ol{
    padding-left: 30px;
}

.showCareerContainer a {
    text-decoration: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--whiteBlueTint);
    white-space: nowrap;
    text-decoration: none;
}

.showCareerContainer .submitCVBtn{
    width: fit-content;
    margin: 4em auto 6em;
    background-color: var(--bluePrimaryLight);
    border-radius: 16px;
    padding: 16px 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.2s;
}




@media (hover:hover){
    .showCareerContainer .submitCVBtn:hover{
        background-color: var(--blueSecondaryDark);
    }
}



@media screen and (max-width: 900px){
    .showCareerContainer{
        margin-top: 5em;
    }

    .showCareerContainer .showCareerHeader{
        padding: 57px 84px;
    }

    .showCareerContainer .showCareerHeader .showCareerSubHeader{
        width: inherit;
    }

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails{
        width: inherit;
    }

    .showCareerContainer .showCareerDescription{
        width: inherit;
        margin: 48px 84px;
    }

    .showCareerContainer .submitCVBtn{
        margin: 0 auto 48px;
        font-size: 12px;
        line-height: 18px;
        padding: 8px 16.5px;
        border-radius: 8px;
    }
}

@media screen and (max-width: 550px){

    .showCareerContainer .showCareerHeader{
        padding: 40px 28px 76px;
    }
    
    .showCareerContainer .showCareerHeader .showCareerSubHeader{
        flex-direction: column;
    }

    .showCareerContainer .showCareerHeader .showCareerSubHeader img{
        display: block;
        height: 24px;
        width: 24px;
        margin-bottom: 12px;
    }

    .showCareerContainer .showCareerHeader .showCareerSubHeader a{
        justify-self: left;
        align-self: stretch;
    }

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails{
        position: initial;
    }

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails .showCareerHeaderTitle{
        font-size: 16px;
        line-height: 24px;
    } 

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails .showCareerHeaderLocation{
        font-size: 12px;
        line-height: 18px;
        margin: 12px 0 4px;
    }

    .showCareerContainer .showCareerHeader .showCareerHeaderDetails  .showCareerHeaderDate{
        font-size: 9px;
        line-height: 13px;
    }

    .showCareerContainer .showCareerDescription{
        margin: 40px 28px;
    }

    .showCareerContainer .showCareerDescription .showCareerDecs{
        font-size: 16px;
        line-height: 24px;
    }

    .showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecSubHead{
        font-size: 12px;
        line-height: 18px;
    }

    .showCareerContainer .showCareerDescription .showCareerDecSub .showCareerDecContent{
        font-size: 12px;
        line-height: 18px;
    }

    .showCareerContainer .submitCVBtn{
        margin: 0 auto 40px;
        border-radius: 6px;
    }
}

@media screen and (max-width: 400px){
    .showCareerContainer .submitCVBtn{
        margin: 0 28px 40px;
        width: inherit;
    }
}