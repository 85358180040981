.benefitsContainer{
    background-color: #F5F7FB;
    font-family: 'Poppins', sans-serif;
    color: var(--blackCharcol);
    padding: 96px 0;
    margin: 4.375em 0 96px;
}

.benefitsContainer .benefitItem{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    margin: 0 auto;
    flex-grow: 1;
}



.benefitsContainer .rowBenefitItem{
    flex-direction: row;
}

.benefitsContainer .rowReverseBenefitItem{
    flex-direction: row-reverse;
}


.benefitsContainer .benefitItem .benefitDetail{
    text-align: left;
    width: 100%;
    margin: 0 2em;
}

.benefitsContainer .benefitItem .benefitSvg{
    width: 100%;
    margin: 0 2em;
}

.benefitsContainer .benefitItem .benefitSvg img{
    width: 100%;
    /* max-height: 30vw; */
}

.benefitsContainer .benefitItem:nth-of-type(2) .benefitSvg img{
    max-height: 475px;
    min-height: 350px;
    height: 40vw;
}





.benefitsContainer .benefitItem .benefitHead{
    font-weight: 600;
    font-size: 2.25em;
    line-height: 140%;
    margin-bottom: 24px;
}

.benefitsContainer .benefitItem .benefitContent{
    font-weight: 500;
    font-size: 1em;
    line-height: 175%;
}

.benefitsContainer .benefitItem .benefitContent a{
    text-decoration: none;
    color: var(--bluePrimaryLight);
}




@media screen and (max-width: 1200px){

    .benefitsContainer .benefitItem .benefitSvg{
        flex: 0 1 auto;
    }


    .benefitsContainer .rowReverseBenefitItem .benefitDetail{
        margin: 3em 0;
    }
}


@media screen and (max-width: 900px){
    .benefitsContainer{
        margin-bottom: 0;
    }
}


@media screen and (max-width: 950px){
    .benefitsContainer{
        padding: 48px 11% 20px 10%;
    }

    .benefitsContainer .benefitItem{
        margin: 0 -8px;
        width: inherit;
    }

    .benefitsContainer .benefitItem .benefitDetail{
        flex: 1;
        width: 100%;
        margin-left: 0;
        margin: 0 8px;
    }

    .benefitsContainer .benefitItem .benefitHead{
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 12px;
    }

    .benefitsContainer .benefitItem .benefitContent{
        font-size: 12px;
        line-height: 175%;
    }

    .benefitsContainer .benefitItem .benefitSvg{
        flex: 1;
        margin: 0 8px;
    }

    .benefitsContainer .benefitItem .benefitSvg img{
        width: 100%;
    }
}


@media screen and (max-width: 500px){
    .benefitsContainer{
        padding: 0 28px 40px;
        margin-bottom: 0;
    }

    .benefitsContainer .benefitItem{
        flex-direction: column-reverse;
    }

    .benefitsContainer .benefitItem .benefitSvg img{
        width: 100%;
        margin-top: 40px;
    }

    .benefitsContainer .benefitItem:nth-of-type(2) .benefitSvg img{
        max-height: 400px;
        margin-bottom: 1.5rem;
    }    

    .benefitsContainer .benefitItem .benefitDetail{
        margin-left: 0;
        width: inherit;
    }
}