.blogOverContainer{
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    border-radius: 1.25em;
    width: 19em;
}

.blogOverContainer a{
    text-decoration: none;
    color: var(--blackCharcol);
}

.blogOverContainer img{
    display: block;
    width: 100%;
    height: 16em;
}

.blogOverContainer .blogDetails{
    background-color: var(--lightBlueBg);
    padding: 1.5em;
    text-align: left;
    border-radius: 0 0 1.25em 1.25em;
}

.blogOverContainer .blogDetails .blogCommodity{
    font-size: 0.75em;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--bluePrimaryLight);
}

.blogOverContainer .blogDetails .blogTitle{
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.5em;
    margin: 8px 0;
}

.blogOverContainer .blogDetails .blogDate{
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: -0.02em;
    color: var(--gray3);
}

@media screen and (max-width: 350px){
    .blogOverContainer{
        width: auto;
        margin: 0 3.4vw;
    }

    .blogOverContainer .blogDetails{
        padding: 6.8vw;
    }

    .blogOverContainer .blogDetails .blogTitle{
        font-size: 5vw;
    }

    .blogOverContainer .blogDetails .blogDate,
    .blogOverContainer .blogDetails .blogCommodity{
        font-size: 4vw;
    }

    .blogOverContainer img{
        height: auto;
    }
}