.lightBlueBgAboutBannerContainer{
    font-family: 'Poppins', sans-serif;
    color: var(--bluePrimaryLight);
    background-color: var(--lightBlueBg);
    padding: 64px 0;
    margin: 96px 0;
}

.lightBlueBgAboutBannerContainer .lightBlueBgAboutBannerHead{
    width: 41%;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0 auto;
}



@media screen and (max-width: 900px){
    .lightBlueBgAboutBannerContainer{
        margin: 48px 0;
    }
}

@media screen and (max-width: 800px){
    .lightBlueBgAboutBannerContainer{
        padding: 48px 84px;
        margin-bottom: 48px;
    }
    
    .lightBlueBgAboutBannerContainer .lightBlueBgAboutBannerHead{
        width: 100%;
        font-size: 16px;
        line-height: 140%;
    }
}


@media screen and (max-width: 450px){
    .lightBlueBgAboutBannerContainer{
        padding: 40px 28px;
        margin: 40px 0;
    }
    .lightBlueBgAboutBannerContainer .lightBlueBgAboutBannerHead{
        font-size: 10px;
        line-height: 140%;
    }
}