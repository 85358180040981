 html {
     box-sizing: border-box;
 }

 .footerContainer {
     background-color: var(--bluePrimaryLight);
     padding: 25px 50px;
     font-family: 'Poppins', sans-serif;
 }

 .footerContainer .footerPILWhiteLogo {
     display: flex;
     justify-content: left;
     /* margin-left: -20px; */
 }

 .footerContainer .footerPILWhiteLogo img {
     /* width: 220px; */
     height: 52px;
 }



 .footerContainer hr {
     height: 1px;
     background-color: white;
     border: none;
     opacity: 0.1;
     margin: 2.5em auto;
     mix-blend-mode: normal;
 }



 .footerContainer .footerOptions {
     display: flex;
     justify-content: space-between;
 }

 .footerContainer .footerOptions .footerNewsLetter {
     color: white;
 }

 .footerContainer .footerOptions .footerNewsLetter hr {
     display: none;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterText {
     letter-spacing: -0.3px;
     width: 11em;
     text-align: left;
     font-weight: 400;
     font-size: 1.375em;
     line-height: 1.5em;
     margin-bottom: 1em;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent {
     display: flex;
     flex-direction: row;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input,
 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent textarea {
     height: 3.125em;
     width: 15em;
     background-color: transparent;
     color: white;
     font-family: 'Poppins', sans-serif;
     font-size: 1em;
     border: none;
     border-bottom: 1px solid rgba(255, 255, 255, 10%);
     margin-right: 0.75em;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input::placeholder,
 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent textarea::placeholder {
     color: white;
     opacity: 0.5;
     font-weight: 400;
     mix-blend-mode: normal;
     line-height: 1.4em;
     letter-spacing: -0.2px;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input:focus,
 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent textarea:focus {
     outline: none;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button {
     height: 3.75em;
     width: 3.75em;
     border-radius: 8px;
     background-color: var(--lightBlueBg);
     border: none;
 }

 .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button img {
     height: 10px;
     width: 6px;
 }

 .footerContainer .footerOptions .footerPagesOptions,
 .footerContainer .footerOptions .footerImportantOptions {
     text-align: left;
     margin: 0 10px;
 }

 .footerContainer .footerOptions .footerOptionsHeading {
     color: white;
     font-weight: 600;
     font-size: 1.25em;
     line-height: 1.5em;
     letter-spacing: -0.23px;
     margin-bottom: 0.6em;
 }

 .footerContainer .footerOptions .footerPagesLinks,
 .footerContainer .footerOptions .footerImportantLinks {
     display: flex;
     flex-direction: column;
     margin: -0.5em 0;
 }

 .footerContainer .footerOptions .footerPagesLinks a,
 .footerContainer .footerOptions .footerImportantLinks a {
     text-decoration: none;
     color: var(--whiteBlueTint);
     font-size: 1em;
     line-height: 1.375em;
     font-weight: 400;
     letter-spacing: -0.2px;
     margin: 0.5em 0;
 }

 .footerContainer .footerOptions .footerBottomTablet {
     display: none;
 }

 .footerContainer .footerOptions .footerContactOptions {
     text-align: left;
 }

 .footerContainer .footerOptions .footerContactOptions .footerContactOptionsItem {
     display: flex;
     align-items: flex-start;
     margin: 0.875em 0;
 }

 .footerContainer .footerOptions .footerContactOptions .footerContactOptionsItem img {
     margin-right: 0.75em;
 }

 .footerContainer .footerOptions .footerContactOptions .contactData {
     font-size: 0.875em;
     font-weight: 500;
     line-height: 150%;
     color: var(--whiteBlueTint);
     text-align: left;
     width: 14.5em;
 }

 .footerContainer .footerOptions .footerContactOptions .contactData a {
     text-decoration: none;
     color: var(--whiteBlueTint);
 }


 .footerContainer .footerBottom {
     display: flex;
     justify-content: space-between;
 }

 .footerContainer .footerBottom .footerImportantLinks {
     display: flex;
     justify-content: center;
     margin: 0 -1.25em;
 }

 .footerContainer .footerBottom .footerImportantLinks a {
     text-decoration: none;
     font-size: 1em;
     line-height: 1.25em;
     letter-spacing: -0.2px;
     font-weight: 400;
     color: var(--whiteBlueTint);
     margin: 0 1.25em;
 }

 .footerContainer .footerBottom .footerSocialMedia {
     margin: 0 -1.25em;
 }

 .footerContainer .footerBottom .footerSocialMedia a {
     margin: 0 1.25em;
     text-decoration: none;
 }

 .footer-header {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .inquiry-form-area {
     width: 100%;
     min-height: 100%;
     padding: 0 20px;

 }

 .inquiry-form-area .inquiry-form {
     border-radius: 8px;
     border: 1px solid #fff;
     padding: 20px;
     background-color: #FFFFFF;
     color: var(--bluePrimaryLight);
 }

 .inquiry-form .inquiry-title {
     font-weight: 500;
     font-size: 20px;
     line-height: 33px;
     color: var(--bluePrimaryLight);

     margin-bottom: 20px;
     text-align: left;
 }

 .inquiry-form .form-control {
     text-align: left;
     margin-bottom: 20px;
     box-sizing: border-box;
 }

 .inquiry-form .submit-form {
     color: var(--bluePrimaryLight);
     margin-top: 20px;
     display: flex;
     align-items: center;
     justify-content: flex-end;
     cursor: pointer;
 }

 .inquiry-form .submit-form .icon {
     width: 35px;
     height: 35px;
     display: flex;
     align-items: center;
     justify-content: center;
     border: 1px solid var(--bluePrimaryLight);
     border-radius: 50%;
     margin-left: 15px;
 }

 .inquiry-form-area .inquiry-form input,
 .inquiry-form-area .inquiry-form textarea {
     background-color: #6077BF;
     border-radius: 5px;
     color: #fff;
     padding: 12px 25px;
     border: none;
     width: 100%;
     box-sizing: border-box;
 }

 .inquiry-form-area .inquiry-form textarea {
     resize: none;
     height: 100px;
     font-family: 'Poppins', sans-serif !important;

 }

 .inquiry-form-area .inquiry-form input::placeholder,
 .inquiry-form-area .inquiry-form textarea::placeholder {
     color: #fff;

 }

 .inquiry-form-area .inquiry-form input:focus,
 .inquiry-form-area .inquiry-form textarea:focus {
     border: none;
     outline: none;
 }


 @media (hover:hover) {
     .footerContainer .footerOptions .footerPagesLinks a:hover {
         color: white;
     }

     .footerContainer .footerOptions .footerImportantLinks a:hover {
         color: white;
     }

     .footerContainer .footerBottom .footerImportantLinks a:hover {
         color: white;
     }

     .footerContainer .footerOptions .footerContactOptions .contactData a:hover {
         color: white;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button:hover {
         cursor: pointer;
     }
 }


 @media screen and (max-width: 1150px) {
     .footerContainer {
         padding: 44px 70px 48px 84px;
     }
 }

 @media screen and (max-width: 991px) {

     .inquiry-form-area {

         padding: 0px;

     }
 }

 @media screen and (max-width: 950px) {

     .inquiry-form-area {

         padding: 0px;

     }

     .footerContainer>hr {
         display: none;
     }

     .footerContainer .footerPILWhiteLogo {
         /* margin-left: -12px; */
     }

     .footerContainer .footerPILWhiteLogo img {
         /* margin-bottom: 49px; */
         /* height: 34px; */
         /* width: 145px; */
     }

     /* 
    .footerContainer .footerOptions{
        display: grid;
        grid-template-columns: repeat(4,25%);
    } */

     .footerContainer .footerOptions .footerNewsLetter hr {
         display: inherit;
         height: 1px;
         background-color: white;
         border: none;
         opacity: 0.1;
         margin: 0 0 40px 0;
         mix-blend-mode: normal;
     }

     .footerContainer .footerOptions .footerNewsLetter {
         grid-column: 1/3;
         margin-right: 16px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterText {
         font-size: 16px;
         line-height: 24px;
         margin-bottom: 24px;
     }

     .footerContainer .footerOptions .footerNewsLetter form {
         margin-bottom: 69px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent input {
         flex: 1;
         margin-right: 10px;
         font-size: 12px;
         line-height: 18px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button {
         width: 40px;
         height: 40px;
         flex: 0 0 40px;
         margin-right: 15px;
     }

     .footerContainer .footerOptions .footerBottomTablet {
         display: flex;
         grid-column: 1/3;
         flex-direction: column-reverse;
         justify-content: flex-end;
         text-align: left;
     }

     .footerContainer .footerBottomTablet .footerSocialMedia {
         margin: 0 -20px 28px;
     }

     .footerContainer .footerBottomTablet .footerSocialMedia a {
         margin: 0 20px;
     }

     .footerContainer .footerBottomTablet .footerImportantLinks {
         display: flex;
         flex-direction: row;
     }

     .footerContainer .footerBottomTablet .footerImportantLinks a {
         flex: 1;
         margin: 0 8px;
         font-size: 12px;
         line-height: 16px;
     }

     .footerContainer .footerOptions .footerOptionsHeading {
         font-size: 16px;
         line-height: 24px;
         margin-bottom: 24px;
     }

     .footerContainer .footerOptions .footerPagesLinks,
     .footerContainer .footerOptions .footerImportantLinks {
         margin: -12px 0;
     }

     .footerContainer .footerOptions .footerPagesLinks a,
     .footerContainer .footerOptions .footerImportantLinks a {
         font-size: 14px;
         line-height: 21px;
         margin: 12px 0 0 !important;
         letter-spacing: -0.204545px;
     }

     .footerContainer .footerOptions .footerContactOptions {
         grid-column: 3/5;
     }

     .footerContainer .footerOptions .footerContactOptions .footerContactOptionsItem {
         margin: 22px 0;
     }

     /* 
    .footerContainer .footerBottom{
        display: none;
    } */
 }

 .footerContainer .footerOptions .footerPagesOptions,
 .footerContainer .footerContactOptions,
 .footerContainer .footerImportantOptions {
     margin-bottom: 50px !important;
 }

 @media screen and (max-width: 600px) {
    .footer-header{
        flex-direction: column;
        align-items: center;
        gap: 20px;
     }
     .footerContainer {
         padding: 40px 28px 64px;
     }

     .footerContainer .footerPILWhiteLogo img {
         /* width: 145px; */
     }

     .footerContainer hr {
         margin: 35px 0 25px;
     }



     .footerContainer .footerOptions .footerNewsLetter {
         grid-column: 1/3;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterText {
         margin-bottom: 16px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent {
         padding-right: 15px;
     }

     .footerContainer .footerOptions .footerNewsLetter form {
         margin-bottom: 36px;
     }

     .footerContainer .footerOptions .footerNewsLetter .footerNewsLetterFormContent button {
         height: 40px;
         width: 16px;
     }



     .footerContainer .footerOptions .footerBottomTablet {
         display: none;
     }

     .footerContainer .footerOptions .footerContactOptions {
         margin: 0;
         grid-column: 1/3;
     }

     .footerContainer .footerOptions .footerContactOptions .footerContactOptionsItem {
         margin: 24px 0;
     }

     .footerContainer .footerOptions .footerContactOptions img {
         flex: 0 0 24px;
         margin-right: 12px;
     }

     .footerContainer .footerBottom {
         display: flex;
         justify-content: flex-start;
         flex-direction: column-reverse;

     }

     /* 
    .footerContainer .footerBottom .footerSocialMedia{
        margin-bottom: 40px;
    } */

     .footerContainer .footerBottom .footerSocialMedia a {
         margin: 0 18px;
     }

     .footerContainer .footerBottom .footerImportantLinks a {
         margin: 0 12px;
         font-size: 12px;
         line-height: 16px;
     }

 }



 .office-address {
     margin: 20px 0;
 }

 .office-address hr {
     margin: 20px 0;
 }

 .office-address .footerContactOptions {
     margin: 0 !important;
 }

 .office-address-title {
     color: #fff;
     text-align: left;
 }

 .footer-support {
     flex-direction: row !important;
 }

 .footer-support a {
     width: 50%;
 }

 .footer-support a:nth-child(2) {
     margin: 8px 10px !important;
 }

 .office-address-area {

     align-items: flex-start;
     display: flex;
 }

 .office-address-area .footerOptions {
     width: 100%;
     grid-template-columns: 100%;
 }

 .office-address-area .address-arrow {
     background: #6077BF;
     border-radius: 8px;
     padding: 10px 12px;
     margin: 15px;
     display: flex;
     align-items: center;
 }